<template>
    <v-sheet>
        <div style="width:1330px;">
            <v-row no-gutters>
                <v-col cols="6">       
                    <v-divider :class="['', stresDsltn.list.length > 19 ? 'mr-2__5' : 'mr-1']"></v-divider>
                    <v-row :class="[stresDsltn.list.length > 19 ? 'pr-2__5' : 'pr-1']" no-gutters>                        
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="5">
                            <span class="font-weight-medium">프로그램명</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="2">
                            <span class="font-weight-medium">구분</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                            <span class="font-weight-medium">운영일시</span>
                        </v-col>
                        <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-2" cols="2">
                            <span class="font-weight-medium">참석자수</span>
                        </v-col>
                    </v-row>
                    <v-divider :class="[stresDsltn.list.length > 19 ? 'mr-2__5' : 'mr-1']"></v-divider>
                    <div v-if="stresDsltn.list.length > 0" class="overflow-y-auto" style="width: 100%; height: 321px;">                        
                        <v-row
                            :class="[
                                'crsShp-Pointer',
                                stresDsltn.list.length > 19 ? '' : 'pr-1'
                            ]"
                            v-for="(itm, i) in stresDsltn.list" :key="i"                           
                            @click="tableClickEvent(itm.eduRecPk)"
                            no-gutters>
                            <v-col
                                :class="[
                                    'txtAlgnMid tableBR tableBB py-1 text-overflow',
                                    sltEduRecPk == itm.eduRecPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white'
                                ]"
                                cols="5">
                                {{ itm.eduNm }}
                            </v-col>
                            <v-col
                                :class="[
                                    'txtAlgnMid tableBR tableBB py-1',
                                    sltEduRecPk == itm.eduRecPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white'
                                ]"
                                cols="2">
                                {{ itm.jobClCd }}
                            </v-col>
                            <v-col
                                :class="[
                                    'txtAlgnMid tableBR tableBB py-1',
                                    sltEduRecPk == itm.eduRecPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white'
                                ]"
                                cols="3">
                                {{ $moment(itm.eduDtStrt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                            </v-col>
                            <v-col
                                :class="[
                                    'txtAlgnMid tableBB py-1',
                                    sltEduRecPk == itm.eduRecPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white'
                                ]"
                                cols="2">
                                {{ itm.eduAtndCnt }}
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-else class="tableBB pa-0" no-gutters>
                        <v-col class="txtAlgnMid py-2" cols="12">
                            <span class="fontOneRem grey006--text">※ 프로그램이 존재하지 않습니다.</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="pl-2" cols="6">  
                    <v-form ref="form" lazy-validation>

                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="tblHdr-greyE01 d-flex justify-center align-center tableBR lnHgt1-7 py-1" cols="2">
                                <span class="fontOneDotOneRem font-weight-medium">
                                    구분
                                </span>
                            </v-col>
                            <v-col class="py-1 px-2 d-flex justify-start align-center" cols="10">
                                <div style="width:100%">                           
                                    <v-radio-group
                                        v-model="items.eduMthdCd" :rules="[rules.selectCheck]"  class="radio-dft-Theme mt-0" hide-details row>
                                        <v-radio v-for="(code, n) in vCd74" :key="n" class="ma-0" :ripple="false" :label="code.valcdnm" :value="code.valcd"></v-radio>
                                    </v-radio-group>
                                </div> 
                            </v-col>                        
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="tblHdr-greyE01 d-flex justify-center align-center tableBR lnHgt1-7 py-1" cols="2">
                                <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                    운영일시
                                </span>
                            </v-col>
                            <v-col class="py-1 px-2 d-flex justify-start align-center" cols="10">
                                <span class="d-inline-block" style="width: 133px;">
                                    <CmmDateComp v-model="items.eduDtStrt" :required="true"></CmmDateComp>
                                </span>
                                <span class="white d-inline-block pa-1" style="width: 80px;">
                                    <CmmTimeField v-model="items.eduTmStrt" :required="true"></CmmTimeField>                                     
                                </span>
                                <span class="px-2">&#126;</span>
                                <!-- <span class="d-inline-block" style="width: 133px;">
                                    <CmmDateComp v-model="items.eduDtEnd" :required="true" :minDt="items.eduDtStrt"></CmmDateComp>
                                </span> -->
                                <span class="white d-inline-block pa-1" style="width: 80px;">
                                    <CmmTimeField v-model="items.eduTmEnd" :required="true"></CmmTimeField>                                     
                                </span>
                            </v-col>                        
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="tblHdr-greyE01 d-flex justify-center align-center tableBR lnHgt1-7 py-1" cols="2">
                                <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                    프로그램명
                                </span>
                            </v-col>
                            <v-col class="py-1 px-2 d-flex justify-start align-center" cols="10">
                                <v-text-field v-model="items.eduNm" color="blue" height="28" :rules="[rules.required]" hide-details outlined dense></v-text-field>
                            </v-col>                        
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="tblHdr-greyE01 d-flex justify-center align-center tableBR lnHgt1-7 py-1" cols="2">
                                <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                    진행자
                                </span>
                            </v-col>
                            <v-col class="tableBR py-1 px-2 d-flex justify-start align-center" cols="4">
                                <span class="d-inline-block" style="width: 100px;">
                                    <v-text-field v-model="items.instrNm" color="blue" height="28" :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                                </span>
                            </v-col>
                            <v-col class="tblHdr-greyE01 tableBR d-flex justify-center align-center lnHgt1-7 py-1" cols="2">
                                <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                    작성자
                                </span>
                            </v-col>
                            <v-col class="py-1 px-2 d-flex justify-start align-center" cols="4">
                                <span class="d-inline-block" style="width: 100px;">
                                    <v-text-field
                                        v-model="items.wrtr" color="blue" ref="info" height="28"
                                        :rules="[rules.required, rules.strCheck]" :key="forceRender"
                                        hide-details outlined readonly dense>
                                    </v-text-field>
                                </span>
                                <v-btn 
                                    class="ml-1 brown lighten-3" 
                                    small 
                                    min-width="30"
                                    height="26"  
                                    style="padding:0px 1px 0px 2px !important"
                                    @click="onWrtr()"
                                    >
                                    <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                                </v-btn>
                            </v-col>                        
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1 d-flex justify-center align-center" cols="2">
                                <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                    운영기관
                                </span>
                            </v-col>
                            <v-col class="tableBR py-1 px-2 justify-start align-center" cols="4">
                                <v-text-field
                                    v-model="items.orgnz" color="blue" ref="info" height="28"
                                    :rules="[rules.required]" hide-details outlined dense :key="forceRender">
                                </v-text-field>
                            </v-col>
                            <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1 d-flex justify-center align-center" cols="2">
                                <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                    장소
                                </span>
                            </v-col>
                            <v-col class="py-1 px-2 justify-start align-center" cols="4">
                                <v-text-field
                                    v-model="items.eduLoc" color="blue" ref="info" height="28"
                                    :rules="[rules.required]" hide-details outlined dense :key="forceRender">
                                </v-text-field>
                            </v-col>                        
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1 d-flex justify-center align-center " cols="2">
                                <span class="fontOneDotOneRem font-weight-medium">
                                    참석자
                                </span>
                                <v-btn 
                                    class="ml-1 brown lighten-3" 
                                    small 
                                    min-width="30"
                                    height="25"  
                                    dark
                                    style="padding:0px 1px 0px 2px !important"
                                    @click="onAtnd()"
                                    >
                                    <v-icon size="20">mdi-account-multiple</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col class="py-1 px-2 posn-rltv" cols="10">
                                <div style="height:60px;overflow-y:auto">
                                    <v-chip 
                                        v-for="(list, j) in exclsList" :key="j" 
                                        class="pa-2 mr-1"
                                        close
                                        label                            
                                        color="blue"
                                        outlined
                                        small      
                                        @click:close="delAtndRow(j)"                                
                                        >
                                        {{list.name}}
                                    </v-chip>
                                </div>
                                <span style="position:absolute;bottom:9px;right:20px;">
                                    <v-btn
                                        class="" color="#3C9099" style="padding:0px 1px 0px 2px !important"
                                        min-width="28" height="28" @click="dialog.splice(1,1,true)"
                                        outlined
                                        dark small>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-arrow-expand</v-icon>
                                            </template>
                                            <span>확대</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span> 
                            </v-col>                                            
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1 d-flex justify-center align-center" cols="2">
                                <span class="fontOneDotOneRem font-weight-medium posn-rltv">
                                    프로그램 내용
                                </span>
                            </v-col>
                            <v-col class="py-1 px-2 d-flex ma-0 align-center posn-rltv" cols="10">
                                <v-textarea v-model="items.eduCntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                        rows="7" outlined dense hide-details no-resize>
                                </v-textarea>  
                                <span style="position:absolute;bottom:9px;right:20px;">
                                    <v-btn
                                        class="" color="#3C9099" style="padding:0px 1px 0px 2px !important"
                                        min-width="28" height="28" @click="onCmnts()"
                                        outlined
                                        dark small>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-arrow-expand</v-icon>
                                            </template>
                                            <span>확대</span>
                                        </v-tooltip>
                                    </v-btn>
                                </span>
                            </v-col>                                            
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1 d-flex justify-center align-center" cols="2">
                                <div class="d-flex justify-start align-center" style="width:95px">
                                    <span class="black--text font-weight-medium">사진첨부</span>
                                    <v-file-input 
                                        class="pl-1"
                                        v-model="images" 
                                        :accept="imgAccept"
                                        dense hide-details truncate-length="5" 
                                        multiple counter
                                        prepend-icon="mdi-camera-outline"
                                        hide-input 
                                        @change="upFile('img')"
                                        ></v-file-input>
                                </div>
                            </v-col>
                            <v-col class="py-1 px-2 d-flex align-center" cols="10">
                                <div class="d-flex justify-start align-center" style="height:100px;overflow-y:auto">
                                    <span class="ma-1 d-inline-block" v-for="(item,i) in imageRes" :key="i">
                                        <imageCard 
                                            ref="imageCard" 
                                            :imgSrc="item.src" 
                                            :imgWidth="'100'" 
                                            @isEvent="delfiles(i,'img')" 
                                            @onDown="fileDownload(i,2)"></imageCard>
                                    </span>
                                </div>
                            </v-col>                                            
                        </v-row>
                        <v-divider class=""></v-divider>
                        <v-row class="pa-0" no-gutters>
                            <v-col class="tblHdr-greyE01 tableBR lnHgt1-7 py-1 pl-2 d-flex align-center" cols="2">
                                <div class="d-flex align-center" style="width:95px">
                                    <span class="black--text font-weight-medium">자료첨부</span>
                                    <v-file-input 
                                        v-model="files" 
                                        dense hide-details truncate-length="5" 
                                        multiple counter
                                        hide-input                                         
                                        outlined 
                                        @change="upFile('file')"
                                        ></v-file-input>
                                </div>
                            </v-col>
                            <v-col class="py-1 px-2  d-flex align-center" cols="10">
                                <div style="width:100%;height:100px;overflow-y:auto">                      
                                    <v-chip v-for="(item, j) in fileRes" :key="j"                        
                                        label                        
                                        close
                                        class="ma-1"
                                        outlined
                                        @click="fileDownload(j,1)"
                                        @click:close="delfiles(j,'file')"
                                    >
                                        <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                            mdi-file-pdf-box
                                        </v-icon>
                                        <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                            mdi-microsoft-excel
                                        </v-icon>
                                        <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                            mdi-file-powerpoint
                                        </v-icon>
                                        <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                            mdi-file-word
                                        </v-icon>
                                        <v-icon v-else color='cyan' left>
                                            mdi-file-document
                                        </v-icon>
                                        {{ item.orgFileNm }}
                                    </v-chip> 
                                </div>
                            </v-col>                                            
                        </v-row>
                        <v-divider class=""></v-divider>
                    </v-form>
                </v-col>
            </v-row>
            <div class="pa-0 ma-0">
                <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <CmntsMdl v-if="dialog[0]" @isClose="isModal(0)" @outText="setDtldGls" :mdlTtl = "mdlTtl" :mdlCmnts="mdlCmnts" :hSize='30'></CmntsMdl>
                </v-dialog>
            </div>
            <div class="pa-0 ma-0">
                <v-dialog v-model="dialog[1]" content-class="round" persistent max-width="800">
                    <AtndMdl v-if="dialog[1]" @isClose="isModal(1)" @outText="setDtldGls" :mdlTtl = "'참석자 상세보기'" :atndList="exclsList"></AtndMdl>
                </v-dialog>
            </div>
        </div>          
    </v-sheet>
</template>
<script>
import { http } from '@/api/baseAxios';
import { remoteURL } from '@/api/baseAxios';
import { tmpFileUpload, selAtchdFileList, fileDownload, delAtchdFile} from '../../api/index.js';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import imageCard from '../commons/imageCard.vue';
import CmntsMdl from '../commons/CmntsMdl.vue';
import AtndMdl from '../commons/AtndMdl.vue';

export default {
    name: 'EmpStresDsltn',
    
    props : {
       
    },

    components: { 
        CmmDateComp,
        CmmTimeField,
        imageCard,
        CmntsMdl,
        AtndMdl,
    },

    created: function(){
       
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            // this.yyyy = this.$parent.$parent.$parent.$parent.year
            // this.getEduRec704List()
        })
    },

    computed: {
        getYear:function(){
            return this.$parent.$parent.$parent.$parent.year
        },
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
        getTab: function () {
            return this.$parent.$parent.$parent.$parent.tab
        },
    },

    watch:{ 
        'getYear':function(value){
            this.yyyy = value
            this.getEduRecList()
            this.sltEduRecPk = -1
        },
        'sltEduRecPk':function(value){            
            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)

            this.clearEduRec()

            if(value > 0){
                this.getEduRecInfo()
                this.getEduAtndList()
                this.getAtchdFileList()                
                this.$parent.$parent.$parent.$parent.filledBtn[3].disabled = false
            }else{
                this.exclsList.splice(0)
                this.eduAtndList.splice(0)                
                this.$parent.$parent.$parent.$parent.filledBtn[3].disabled = true
            }
        },
        getSubEvt: function (bol) {
            if(bol && this.rmFileCnFm){
                this.delAtchdFile()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmFileCnFm = false
            }
            
            if(bol && this.rmAtndCnFm){
                this.delAtnd()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmAtndCnFm = false
            }

            if(bol && this.rmStrsDsCnFm){
                this.delEduRec()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmStrsDsCnFm = false
            }
        },
        getTab:function(value){
            if(value==4){
                if(this.sltEduRecPk > 0) this.$parent.$parent.$parent.$parent.filledBtn[3].disabled = false
                else this.$parent.$parent.$parent.$parent.filledBtn[3].disabled = true
            }
        }
    },
        
    methods: {

        //신규
        frmReset:function(){
            this.sltEduRecPk = 0
        },
        
         // 직무 스트레스 해소 교육 조회
         getEduRecList: function (){    
            this.stresDsltn.list.splice(0)
            this.stresDsltn.loader = true
            let obj = {
                inqYYYY     : this.yyyy,
            }

            http.post('emp/selEduRec705List', obj)
                .then((response) => ( this.rstEduRecList(response.data) ))
                .catch((error) => console.log('connect error /emp/selEduRec705List : ' + error))
        },

        //사진 및 자료 파일 업로드
        upFile: function (type) {            
            this.fileType = type
            let formData    = new FormData();            
            let filelen     = 0

            if(type == 'img'){
                this.images.forEach(element => {
                    console.log(element)
                    formData.append("files", element)
                })
                filelen = this.images.length
            } else if(type == 'file'){
                this.files.forEach(element => {
                    console.log(element)
                    formData.append("files", element)
                })
                filelen = this.files.length
            }

            if(filelen > 0){                    
                tmpFileUpload(formData)
                    .then((response) => ( this.rstFileAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
            }
        },

        //첨부파일 조회
        getAtchdFileList:function(){
            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)
            
            let fcltyNum  = this.$store.getters.getFcltyNum            
            let clcd = '10'
            selAtchdFileList(fcltyNum, clcd, this.sltEduRecPk)
                    .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
        },

        //참석자 조회
        getEduAtndList:function(){              
            this.exclsList.splice(0)
            this.eduAtndList.splice(0)

            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                eduRecPk : this.sltEduRecPk,
            }  

            if(this.sltEduRecPk > 0){
                http.post('emp/selEduAtndList', obj)
                    .then((response) => ( this.rstEduAtndList(response.data) ))
                    .catch((error) => console.log('connect error /emp/selEduAtndList : ' + error))
            }
        },

        getEduRecInfo:function(){  
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                eduRecPk : this.sltEduRecPk,
            }     
            
            http.post('emp/selEduRecInfo', obj)
                .then((response) => ( this.rstEduRecInfo(response.data) ))
                .catch((error) => console.log('connect error /emp/selEduRecInfo : ' + error))
        },

        onSave:function(){

            if(this.$refs.form.validate()){
                let obj = {                    
                    fcltyNum        : this.$store.getters.getFcltyNum ,
                    userNm          : this.$store.getters.getUserNm,
                    eduRecPk        : this.sltEduRecPk,
                    eduTypcd		: '705',                    
                    eduNm			: this.items.eduNm,
                    instrNm			: this.items.instrNm,
                    eduDtStrt		: this.$moment(this.items.eduDtStrt,'YYYY-MM-DD').format('YYYYMMDD'),
                    eduTmHhStrt		: '',
                    eduTmMmStrt		: '',
                    eduDtEnd		: '',
                    eduTmHhEnd		: '',
                    eduTmMmEnd		: '',
                    orgnz			: this.items.orgnz,
                    eduLoc			: this.items.eduLoc,
                    eduMthdCd		: this.items.eduMthdCd,
                    eduCntnt		: this.items.eduCntnt,
                    wrtr			: this.items.wrtr,
                    rmrks			: this.items.rmrks,
                    
                    atndList:[],
                    files:[],
                }

                if(this.items.eduTmStrt !='' ) {
                    let eduTmStrt = this.items.eduTmStrt.split(":")
                    obj.eduTmHhStrt = eduTmStrt[0]
                    obj.eduTmMmStrt = eduTmStrt[1]
                }

                if(this.items.eduTmEnd !='' ) {
                    let eduTmEnd = this.items.eduTmEnd.split(":")
                    obj.eduTmHhEnd = eduTmEnd[0]
                    obj.eduTmMmEnd = eduTmEnd[1]
                }

                this.imageRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){                        
                        obj.files.push(x)
                    }
                })

                this.fileRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){
                        x.fileKndCd = '1'
                        obj.files.push(x)
                    }
                })

                this.exclsList.forEach(x => {
                    let atnd = {
                        fcltyNum:this.$store.getters.getFcltyNum  ,
                        userNm:this.$store.getters.getUserNm,
                        atndNm:x.name,
                        mmbrPk:x.mmbrpk,
                        eduAtndPk:x.eduAtndPk,
                        pstnCd:(x.checkr=='1')?x.checkr:'3',
                    }
                    
                    obj.atndList.push(atnd)
                });

                if(this.exclsList.length > 0){
                    http.post('emp/insEduRec', obj)
                        .then((response) => ( this.rstSave(response.data) ))
                        .catch((error) => console.log('connect error /emp/insEduRec : ' + error))
                } else{                    
                    let obj = {}
                    obj.actv = true                 // 경고 노출여부
                    obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                    obj.title = '참가자를 선택해주세요.'        // 경고 타이틀

                    this.$store.commit('setWrnnInf', obj)
                }
            } else{                    
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '필수항목을 확인해주세요.'        // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        }, 

        //프로그램 삭제
        delEduRec:function(){       
            if(this.sltEduRecPk > 0){
                let obj = {                    
                    eduRecPk:this.sltEduRecPk,
                }
                http.post('emp/delEduRec', obj)
                        .then((response) => ( this.rstDelEduRec(response.data) ))
                        .catch((error) => console.log('connect error /emp/delEduRec : ' + error))
            }
            else {
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '삭제 대상자를 찾을 수 없습니다(오류:400)'        // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        },
        

        // 결과 :: 직무 스트레스 해소 교육 조회
        rstEduRecList:function(res){
            this.stresDsltn.loader = false
            if(res.statusCode == '200'){                
                if(res.result.list != null && res.result.list != undefined){
                    res.result.list.forEach(itm => {
                        this.stresDsltn.list.push(itm)
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },

        //결과 :: 사진 및 자료 파일 업로드
        rstFileAfter:function(res){
            if(this.fileType == 'file'){
                res.files.forEach(x=>{
                    let fileNm = x.fileNm.split(".")
                    x.ext = fileNm[1]
                    this.fileRes.push(x)
                })

                this.files = null
            } else if(this.fileType == 'img'){
                res.files.forEach(x=>{
                    x.src = remoteURL + 'opr/imgPrview?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                    this.imageRes.push(x)
                })       

                this.images = null
            }
            
        },

        //결과 :: 첨부파일 조회
        getAtchdFileListAfter:function(res){
            res.forEach(x=>{
                if(x.fileKndCd == "2"){
                    x.src = remoteURL + 'opr/imgView?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                    this.imageRes.push(x)
                }else{
                    let fileNm = x.fileNm.split(".")
                    x.ext = fileNm[1]  
                    this.fileRes.push(x)
                }
            })
        },

        //결과 :: 참석자 조회
        rstEduAtndList:function(res){
            this.eduAtndList = res
            this.eduAtndList.forEach(e => {
                let obj = {
                    eduAtndPk:e.eduAtndPk,
                    name:e.atndNm,
                    mmbrpk:e.mmbrPk,
                    disChekr:true,
                    checkr:(e.pstnCd=='1')?'1':'2'
                }

                this.exclsList.push(obj)
            });
        },    

        rstSave:function(res){    
            let obj = {}
           
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getEduRecList()
                this.clearEduRec()
                this.exclsList.splice(0)
                this.eduAtndList.splice(0) 
            }else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }

            this.$store.commit('setWrnnInf', obj)

        },

        //결과 :: 프로그램 삭제
        rstDelEduRec:function(res){
            let obj = {}
           
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getEduRecList()
                this.sltEduRecPk = -1      
            }else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        rstEduRecInfo:function(res){   

            if(res != null && res != ''){

                this.items.eduTypcd		        = res.eduTypcd
                this.items.eduNm			    = res.eduNm
                this.items.instrNm			    = res.instrNm
                this.items.eduDtStrt		    = this.$moment(res.eduDtStrt,'YYYYMMDD').format('YYYY-MM-DD')
                this.items.eduTmHhStrt		    = res.eduTmHhStrt
                this.items.eduTmMmStrt		    = res.eduTmMmStrt
                this.items.eduDtEnd		        = res.eduDtEnd
                this.items.eduTmHhEnd		    = res.eduTmHhEnd
                this.items.eduTmMmEnd		    = res.eduTmMmEnd
                this.items.orgnz			    = res.orgnz
                this.items.eduLoc			    = res.eduLoc
                this.items.eduMthdCd		    = res.eduMthdCd
                this.items.eduCntnt		        = res.eduCntnt
                this.items.wrtr			        = res.wrtr
                this.items.rmrks			    = res.rmrks

                this.items.eduTmStrt            =  this.items.eduTmHhStrt+':'+this.items.eduTmMmStrt
                this.items.eduTmEnd            =  this.items.eduTmHhEnd+':'+this.items.eduTmMmEnd
            }

            this.$refs.form.resetValidation()
        },  

        //첨부파일 다운로드
        fileDownload:function(idx, type){
            if(type == 1){
                if(this.fileRes[idx] != undefined){
                    fileDownload(this.fileRes[idx])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }
            }else if(type==2){
                if(this.imageRes[idx] != undefined){
                    fileDownload(this.imageRes[idx])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        
        delAtndRow:function(idx){
            if(this.exclsList[idx].eduAtndPk > 0){
                this.stlAtndPk = this.exclsList[idx].eduAtndPk                
                
                let obj = {}
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = '참석자 삭제'
                obj.cntnt = [this.exclsList[idx].name+'님 을(를) 삭제하시겠습니까?']
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmAtndCnFm = true

            }else{
                 this.exclsList.splice(idx,1)
            }
        },
        //DB ::참석자 삭제
        delAtnd:function(){         
            if(this.stlAtndPk > 0){ 
                let obj = {
                    eduAtndPk: this.stlAtndPk,
                }

                http.post('emp/delEduAtnd', obj)
                    .then((response) => ( this.rstAtnd(response.data) ))
                    .catch((error) => console.log('connect error /emp/delEduAtnd : ' + error))
            } else {
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '삭제 대상자를 찾을 수 없습니다(오류:400)'        // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        },   

        //DB 결과 ::참석자 삭제
        rstAtnd:function(res){
            
            let obj = {}
            obj.actv = true                 // 경고 노출여부            
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

               

            if(res.statusCode == 200){
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getEduAtndList()    
                this.getEduRecList()
                this.atndPk = 0
            }else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        //DB :: 첨부파일 삭제
        delAtchdFile:function(){
            
                let pk = 0

                if(this.fileType == 'file'){
                    pk = this.fileRes[this.sltFileIdx].atchdFilePk
                }else if(this.fileType == 'img'){
                    pk = this.imageRes[this.sltFileIdx].atchdFilePk
                }

                if(pk > 0){
                    let fcltyNum  = this.$store.getters.getFcltyNum  
                    delAtchdFile(fcltyNum, pk)
                        .then((response) => ( this.delAtchdFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
                }

           
        },       
        delAtchdFileAfter:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){                
                this.getAtchdFileList()                
                this.sltFileIdx = -1
                this.fileType = ''               
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
               
            }else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }            

            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            this.$store.commit('setWrnnInf', obj)
        },

        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },

        onCmnts:function(){
            this.mdlTtl     = '프로그램 내용'
            this.mdlCmnts   = this.items.eduCntnt
            this.dialog.splice(0, 1, true)
        },

        setDtldGls:function(txt){
            if(this.mdlTyp == 'eduCntnt'){
                this.items.eduCntnt = txt
            }else{
                this.items.rmrks = txt
            }
        },

        delfiles:function(idx, type){
            this.fileType = type

            let obj = {}
            
            if(type == 'file') {
                if(this.fileRes[idx].atchdFilePk != undefined){                    
                    this.sltFileIdx = idx
                    obj.title = '자료첨부 삭제'
                    obj.cntnt = ['파일 : '+this.fileRes[idx].orgFileNm +'삭제하시겠습니까?']
                    this.rmFileCnFm = true
                }else{
                    this.fileRes.splice(idx,1)
                }
            } else if(type == 'img'){
                if(this.imageRes[idx].atchdFilePk != undefined){
                    this.sltFileIdx = idx
                    obj.title = '사진첨부 삭제'
                    obj.cntnt = ['파일 : '+this.imageRes[idx].orgFileNm + '삭제하시겠습니까?']
                    this.rmFileCnFm = true
                }else{
                    this.imageRes.splice(idx,1)
                }
            }

            if(this.rmFileCnFm){
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });
            }
        }, 

        onDelete:function(){
            if(this.sltEduRecPk > 0){
                let obj = {}
                obj.title = '프로그램 삭제'
                obj.cntnt = [this.items.eduNm+' 삭제하시겠습니까?']
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 삭제하겠습니다.'
    
                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmStrsDsCnFm = true
            } else {
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '삭제 대상자를 찾을 수 없습니다(오류:400)'        // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        },

        onWrtr:function(){            
            this.$emit('onWrtr', this.items.eduDtStrt, 0)
        },

        onAtnd:function(){
            this.$emit('onAtnd', this.items.eduDtStrt, 1, this.exclsList)
        },

        setEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            ++this.forceRender
        },
        setAplyList: function (arr){

            arr.forEach(e => {  
                let mmbrpk = 0
                let cd = ''

                switch (e.dtTyp) {
                    case 'bnfcr':
                        cd = '1'
                        mmbrpk = e.bnMmbrPk
                        break;
                    case 'emp':
                        cd = '2'
                        mmbrpk = e.mmbrPk
                        break;
                    default:
                        break;
                }            

                if(this.exclsList.findIndex( v => v.mmbrpk == mmbrpk) == -1){
                    let obj = {
                        name:e.name,
                        mmbrpk:mmbrpk,
                        disChekr:false,
                        checkr:cd
                    }

                    this.exclsList.push(obj)
                }

            });            
        },

        tableClickEvent:function(pk){            
            this.sltEduRecPk = pk            
        },

        clearEduRec:function(){            
            this.items.eduTypcd		        = ''     //교육종류코드            
            this.items.eduNm			    = ''    //교육명
            this.items.instrNm			    = ''    //교육강사명
            this.items.eduDtStrt		    = ''    //교육시작일자
            this.items.eduTmStrt            = ''
            this.items.eduTmHhStrt		    = ''    //교육시작시간_시
            this.items.eduTmMmStrt		    = ''    //교육시작시간_분
            this.items.eduDtEnd		        = ''    //교육종료일자
            this.items.eduTmEnd             = ''
            this.items.eduTmHhEnd		    = ''    //교육종료시간_시
            this.items.eduTmMmEnd		    = ''    //교육종료시간_분
            this.items.orgnz			    = ''    //주관처
            this.items.eduLoc			    = ''    //교육장소
            this.items.eduMthdCd		    = ''    //교육방법코드
            this.items.eduCntnt		        = ''        //교육내용
            this.items.wrtr			        = ''        //작성자
            this.items.rmrks			    = ''        //비고                
        },
    },
    
    data: () => ({   
        rmStrsDsCnFm  : false,
        rmAtndCnFm  : false,
        rmFileCnFm  : false,
        forceRender : 0,
        sltEduRecPk : -1,  
        stlAtndPk   : -1,
        yyyy        : '',
        sltFileIdx  : -1,
        fileType    : '',
        files       : null,
        fileRes     : [],
        images      : null, 
        imageRes    : [],
        imgAccept   : 'image/*', 
        vCd74       : [
            { valcd   : "4", valcdnm : "자체프로그램" }
            ,{ valcd   : "5", valcdnm : "외부프로그램" }
        ],
        dialog      : [false],
        mdlTtl      : '',
        mdlCmnts    : '',
        exclsList   : [],
        eduAtndList : [],
        stresDsltn  : {
            loader  : false,            
            list    : [],            
        },
        items:{            
            eduNm			: '', //교육명
            instrNm			: '', //교육강사명
            eduDtStrt		: '', //교육시작일자
            eduTmStrt       : '',
            eduTmHhStrt		: '', //교육시작시간_시
            eduTmMmStrt		: '', //교육시작시간_분
            eduDtEnd		: '', //교육종료일자
            eduTmEnd        : '',
            eduTmHhEnd		: '', //교육종료시간_시
            eduTmMmEnd		: '', //교육종료시간_분
            orgnz			: '', //주관처
            eduLoc			: '', //교육장소
            eduMthdCd		: '', //구분(자체프로그램=4,외부프로그램=5)
            eduCntnt		: '', //교육내용
            wrtr			: '', //작성자
            rmrks			: '', //비고
        },
        rules       : {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
    }),
};
</script>