<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth+200" :max-width="areaWidth+300">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>    
        <div class="mx-2 mt-2 d-flex">
            <expandNowDatePicker :prtDate="sltDate" @nowDate="getDate" :lmtNxtDt="lmtNxtDt" :key="forceRender"></expandNowDatePicker>
            <v-spacer></v-spacer>
            <span v-if="progress">
                <v-progress-circular            
                :size="30"
                color="primary"
                indeterminate
                ></v-progress-circular>
            </span>
            <span v-if="!progress">
                <span>
                    <v-btn class="ml-1 white--text" height="30" color="blueBtnColor"
                        :disabled="bnMmbrPk < 1"
                        @click="preSndAct" samll rounded>
                        <v-icon small>mdi-arrow-expand-right</v-icon>
                        <span style="font-size: 1rem;">개인현황안내</span>
                    </v-btn>
                </span>
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" :class="list.class" height="30" :color="list.color"
                        :disabled="list.disabled" @click="onClickBtn(list.text)" samll rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </span>
            <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="dialog[filledBtn.length+j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span>
        </div>
        <v-divider class="my-2"></v-divider>
        <basicInfo ref="basicInfo" :bnMmbrPk="bnMmbrPk" @setParentData="getBnfcrInfo"></basicInfo>
        <div style="position:relative;">
            <div v-if="recPsbYn != '1'" style="position:absolute;top:0px;left:0px;width:100%;height:100%;z-index:7;">
                
                <div style="width:100%;height:100%;background-color:black;opacity: 0.2;"></div>
                <v-icon size="70" style="position:absolute;top:36%;left:42%;">mdi-pencil-off-outline</v-icon>
                <div v-if="recPsbYn == '3' && bnMmbrPk > 0" style="position:absolute;top:35%;left:50%;">
                    <span class="font-weight-bold" style="font-size:4rem;color:rgba(0, 0, 0, 0.54)">외박중</span>
                </div>
                <div v-if="recPsbYn == '2' && bnMmbrPk > 0" style="position:absolute;top:35%;left:50%;">
                    <span class="font-weight-bold" style="font-size:4rem;color:rgba(0, 0, 0, 0.54)">퇴소중</span>
                </div>
            </div>            
            <v-tabs
                v-model="tab" class="tab-dft-Theme mt-2" active-class="black"
                center-active hide-slider>
                <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
            </v-tabs>
            <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
            <v-tabs-items v-model="tab">            
                <v-tab-item eager transition="fade-transition">
                    <HlthNsgRcrdTab 
                        ref="hlthNsgRcrdTab"
                        @hlthBlnftTrigger="hlthBlnftTrigger"
                        @onProgress="onProgress"
                        @setDate="setSltDate"></HlthNsgRcrdTab>
                </v-tab-item>
                <v-tab-item eager transition="fade-transition">
                    <MdctnMgmtTabOld  v-if="oldRecYn"
                        ref="mdctnMgmtTab"  
                        :lvnRmInfPk="lvnRmInfPk"
                        @onMdctnPrscr="onMdctnPrscr"
                        @hlthBlnftTrigger="hlthBlnftTrigger"></MdctnMgmtTabOld>
                    <MdctnMgmtTab v-else
                        ref="mdctnMgmtTab"  
                        :lvnRmInfPk="lvnRmInfPk"
                        @onMdctnPrscr="onMdctnPrscr"
                        @hlthBlnftTrigger="hlthBlnftTrigger"></MdctnMgmtTab>
                </v-tab-item>     
                <v-tab-item eager transition="fade-transition">
                    <DctrCareTab  
                        ref="dctrCareTab"
                        @hlthBlnftTrigger="hlthBlnftTrigger"
                        @setDate="setSltDate"></DctrCareTab>
                </v-tab-item> 
                <v-tab-item eager transition="fade-transition">
                    <HlthChckpTab  
                        ref="hlthChckpTab"   
                        @hlthBlnftTrigger="hlthBlnftTrigger"
                        @setDate="setSltDate"></HlthChckpTab>
                </v-tab-item>        
                <v-tab-item eager transition="fade-transition">
                    <NrsngDocTab  
                        ref="nrsngDocTab"   
                        @hlthBlnftTrigger="hlthBlnftTrigger"
                        @setDate="setSltDate"></NrsngDocTab>
                </v-tab-item>     
            </v-tabs-items>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[0]" eager content-class="round" persistent max-width="1360">
                <HsptlMdf @isClose="isModal(0)"></HsptlMdf>
            </v-dialog>
         </div>
         <div class="ma-0 pa-0">
            <v-dialog v-model="menu[1]" content-class="round" persistent max-width="1640">
                <HlthBlnftSrvRcrdVitWrt
                    v-if="menu[1]"
                    :hlthBlnftSrvRcrdVitPk="hlthBlnftSrvRcrdVitPk"
                    :inqYMD="inqYMD"
                    @isClose="isModal(1)"
                    @modelEvent="modelEvent(99)">
                </HlthBlnftSrvRcrdVitWrt>
            </v-dialog>
         </div>
         <div class="ma-0 pa-0">
            <v-dialog v-model="menu[2]" content-class="round" persistent max-width="1550">
                <MdctnPrscrMdlOld v-if="oldRecYn"
                    :mdctnPrscrMdlPk="mdctnPrscrMdlPk"
                    :inqYMD="inqYMD"
                    @isClose="isModal(2)"
                    @modelEvent="modelEvent(99)">
                </MdctnPrscrMdlOld>
                <MdctnPrscrMdl v-else
                    :mdctnPrscrMdlPk="mdctnPrscrMdlPk"
                    :inqYMD="inqYMD"
                    @isClose="isModal(2)"
                    @modelEvent="modelEvent(99)">
                </MdctnPrscrMdl>
            </v-dialog>
            <v-dialog v-model="menu[4]" max-width="1100" persistent>
                <bizMsgMdl 
                    ref="bizMsgMdl"
                    :bizMsgData="bizMsgInfo.mmbrPk"
                    :bizMsgDocClcd="bizMsgInfo.docClcd"
                    :bizMsgBzPk="bizMsgInfo.bzPk"
                    :bizMsgShow="menu[4]"
                    :bizMsgTitle="bizMsgInfo.bzTitle"
                    :bizMsgCntnt="bizMsgInfo.bzCntnt"
                    @madalState="menu.splice(4, 1, false)">
                </bizMsgMdl>  
            </v-dialog>
         </div>
    </v-sheet>
</template>

<script>
import expandNowDatePicker from '../../components/commons/expandNowDatePicker.vue';
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import HlthNsgRcrdTab from '../../components/bnftrcrd/HlthNsgRcrdTab.vue';                  //건강 및 간호기록
import MdctnMgmtTab from '../../components/bnftrcrd/MdctnMgmtTab.vue';                      //투약관리
import MdctnMgmtTabOld from '../../components/bnftrcrd/MdctnMgmtTabOld.vue';                      //2025이전투약관리
import DctrCareTab from '../../components/bnftrcrd/DctrCareTab.vue';                        //의사진료
import HlthChckpTab from '../../components/bnftrcrd/HlthChckpTab.vue';                      //건강검진
import NrsngDocTab from '../../components/bnftrcrd/NrsngDocTab.vue';                        //기타자료

import HsptlMdf from '../../components/bnftrcrd/HsptlMdf.vue';                              //병의원관리
import HlthBlnftSrvRcrdVitWrt from '../../components/bnftrcrd/HlthBlnftSrvRcrdVitWrt.vue';  //전체 건강 바이탈 기록
import MdctnPrscrMdl from '../../components/bnftrcrd/MdctnPrscrMdl.vue';                    //투약처방관리
import MdctnPrscrMdlOld from '../../components/bnftrcrd/MdctnPrscrMdlOld.vue';                    //투약처방관리

import bizMsgMdl from '../../components/commons/BizMsgMdl.vue';
import { selFcltyInitList  } from '../../api/opr.js';
import { selBnfcrSttChkInfo  } from '../../api/bnfcr.js';


export default {
    name: 'HlthBlnftSrvRcrd',
    
    components: {
        expandNowDatePicker,
        basicInfo,
        HlthNsgRcrdTab,
        MdctnMgmtTab,
        MdctnMgmtTabOld,
        DctrCareTab,
        HlthChckpTab,
        HsptlMdf,
        HlthBlnftSrvRcrdVitWrt,
        MdctnPrscrMdl,
        MdctnPrscrMdlOld,
        NrsngDocTab,
        bizMsgMdl,
    },

    props : {

    },

    created: function(){       
        this.lmtNxtDt = this.$moment().format('YYYY-MM-DD')
        this.getFcltyInitList()
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
            this.bnMmbrPk = this.$parent.$parent.sltBnMmBrPk

            if( this.bnMmbrPk > 0){
                this.filledBtn[1].disabled = false
                // this.filledBtn[2].disabled = false
                // this.filledBtn[3].disabled = false
                // this.filledBtn[4].disabled = false                
                this.filledBtn[5].disabled = false       
            }else{
                this.filledBtn[0].disabled = true
                this.filledBtn[1].disabled = true
                // this.filledBtn[2].disabled = true
                // this.filledBtn[3].disabled = true
                // this.filledBtn[4].disabled = true
                this.filledBtn[5].disabled = true
            }
            this.getBnfcrSttChkInfo()
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
        getBnmmbrPk(){
            return this.$parent.$parent.sltBnMmBrPk
        },
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
        getSubActv: function () {
            return this.$store.getters.getSubActv
        },
    },  

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        getBnmmbrPk:function(value){
            this.bnMmbrPk = value
            if(value > 0){
                this.filledBtn[0].disabled = false
                this.filledBtn[1].disabled = false
                // this.filledBtn[2].disabled = false
                // this.filledBtn[3].disabled = false
                // this.filledBtn[4].disabled = false
                this.filledBtn[5].disabled = false    
                
                let idx = this.$parent.$parent.bnftrcrdList.findIndex(x=>x.bnMmbrPk == value)
                this.lvnRmInfPk = this.$parent.$parent.bnftrcrdList[idx].lvnRmInfPk
            }else{
                this.filledBtn[0].disabled = true
                this.filledBtn[1].disabled = true
                // this.filledBtn[2].disabled = true
                // this.filledBtn[3].disabled = true
                // this.filledBtn[4].disabled = true
                this.filledBtn[5].disabled = true
                this.lvnRmInfPk = 0
            }
            this.getBnfcrSttChkInfo()
        },
        'inqYMD':function(){
            this.oldRecYn =  this.$moment(this.inqYMD ,'YYYYMMDD').isBefore('2025-01-01')

            let entStcd = null
            if(this.$parent.$parent.entChgBox == 1)
                entStcd = '4'
            this.$parent.$parent.getAllHlthCareList(this.inqYMD, entStcd)
            if(this.bnMmbrPk > 0) this.getBnfcrSttChkInfo()
        },
        'tab':function(){
            if(this.bnMmbrPk > 0) this.getBnfcrSttChkInfo()
            
            if(this.tab==2){
                this.filledBtn[0].disabled = false
                this.filledBtn[0].visible = true
            }else{
                this.filledBtn[0].disabled = true
                this.filledBtn[0].visible = false
            }
        },
        getSubEvt: function (bol) {
            if(bol && this.hlthBlnftCnfm){
                this.onIgnrSave()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.hlthBlnftCnfm = false
            }
        },

        getSubActv:function (bol){
            if(!bol && this.hlthBlnftCnfm){
                this.onLoad()
                this.hlthBlnftCnfm = false
            }
        }
    },

    methods: {
        onLoad(){
            switch (this.tab) {
                case 0:
                    this.$refs.hlthNsgRcrdTab.onLoad()
                    break
                case 1:
                    this.$refs.mdctnMgmtTab.onLoad()
                    break               
                case 2:
                    this.$refs.dctrCareTab.onLoad()
                    break   
                case 3:
                    this.$refs.hlthChckpTab.onLoad()
                    break   
                case 4:
                    this.$refs.nrsngDocTab.onLoad()
                    break   
                default:
                    break
            }
        },
        onIgnrSave:function(){
            switch (this.tab) {
                    case 0:
                        this.$refs.hlthNsgRcrdTab.onSave('1')
                        break
                    case 1:
                        this.$refs.mdctnMgmtTab.onSave('1')
                        break                   
                    case 2:
                        this.$refs.dctrCareTab.onSave('1')
                        break
                    case 3:
                        this.$refs.hlthChckpTab.onSave('1')
                        break
                    case 4:
                        this.$refs.nrsngDocTab.onSave('1')
                        break                        
                    default:
                        break
                }
        },
        onClickBtn:function(text){
            if(text == "저장") {                
                switch (this.tab) {
                    case 0:
                        this.$refs.hlthNsgRcrdTab.onSave()
                        break
                    case 1:
                        this.$refs.mdctnMgmtTab.onSave()
                        break                   
                    case 2:
                        this.$refs.dctrCareTab.onSave()
                        break
                    case 3:
                        this.$refs.hlthChckpTab.onSave()
                        break
                    case 4:
                        this.$refs.nrsngDocTab.onSave()
                        break                        
                    default:
                        break
                }
            }else if(text == "삭제"){               
                switch (this.tab) {
                    case 0:
                        this.$refs.hlthNsgRcrdTab.delAll()
                        break
                    case 1:
                        this.$refs.mdctnMgmtTab.delAll()
                        break
                    case 2:
                        this.$refs.dctrCareTab.delAll()
                        break
                    case 3:
                        this.$refs.hlthChckpTab.delAll()
                        break
                    case 4:
                        this.$refs.nrsngDocTab.delAll()
                        break  
                    default:
                        break
                }
            }else if(text == '병의원관리'){
                this.menu.splice(0, 1, true)
            }else if(text == '신규'){
                switch (this.tab) {                    
                    case 2:
                        this.$refs.dctrCareTab.onNewRec()
                        break;
                    default:
                        break;
                }
            }else if(text == '의약정보'){
                this.onLink()
            }else if(text == '전체건강바이탈기록'){
                this.hlthBlnftSrvRcrdVitPk = 1
                this.menu.splice(1,1,true)
            }
        },

        getBnfcrSttChkInfo:function(){
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum, 
                bnMmbrPk    : this.bnMmbrPk,
                inqYMD      : this.inqYMD, 
            }
            selBnfcrSttChkInfo(obj)
                .then((response) => ( this.getBnfcrSttChkInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /bnfcr/selBnfcrSttChkInfo : ' + error))
        },
        getBnfcrSttChkInfoAfter:function(res){
            if(res.statusCode == 200){
                if(res.result != null) {
                    this.recPsbYn = res.result.recPsbYn
                    if(this.recPsbYn == '1') {
                        this.filledBtn[1].disabled = false
                        this.onLoad()
                    }else{
                        this.filledBtn[1].disabled = true
                    }
                }else{
                    this.recPsbYn = '2'
                    this.filledBtn[1].disabled = true
                }
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        hlthBlnftTrigger:function(res){
            let entStcd = null
            if(this.$parent.$parent.entChgBox == 1)
                entStcd = '4'

            let obj = {}
            
            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
                this.$parent.$parent.getAllHlthCareList(this.inqYMD, entStcd)
            }else if(res.statusCode == 205){
                obj.icon = 'mdi-alert'
                obj.iconClr = 'red'
                obj.title = res.message
                obj.cntnt = []

                if(res.messagesList != null && res.messagesList != undefined){
                    res.messagesList.forEach(el => {
                        obj.cntnt.push(el)
                    });
                }

                obj.cntnt.push('등록된 기록을 삭제하고 저장하시겠습니까?')
                obj.cnclTxt = '아니오'
                obj.cnfrmTxt = '예, 저장하겠습니다.'

                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });   

                this.hlthBlnftCnfm = true
            }else if(res.statusCode == 300){
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.actv = true                 // 경고 노출여부
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)
                this.$store.commit('setWrnnInf', obj)
            }
            
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        getDate: function (value) {            
            this.inqYMD = value.date
        },
        setSltDate:function(value){            
            this.sltDate=value
            this.forceRender++
        },
        isModal:function(value){
            if(value == 1) this.hlthBlnftSrvRcrdVitPk = -1
            else if(value == 2) this.mdctnPrscrMdlPk = -1
            this.menu.splice(value, 1, false)
        },
        onLink:function(){
            this.windowRef = window.open('https://www.nhis.or.kr/nhis/healthin/retrieveMdsupInfoSrch.do', '_blank');         
            this.windowRef = null  
        },
        modelEvent:function(event){
            if(event == 99){
               this.onLoad()
            }
        },
        onMdctnPrscr:function(){
            if(this.bnMmbrPk > 0){
                this.mdctnPrscrMdlPk = this.bnMmbrPk
                this.menu.splice(2, 1, true)
            }else{
                this.snackControll([true, 2000, '왼쪽 목록에서 수급자를 선택해주세요.', 'warning'])
            }
        },
        rprtActCall: function (){
            this.prtThrowObj.bnMmbrPk = this.$store.getters.getBnfcrState.bnmmbr
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.prtCd = '29'
            this.prtThrowObj.inqYmdStart = this.inqYMD
            this.prtThrowObj.inqYmdEnd = this.inqYMD

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
        prtPyrprRcrd: function (key){
            if(key == 0){

                this.prtThrowObj.list[0].bzPk.splice(0)
                this.prtThrowObj.list[0].bzClcd.splice(0)
                this.prtThrowObj.inqYmdStart = this.inqYMD
                this.prtThrowObj.inqYmdEnd = this.inqYMD
                this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                this.prtThrowObj.bnMmbrPk = this.bnMmbrPk
                this.prtThrowObj.prtCd = '52'
    
                this.$store.commit('setRootInf', {
                    actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                });
            }
            else if(key == 1){
                this.prtThrowObj.list[0].bzPk.splice(0)
                this.prtThrowObj.list[0].bzClcd.splice(0)
                this.prtThrowObj.inqYmdStart = this.inqYMD
                this.prtThrowObj.inqYmdEnd = this.inqYMD
                this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                this.prtThrowObj.bnMmbrPk = this.bnMmbrPk
                this.prtThrowObj.prtCd = '83'
    
                this.$store.commit('setRootInf', {
                    actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                });
            }
        },
        onProgress:function(key){
            if(key == '1') this.progress = true 
            else this.progress = false
        },
        preSndAct:function(){
            this.bizMsgInfo.bzTitle = '어르신 개인현황 안내'
            this.bizMsgInfo.docClcd = 24
            this.bizMsgInfo.bzPk = this.bnMmbrPk
            this.bizMsgInfo.mmbrPk.splice(0)
            this.bizMsgInfo.mmbrPk.push(this.bnMmbrPk)
            this.bizMsgInfo.bzCntnt = this.bnMmbrNm
            this.menu.splice(4, 1, true)
        },
        getBnfcrInfo:function(obj){
            this.bnMmbrNm = obj.name
        },
        getFcltyInitList:function(){           
            selFcltyInitList(this.$store.getters.getFcltyNum, ['4'])
                .then((response) => ( this.getFcltyInitListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selFcltyInitList : ' + error))
        },
        getFcltyInitListAfter:function(res){
            let tday = this.$moment()
            let tommday = this.$moment().add(1, 'days')
            res.forEach(el => {
                if(el.itmVal1 == '1') this.lmtNxtDt = ''
                else {

                    let day     = this.$moment(tday.format('YYYYMMDD')+' '+el.itmVal2,'YYYYMMDD hh:mm')                    
                    let yn = this.$moment.duration(tday.diff(day)).asMinutes()                       
                    this.lmtNxtDt = yn > 0 ? tommday.format('YYYY-MM-DD') : tday.format('YYYY-MM-DD')
                }
            });
        }
    },
    data: () => ({
        oldRecYn:false,
        hlthBlnftCnfm:false,
        hlthBlnftSrvRcrdVitPk:-1,
        mdctnPrscrMdlPk:-1,
        tab:0,
        areaWidth: 708,
        bnMmbrPk: 0,
        bnMmbrNm: '',
        windowRef:null,
        sltDate: '',
        inqYMD:'',
        dialog: [],
        lvnRmInfPk:0,
        menu:[false,false,false,false,],
        snackOptions: [false, 3000, '', 'info'], 
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '신규', type: 'action', visible: false, disabled: true, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장',type: 'action', visible: true,disabled: true, },            
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '전체건강바이탈기록',type: 'action', visible: true,disabled: false, },
            { icon: 'mdi-clipboard-text-search-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '의약정보',type: 'action', visible: true,disabled: false, },
            { icon: 'mdi-clipboard-text-search-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '병의원관리',type: 'action', visible: true,disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제',type: 'action', visible: true, disabled: true, },
        ],
        outlinedBtn: [
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        tabItems: [
            { index: 0, tab: '건강 및 간호기록', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 1, tab: '투약관리', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 2, tab: '의사진료', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 3, tab: '건강검진', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 4, tab: '기타자료', class: 'fontOneDotTwoRem px-15', enter:false},
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '29', inqYmdStart: '', inqYmdEnd: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
        progress:false,
        bizMsgInfo: {
            mmbrPk: [], docClcd: 0, bzPk: 0, bzTitle: '', bzCntnt: '', bizMsgVal1: '',
        },
        lmtNxtDt:'',
        forceRender:0,
        recPsbYn:'1'
    }),
};
</script>