<template>
    <v-sheet class="ml-0">
        <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500"
            class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
            <div style="text-align: center;">
                <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
            </div>
        </v-snackbar>
        <div class="d-flex">
            <v-spacer></v-spacer> 
            <v-btn
                class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25"
                @click="menu.splice(0,1,true)"
                small outlined>
                <v-icon size="14">mdi-office-building-plus-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">건물 추가</span>                
            </v-btn>           
        </div>
        <div v-if="bldInfList.length > 0">        
            <v-tabs v-model="tab">
                <v-tab
                    v-for="bldInf in bldInfList"
                    :key="bldInf.bldInfPk"
                >   
                    <v-icon size="20" class="mr-2">mdi-office-building-outline</v-icon>
                    <span>{{ bldInf.bldNm }}</span>
                </v-tab>                
                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(bldInf,i) in bldInfList" :key="i"  >
                        <v-form ref="frm" lazy-validation >
                        <v-row class="mt-3" no-gutters>
                            <v-col class="d-flex align-center" cols="12">
                                <div>
                                    <v-icon class="font-weight-bold">mdi-chevron-right</v-icon>
                                    <span class="black--text font-weight-bold">생활실 정보</span>
                                </div>
                                <v-spacer></v-spacer>     
                                <v-btn
                                    class="ml-1" color="blueBtnColor" style="padding: 13px 12px 12px 10px !important"
                                    min-width="30" height="25"
                                    @click="preInsLvnRmInf"
                                    small outlined>
                                    <v-icon size="14">mdi-content-save-outline</v-icon>
                                    <span class="fontOneRem ml-1" style="padding-bottom: 2px;">생활실 일괄저장</span>                
                                </v-btn>                           
                            </v-col>
                        </v-row>
                        <v-row class="mt-2 pa-0" no-gutters>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="bldFlrHeader"
                                    :items="bldInf.bldFlrList"
                                    :loading="loader"
                                    loading-text="Loading... Please wait"
                                    fixed-header hide-default-header hide-default-footer dense disable-pagination                         
                                    height='600'
                                >
                                    <template v-slot:header="{ props }" >
                                        <thead>
                                            <tr>
                                                <th class="black--text clmsDtlHeaderCommon py-2 tableBT tableBR" style="width:10%;">{{props.headers[0]?.text}}</th>
                                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" >{{props.headers[1]?.text}}</th>
                                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:10%;">{{props.headers[2]?.text}}</th>
                                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:10%;">{{props.headers[3]?.text}}</th>
                                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:15%;">{{props.headers[4]?.text}}</th>
                                                <th class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:10%;">{{props.headers[5]?.text}}</th>
                                                <th v-if="dmntTrtYn =='1'"  class="black--text clmsDtlHeaderCommon tableBT tableBR" style="width:13%;">{{props.headers[6]?.text}}</th>
                                                <th class="black--text clmsDtlHeaderCommon tableBT" style="width:5%;">
                                                    <v-btn icon @click="onAddLvn" class="mr-1" color="blue200">                                                
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon v-bind="attrs" v-on="on">mdi-plus-circle-outline</v-icon>
                                                            </template>
                                                            <span>생활실 추가</span>
                                                        </v-tooltip>
                                                    </v-btn>
                                                </th>
                                            </tr>
                                        </thead>
                                    </template>
                                    <template v-slot:item='{ item }'>
                                        <tr>
                                            <td class="clmsBodyCommon tableBR px-1">
                                                <v-select
                                                    v-model="item.bldFlr"
                                                    :items="bldFlrItems"
                                                    item-text="valcdnm" item-value="valcd"
                                                    class="wrkSelect" height="28"
                                                    :rules="[rules.required]"
                                                    dense hide-details outlined>
                                                </v-select>
                                            </td>
                                            <td class="clmsBodyCommon tableBR px-1">
                                                <v-text-field
                                                    v-model="item.lvnRmNm" :rules="[rules.required]"
                                                    color="blue" height="28"
                                                    hide-details outlined dense>
                                                </v-text-field>
                                            </td>                                    
                                            <td class="clmsBodyCommon tableBR">
                                                <div class="d-flex justify-center align-center py-2 px-1">
                                                    <span style="width:70px;">
                                                        <v-text-field
                                                        v-model="item.fxNum" :rules="[rules.required]"
                                                        color="blue" type="number" height="28"
                                                        hide-details outlined dense>
                                                    </v-text-field>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="clmsBodyCommon tableBR">
                                                <div class="d-flex justify-center align-center py-2 px-1">
                                                    {{item.lvnRmCnt}}
                                                </div>
                                            </td>
                                            <td class="clmsBodyCommon tableBR">
                                                <div class="d-flex justify-center align-center py-2 px-1">
                                                    <span style="width:70px;">
                                                        <v-text-field
                                                            v-model="item.ord" :rules="[rules.required]"
                                                            color="blue" type="number"
                                                            hide-details outlined dense>
                                                        </v-text-field>
                                                    </span>
                                                    <span>
                                                        <v-btn icon small style="padding:0px !important" @click="onCalculOrd(item)">                                                
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon v-bind="attrs" v-on="on">mdi-calculator</v-icon>
                                                                </template>
                                                                <span>순서 계산</span>
                                                            </v-tooltip>
                                                        </v-btn>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="clmsBodyCommon tableBR py-2 px-1">
                                                <v-select
                                                    v-model="item.upBedYn"
                                                    :items="vCd114"
                                                    item-text="valcdnm" item-value="valcd"
                                                    class="wrkSelect" height="28"
                                                    :rules="[rules.required]"
                                                    dense hide-details outlined>
                                                </v-select>
                                            </td>
                                            <td v-if="dmntTrtYn =='1'" class="clmsBodyCommon tableBR py-2 px-1">
                                                
                                                <v-select
                                                    v-model="item.bnftClcd"
                                                    :items="vCd87"
                                                    item-text="valcdnm" item-value="valcd"
                                                    class="wrkSelect" height="28"
                                                    :disabled="dmntTrtYn !='1'"
                                                    dense hide-details outlined>
                                                </v-select>
                                            </td>
                                            <td class="clmsBodyCommon">
                                                <v-btn icon class="" :disabled="item.lvnRmCnt>0" @click="onDelLvn(item, i)">                                                
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-if="item.lvnRmInfPk &gt; 0" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                                            <v-icon v-else v-bind="attrs" v-on="on">mdi-minus-circle-outline</v-icon>
                                                        </template>
                                                        <span>생활실 삭제</span>
                                                    </v-tooltip>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>                        
                        </v-form>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </div>
        <div v-else class="mt-3 d-inline-block blue001 d-flex justify-center align-center rounded-lg" style="width: 100%; height: 200px; opacity: 0.7;">
            <span class="font-weight-bold white--text" style="font-size: 2rem;">건물를 추가해주세요.</span>
        </div> 
        <v-dialog v-model="menu[0]" content-class="round" persistent max-width="600">
            <oprbuilding v-if="menu[0]" @isClose="isModal(0)" @reload="getLvnRmGrpList"></oprbuilding>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500">
            <btnModalDelete :title="deltitle" @madalState="onDelLvnAct"></btnModalDelete>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { selLvnRmGrpList, insLvnRmInfMulti, delLvnRmInf} from '../../api/opr.js';
import oprbuilding from '../../components/opr/oprbuilding.vue'
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
export default {
    name: 'FacInfoMgmtTab3',

    components: {
        btnModalDelete,
        oprbuilding,
    },

    created: function () {
    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getLvnRmGrpList()
        })
    },

    computed: {

    },

    watch: {
    },

    methods: {                
        getLvnRmGrpList: function () {           
            this.loader = true;
            this.bldInfList.splice(0)
            let obj = {
                fcltyNum:this.$store.getters.getFcltyNum
            }
            selLvnRmGrpList(obj)
                .then((response) => (this.getLvnRmGrpListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selLvnRmGrpList : ' + error))
        },
        preInsLvnRmInf:function(){
            if(this.$refs.frm[0].validate()){                
                let obj = {
                    fcltyNum    : this.$store.getters.getFcltyNum,
                    userNm      : this.$store.getters.getUserNm,
                    list        : []
                }

                this.bldInfList[this.tab].bldFlrList.forEach(itm => {
                    obj.list.push(itm)
                });

                this.setLvnRmInfMulti(obj)

            }else{
                this.snackControll([true, 2500, '필수 항목을 입력해주세요.', 'warning'])
            }
        },
        setLvnRmInfMulti:function(obj){
            this.pretab = this.tab
            insLvnRmInfMulti(obj)
                .then((response) => (this.setLvnRmInfMultiAfter(response.data)))
                .catch((error) => console.log('connect error opr/insLvnRmInfMulti : ' + error))
        },
        removeLvnRmInf:function(obj){
            this.pretab = this.tab
            delLvnRmInf(obj)
                .then((response) => (this.removeLvnRmInfAfter(response.data)))
                .catch((error) => console.log('connect error opr/delLvnRmInf : ' + error))
        },
        getLvnRmGrpListAfter: function (res) {    
            if (res.statusCode == 200) {
                if(res.result != undefined){                   
                    this.dmntTrtYn = res.result.fcltyInf.dmntTrtYn

                    if(res.result.codes != undefined){

                        this.vCd87 = res.result.codes.vCd87

                        this.vCd87.push({valcd:'0',valcdnm:'선택'})
                        res.result.codes.vCd87.forEach(x=>{
                            this.vCd87.push(x)
                        })
                        
                        this.vCd114 = res.result.codes.vCd114
                    }

                    if(res.result.bldInfList != null){

                        res.result.bldInfList.forEach(el => {
                            
                            let bldInf = {
                                bldInfPk    : el.bldInfPk,
                                bldNm       : el.bldNm,
                                bldFlrList  : []
                            }

                            if(res.result.lvnRmGrpList != null){
                                let idx = res.result.lvnRmGrpList.findIndex(x=>x.bldInfPk == el.bldInfPk)

                                if(idx > -1){
                                    let target = res.result.lvnRmGrpList[idx]

                                    if(target.bldFlrList != null){
                                        target.bldFlrList.forEach(el => {
                                            if(this.dmntTrtYn != '1') el.bnftClcd = '1'
                                            bldInf.bldFlrList.push(el)
                                        });
                                    }
                                }
                            }

                            this.bldInfList.push(bldInf)
                        });
                    }
               }
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }

            this.loader = false
            if(this.bldInfList.length < (this.pretab+1)) this.tab = 0
            else this.tab = this.pretab 
        },   
        setLvnRmInfMultiAfter:function(res){
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.getLvnRmGrpList()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        removeLvnRmInfAfter:function(res){
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.getLvnRmGrpList()
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
            this.sltPk = 0
        },
        onAddLvn:function(){
            let obj = {
                lvnRmInfPk  : 0,
                bldInfPk     : this.bldInfList[this.tab].bldInfPk,
                bldNm       : this.bldInfList[this.tab].bldNm,
                bldFlr      : '',
                lvnRmNm     : '',
                fxNum       : 0,
                ord         : 0,
            }

            this.bldInfList[this.tab].bldFlrList.unshift(obj)
        },
        onDelLvn:function(obj, idx){
            if(obj.lvnRmInfPk > 0){
                this.dialogDelete = true
                this.deltitle = '생활실 : '+obj.lvnRmNm
                this.sltPk = obj.lvnRmInfPk
            }else{
                this.bldInfList[this.tab].bldFlrList.splice(idx, 1)
            }
        },
        onDelLvnAct:function(res){
            if (res == 9990) {
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    lvnRmInfPk: this.sltPk,
                    userNm: this.$store.getters.getUserNm,
                }

                this.removeLvnRmInf(obj)
            } else if (res == 7) {
                this.dialogDelete = false
            }
        },
        onCalculOrd:function(obj){
            if(obj.bldFlr != ''){
                let targetList = this.bldInfList[this.tab].bldFlrList.filter(x=>x.bldFlr==obj.bldFlr)
                obj.ord = targetList.length
            }else{
                this.snackControll([true, 5000, "층수를 선택해주세요.", 'error'])
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        isModal: function (value) {
            this.menu.splice(value, 1, false)
        },
    },

    data: () => ({
        sltPk:0,
        tab:0,
        pretab:0,
        dmntTrtYn:'',
        loader:false,
        menu:[false,],
        vCd87:[],
        vCd114:[],
        bldInfList:[{
                bldInfPk:0,bldNm:'',
                bldFlrList:[{
                    bldFlr:'',LvmList:[]
                }]
        }], 
        bldFlrHeader:[
            { text: '층', align:'center', value: 'bldFlr',},            
            { text: '생활실', align:'center', value: 'lvnRmNm', filterable: false,},
            { text: '정원수', align:'center', value: 'fxNum', filterable: false,},
            { text: '입실인원', align:'center', value: 'rmnCnt', filterable: false,},            
            { text: '순서', align:'center', value: 'ord', filterable: false,},
            { text: '생활실종류', align:'center', value: 'upBedYn', filterable: false,},
            { text: '급여수가구분', align:'center', value: 'upBedYn', filterable: false,},
            { text: '처리', align:'center', value: 'actions', filterable: false,},
        ],      
        bldFlrItems: [
            {valcdnm:'선택', valcd:'',},
            {valcdnm:'1층', valcd:'1',},
            {valcdnm:'2층', valcd:'2',},
            {valcdnm:'3층', valcd:'3',},
            {valcdnm:'4층', valcd:'4',},
            {valcdnm:'5층', valcd:'5',},
            {valcdnm:'6층', valcd:'6',},
            {valcdnm:'7층', valcd:'7',},
            {valcdnm:'8층', valcd:'8',},
            {valcdnm:'9층', valcd:'9',},
            {valcdnm:'10층', valcd:'10',},
        ],
        snackOptions: [false, 3000, '', 'info'],
        rules: {
            required: value => !!value || 'Required.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            selCheck: value => !(value == '선택' || value == '') || 'Required.',
        },
        deltitle:'',
        dialogDelete:false,
    }),
};
</script>