<template>
    <v-sheet class="ma-0 " rounded="lg">
        <div class="mt-5 ">
            <v-data-table hide-default-footer dense locale="ko"
                height="450"
                hide-default-header fixed-header 
                :headers="headers" :items="items"
                class="" disable-pagination>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>{{props.headers[0].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" colspan='2'>상급침실비</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>{{props.headers[1].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>{{props.headers[2].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBR tableBT" rowspan='2'>{{props.headers[3].text}}</th>
                            <th class="black--text clmsHeaderCommon tableBT" rowspan='2'>{{props.headers[4].text}}</th>                            
                        </tr>
                        <tr>
                            <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[6].text}}</th>                            
                            <th class="black--text clmsHeaderCommon tableBR tableBT">{{props.headers[7].text}}</th>            
                        </tr>
                    </thead>
                </template>
                <template v-slot:item='{ item }'>
                    <tr>
                        <td class="clmsBodyCommon tableBR">{{item.aplStrt}}&nbsp;~&nbsp;{{item.aplEnd}}</td>
                        <td class="clmsBodyCommon tableBR">{{item.upbedfee1?.toLocaleString('ko-KR')}}원</td>
                        <td class="clmsBodyCommon tableBR">{{item.upbedfee2?.toLocaleString('ko-KR')}}원</td>
                        <td class="clmsBodyCommon tableBR">{{item.mealCost?.toLocaleString('ko-KR') }}원</td>
                        <td class="clmsBodyCommon tableBR">{{item.lqdFoodFee?.toLocaleString('ko-KR') }}원</td>
                        <td class="clmsBodyCommon tableBR">{{item.snackFee?.toLocaleString('ko-KR') }}원</td>
                        <td class="clmsBodyCommon">{{item.btyFee?.toLocaleString('ko-KR') }}원</td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </v-sheet>
</template>

<script>
import { selNbnftFeeHisList } from '../../api/opr.js';

export default {
    name: 'oprFeeInfListTab2',

    components: {
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.selNbnftFeeHisList()
        })
    },

    computed: {

    },

    watch: {
    },
        
    methods: {
        selNbnftFeeHisList: function () {
            selNbnftFeeHisList(this.$store.getters.getFcltyNum)
                .then((response) => (this.selNbnftFeeHisListAfter(response.data)))
                .catch((error) => console.log('connect error opr/selNbnftFeeInf : ' + error))
        },
        selNbnftFeeHisListAfter: function (res) {
            
            this.items.splice(0)
            res.forEach(el => {
                el.aplStrt = el.aplStrt?.beforeDateFormatDot()

                if(el.aplEnd === '29991231'){
                    el.aplEnd = '현재'
                }else{
                    el.aplEnd = el.aplEnd?.beforeDateFormatDot()
                }

                this.items.push(el)
            });
        },
        
    },
    
    data: () => ({
        headers: [
            { text: '적용일', value: 'rn', align: 'center', sortable: true, width: '70', class: 'black--text text-h6'},
            { text: '식사재료비', value: 'name', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '경관유동식', value: 'gndr', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '간식비', value: 'age', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '이·미용비', value: 'entDt', align: 'center', sortable: true, width: '80', class: 'black--text'},
            { text: '기타비', value: 'acknwPrdEnd', align: 'center', sortable: true, width: '100', class: 'black--text'},
            { text: '1인실', value: 'acknwRtng', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '2인실', value: 'entStcd', align: 'center', sortable: true, width: '70', class: 'black--text'},
            { text: '상급침실비', value: 'cnsCnt1q', align: 'center', groupable: true, sortable: true, width: '70', class: 'black--text'},
            { text: '식사재료비', value: 'cnsCnt2q', align: 'center', groupable: true, sortable: true, width: '70', class: 'black--text'},
        ],
        items: [],
    }),
};
</script>