<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth" :max-width="areaWidth+300">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="mx-2 mt-2 d-flex">
            <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn :class="list.class" height="30" :color="list.color"
                    :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="btnDialog[filledBtn.length+j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span>
        </div>
        <v-divider class="my-2"></v-divider>
        <basicInfo ref="basicInfo" :bnMmbrPk="$parent.$parent.sltBnMmBrPk" @setParentData="getBnfcrInfo"></basicInfo>
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">신체제재 내역</span>
        </div>
        <div class="mt-2">
            <v-data-table height="630" locale="ko"
                :headers="physcSRHeaders"
                :items="physcSRList" 
                disable-pagination fixed-header hide-default-header hide-default-footer dense>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>
                            <th class="black--text clmsFixedHeader tableBR" style="width:200px !important;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:360px !important;">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:110px !important;">{{props.headers[3].text}}</th>
                        </tr>
                    </thead>
                </template>
 
                <template v-slot:item='{ item }'>
                    <tr class="disSltRow">
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div class="d-flex justify-center align-center">
                                <span>{{item.snctDtStrt?.beforeDateFormatDot()}}</span>
                                <span>&#126;</span>
                                <span>{{item.snctDtEnd?.beforeDateFormatDot()}}</span>
                            </div>
                        </td>
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div style="width:320px" class="text-overflow">{{item.snctMthd}}</div>
                        </td>
                        <td class="clmsBodyCommon tableBR fixedCol0 ">
                            <div v-if="bnMmbrPk > 0">
                                <div class="crsShp-Pointer" v-if="item.ntcDt !='' && item.ntcDt !=null" @click="onShowGrdnNtc(item.physSnctPk)">
                                    {{item.ntcDt?.beforeDateFormatDot()}}
                                </div>
                                <div v-else>
                                    <v-btn icon color="blueBtnColor" @click="onShowGrdnNtc(item.physSnctPk)">
                                        <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-btn>                                
                                </div>
                            </div>
                        </td>
                        <td class="clmsBodyCommon fixedCol0">
                            <v-btn v-if="bnMmbrPk > 0" icon color="blueBtnColor" @click="onShowDtl(item.physSnctPk)">
                                <v-icon>mdi-square-edit-outline</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog eager v-model="dialog[0]" max-width="800">    
                <physcSnctnRcrdWrt 
                    ref="physcSnctnRcrdWrt" 
                    :bnfcrInfo="bnfcrInfo"
                    :vcd138 = "vCd138"
                    @isClose="isModal(0)" 
                    @modalEvent="modalEvent"></physcSnctnRcrdWrt>                  
            </v-dialog>   
        </div>
        <div class="ma-0 pa-0">
            <v-dialog eager v-model="dialog[1]" max-width="700">    
                <grdnNtcModel 
                    ref="grdnNtcModel"
                    @isClose="isModal(1)"
                    @modalEvent="modalEvent"
                    ></grdnNtcModel>                  
            </v-dialog>   
        </div>
        <div class="ma-0 pa-0">
            <v-dialog eager v-model="dialog[2]" max-width="1400">    
                <physcSnctnRcrdDtl 
                    ref="physcSnctnRcrdDtl" 
                    :bnfcrInfo="bnfcrInfo"
                    :vcd138 = "vCd138"
                    @isClose="isModal(2)" 
                    @modalEvent="modalEvent"></physcSnctnRcrdDtl>                  
            </v-dialog>   
        </div>        
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import physcSnctnRcrdWrt from '../../components/bnftrcrd/PhyscSnctnRcrdWrt.vue';
import physcSnctnRcrdDtl from '../../components/bnftrcrd/PhyscSnctnRcrdDtl.vue';
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import grdnNtcModel from '../../components/bnftrcrd/GrdnNtcModel.vue';
import { selBnfcrPhysSnctList, } from '../../api/bnftrcrd.js';
import { getMultiStcd } from '../../api/index.js';
export default {
    name: 'PhyscSnctnRcrd',
    
    components: {
        expandYearOnlyPicker,
        physcSnctnRcrdWrt,
        physcSnctnRcrdDtl,
        basicInfo,
        grdnNtcModel,
    },

    props : {

    },

    created: function(){       
      
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
            this.bnMmbrPk = this.$parent.$parent.sltBnMmBrPk
            
            if(this.bnMmbrPk > 0){
                this.onLoad()
                this.filledBtn[0].disabled = false
            }else{
                this.filledBtn[0].disabled = true                
            }   

            this.getCode(['138'])
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
        getBnmmbrPk(){
            return this.$parent.$parent.sltBnMmBrPk
        }
    },  

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        getBnmmbrPk:function(value){
            this.bnMmbrPk = value  
            if(value > 0){
                this.onLoad()
                this.filledBtn[0].disabled = false
            }else{
                this.filledBtn[0].disabled = true
                this.physcSRList.splice(0)
            }
        },
        'year':function(value){
            let entStcd = null
            if(this.$parent.$parent.entChgBox == 1)
                entStcd = '4'

            if(value != ''){                
                this.$parent.$parent.getAllPhysSnctList(value, entStcd)
                this.onLoad()
            }
        }
    },

    methods: {
        onLoad:function(){
            this.getBnfcrPhysSnctList()
        },
        onShowDtl:function(pk){
            this.$refs.physcSnctnRcrdDtl.onLoad(pk)
            this.dialog.splice(2, 1, true)
        },
        onShowGrdnNtc:function(pk){
            this.dialog.splice(1, 1, true)
            this.$refs.grdnNtcModel.onLoad('6',pk,this.bnfcrInfo.bnMmbrPk,'1')                
        },
        getBnfcrPhysSnctList(){
            let gFcltyNum = this.$store.getters.getFcltyNum
                      
            selBnfcrPhysSnctList(gFcltyNum,this.bnMmbrPk,this.year)
                .then((response) => ( this.getBnfcrPhysSnctListAfter(response.data)))
                .catch((error) => console.log('upload error /bnftrcrd/selBnfcrPhysSnctList : ' + error))
        },
        getBnfcrPhysSnctListAfter:function(res){
            this.physcSRList = res
        },
        onClickBtn:function(key){
            this.dialog.splice(key, 1, true)
            
            switch (key) {
                case 0:
                    break;
            
                default:
                    break;
            }
        },
        getYear: function (yyyy){
            this.year = yyyy
        },
        getBnfcrInfo: function(item){
            this.bnfcrInfo = item
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        isModal: function (key) {
            this.dialog.splice(key, 1, false)
        },        
        onModal: function (key) {  
            this.dialog.splice(key, 1, true)
        },
        modalEvent: function (event) { 
            if(event == 99){
                let entStcd = null
                if(this.$parent.$parent.entChgBox == 1)
                    entStcd = '4'

                this.onLoad()
                this.$parent.$parent.getAllPhysSnctList(this.year, entStcd)
            }else{
                this.dialog.splice(event, 1, false)
            }      
        },    
        delRow(key){
            this.idx = key
            this.dialogDelete = true
        },
        onDeleteAfter(){
            let entStcd = null
            if(this.$parent.$parent.entChgBox == 1)
                entStcd = '4'

            this.onLoad()  
            this.$parent.$parent.getAllPhysSnctList(this.year, entStcd)                           
        },

        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {

            let obj = {
                valcd: "",
                valcdnm: "선택",
                valclcd: "",
                valclcdnm: "",
            }

            this.vCd138.push(obj)

            res.vCd138.forEach(code => {
                this.vCd138.push(code)
            });
        },
    },
    data: () => ({
        idx:0,
        bzPk:0,
        bnMmbrPk:0,
        year:'',
        bnfcrInfo:{},
        areaWidth: 708,
        snackOptions: [false, 3000, '', 'info'],
        btnDialog: [false, false, false, false, ],
        dialog:[false,false,false,],
        vCd138 : [],
        physcSRHeaders: [            
            { text: '재제기간',width: '', value: '', align: 'center', },
            { text: '제재방법',width: '', value: '', align: 'center', },
            { text: '제재동의',width: '', value: '', align: 'center', },
            { text: '상세기록',width: '', value: '', align: 'center', },
            { text: '처리',width: '', value: '', align: 'center', },
        ],
        physcSRList:[],
        filledBtn: [            
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '신체제재 작성 및 통지', disabled: true, },
        ],
        outlinedBtn: [      
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '신체제재 동의서 출력',  disabled: true, },
        ], 
        dialogDelete:false
    }),
};
</script>