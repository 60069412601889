<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth" :max-width="areaWidth+300">        
        <div class="mx-2 mt-2 d-flex">
            <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
            <v-spacer></v-spacer>
            <span v-for="(list, i) in filledBtn" :key="i">
                <v-btn :class="list.class" height="30" :color="list.color"
                    :disabled="list.disabled" @click="onClickBtn(i)" samll rounded>
                    <v-icon small>{{list.icon}}</v-icon>
                    <span style="font-size: 1rem;">{{list.text}}</span>
                </v-btn>
            </span>
            <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                <v-dialog
                    v-model="btnDialog[filledBtn.length+j]" :max-width="list.width">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                :class="list.class" samll height="30" :color="list.color" rounded outlined>
                                <v-icon small>{{list.icon}}</v-icon>
                                <span style="font-size: 1rem;">{{list.text}}</span>
                            </v-btn>
                        </template>
                        <div>개발중</div>  
                </v-dialog>
            </span>
        </div>
        <v-divider class="my-2"></v-divider>
        <basicInfo ref="basicInfo" :bnMmbrPk="$parent.$parent.sltBnMmBrPk" @setParentData="getBnfcrInfo"></basicInfo>
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">응급상황기록 내역</span>
        </div>
        <div class="mt-2">
            <v-data-table height="630" locale="ko"
                :headers="emrgnRcrdHeaders"
                :items="emrgnRcrdList" 
                disable-pagination fixed-header hide-default-header hide-default-footer dense>
                <template v-slot:header="{ props }" >
                    <thead>
                        <tr>
                            <th class="black--text clmsFixedHeader tableBR" style="width:200px !important;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:400px !important;">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader" style="width:110px !important;">{{props.headers[3].text}}</th>
                        </tr>
                    </thead>
                </template>
 
                <template v-slot:item='{ item, index }'>
                    <tr class="disSltRow">
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.recDt?.beforeDateFormatDot()}}</td>
                        <td class="clmsBodyCommon tableBR fixedCol0">
                            <div style="width:400px" class="text-overflow">{{item.itm2Val}}</div>
                        </td>                        
                        <td class="clmsBodyCommon tableBR fixedCol0">{{item.wrtr}}</td>
                        <td class="clmsBodyCommon fixedCol0">
                            <div class="d-flex justify-center align-center">   
                                <v-btn icon @click="onShowEmrgn(item.bnftOfrRecHisPk)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>                             
                                <v-btn icon color='grey006' @click="delRow(index)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>                                
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog eager v-model="dialog[0]" max-width="1000">    
                <emrgnRcrdWrt 
                    v-if="dialog[0]"
                    ref="emrgnRcrdWrt" 
                    :bnfcrInfo="bnfcrInfo"
                    @isClose="isModal(0)" 
                    @modalEvent="modalEvent"></emrgnRcrdWrt>                  
            </v-dialog>   
        </div>       
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import emrgnRcrdWrt from '../../components/bnftrcrd/EmrgnRcrdWrt.vue';
import basicInfo from '../../components/bnfcr/BnfcrBasicInfo.vue';
import { selBnftOfrRec305List, delEmrgnRcrd } from '../../api/bnftrcrd.js';

export default {
    name: 'EmrgnRcrd',
    
    components: {
        expandYearOnlyPicker,
        emrgnRcrdWrt,
        basicInfo,
    },

    props : {

    },

    created: function(){       
      
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
            this.bnMmbrPk = this.$parent.$parent.sltBnMmBrPk
            
            if(this.bnMmbrPk > 0){
                this.onLoad()
                this.filledBtn[0].disabled = false
            }else{
                this.filledBtn[0].disabled = true
            }      
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
        getBnmmbrPk(){
            return this.$parent.$parent.sltBnMmBrPk
        },
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },  

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        getBnmmbrPk:function(value){
            this.bnMmbrPk = value  
            if(value > 0){
                this.onLoad()
                this.filledBtn[0].disabled = false
            }else{
                this.filledBtn[0].disabled = true
            }
        },
        'year':function(value){
            let entStcd = null
            if(this.$parent.$parent.entChgBox == 1)
                entStcd = '4'

            if(value != ''){
                this.$parent.$parent.getAllEmergRecList(value, entStcd)
                this.onLoad()
            }
        },
        getSubEvt: function (bol) {            
            if(bol && this.rmEmrgnCnfm){
                this.rmEmrgnRcrd()
                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });
                this.rmEmrgnCnfm = false
            }
        },
    },

    methods: {
        onLoad:function(){
            this.getBnftOfrRecList()
        },
        onClickBtn:function(key){
            this.dialog.splice(key, 1, true)
            
            switch (key) {
                case 0:
                    this.sltHisPk = 0
                    break;
            
                default:
                    break;
            }
        },       
        onShowEmrgn:function(pk){
            this.sltHisPk = pk
            this.dialog.splice(0, 1, true)
        },
        delRow:function(idx){
            this.idx=idx
            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '응급상황기록'
            obj.cntnt = [this.emrgnRcrdList[idx].recDt+' 의 응급상황기록을(를) 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   

            this.rmEmrgnCnfm = true
        },
        
        getBnftOfrRecList(){
            this.emrgnRcrdList.splice(0)

            let obj = {
                fcltyNum: this.$store.getters.getFcltyNum, 
                clsfc1Cd: '305',
                clsfc2Cd: '1',
                clsfc3Cd: '1',
                bnMmbrPk: this.bnMmbrPk,
                inqYYYY:this.year,
            }

            selBnftOfrRec305List(obj)
                .then((response) => ( this.getBnftOfrRecListAfter(response.data)))
                .catch((error) => console.log('upload error /bnftrcrd/selBnftOfrRec305List : ' + error))
        },
        getBnftOfrRecListAfter:function(res){
            
            let obj = {}

            if(res.statusCode == 200){
                if(res.result.length > 0){   
                    res.result.forEach(x=>{
                        this.emrgnRcrdList.push(x)
                    })

                    if(this.sltLoad) this.$refs.emrgnRcrdWrt.getBnftOfrRec('reload')                    
                }
            }else{
                    obj.actv = true                 // 경고 노출여부
                    obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                    obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                    obj.title = res.message         // 경고 타이틀
                    this.$store.commit('setWrnnInf', obj)
            }     

            this.sltLoad = false
        },

        rmEmrgnRcrd:function(){       
            
            if(this.emrgnRcrdList[this.idx].bnftOfrRecHisPk > 0 && this.emrgnRcrdList[this.idx].bnftOfrRec305Pk > 0){    

                let obj = {
                    fcltyNum:this.$store.getters.getFcltyNum,
                    bnftOfrRecHisPk:this.emrgnRcrdList[this.idx].bnftOfrRecHisPk,
                    bnftOfrRec305Pk:this.emrgnRcrdList[this.idx].bnftOfrRec305Pk,
                    userNm:this.$store.getters.getUserNm,
                }         
                
                delEmrgnRcrd(obj)
                    .then((response) => ( this.onDeleteAfter(response.data)))
                    .catch((error) => console.log('upload error /bnftrcrd/delEmrgnRcrd : ' + error))
            }
        },
        onDeleteAfter(res){            
            this.idx = 0
            let entStcd = null
            if(this.$parent.$parent.entChgBox == 1)
                entStcd = '4'

            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){       

                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)
                this.onLoad()               
                this.$parent.$parent.getAllEmergRecList(this.year, entStcd)
            }
            else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                this.$store.commit('setWrnnInf', obj)
            }      
        },
        
        getYear: function (yyyy){
            this.year = yyyy
        },
        getBnfcrInfo: function(item){
            this.bnfcrInfo = item
        },
        modalEvent: function (event) { 
            if(event == 99 || event == 100){
                let entStcd = null
                if(this.$parent.$parent.entChgBox == 1)
                    entStcd = '4'

                if(event == 100) this.sltLoad = true
                this.onLoad()
                this.$parent.$parent.getAllEmergRecList(this.year, entStcd)
            }else{
                this.dialog.splice(event, 1, false)
            }      
        },    
        isModal: function (key) {
            this.dialog.splice(key, 1, false)
        },         
    },
    data: () => ({
        rmEmrgnCnfm:false,
        idx:0,
        year:'',
        bnMmbrPk:0,
        areaWidth: 708,
        bnfcrInfo:{},
        sltHisPk:0,
        sltLoad:false,        
        btnDialog: [false, false, false, false, ],
        dialog:[false,false,false,],
        emrgnRcrdHeaders: [            
            { text: '발생일',width: '', value: '', align: 'center', },
            { text: '사유',width: '', value: '', align: 'center', },
            { text: '작성자',width: '', value: '', align: 'center', },
            { text: '처리',width: '', value: '', align: 'center', },
        ],
        emrgnRcrdList:[],
        filledBtn: [            
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '작성', disabled: true, },
        ],
        outlinedBtn: [      
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '출력',  disabled: false, },
        ], 
    }),
};
</script>