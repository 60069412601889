<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="550">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left align-center mb-2 ">
            <span class="text-h4 font-weight-bold">보호자 통지</span>
            <v-spacer></v-spacer>            
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-left align-center mb-2">
            <v-spacer></v-spacer>
            <v-btn v-for="(list, i) in filledBtn" :key="i"  @click="onClickBtn(i)"
                :class="list.class" height="30" :color="list.color" rounded
                :disabled="list.disabled"
                style="font-size: 14px;">
                <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>            
        </div>
        <v-form ref="mform" lazy-validation>
            <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">구분</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <span class="d-inline-block" style="width: 150px;">
                        <v-select 
                            v-model="items.ntcClcd"                               
                            :items="codeItem59"
                            item-text="valcdnm"
                            item-value="valcd"
                            dense hide-details outlined
                            :rules="[rules.selectCheck]"
                        /> 
                    </span>
                </v-col>            
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">통지일시</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <span class="white d-inline-block" style="width: 154px;">   
                        <CmmDateComp v-model="items.ntcDt" :required="true"></CmmDateComp>                                                
                    </span>
                    <span class="white d-inline-block pa-1" style="width: 70px;"> 
                        <CmmTimeField v-model="items.ntcTm" :required="true"></CmmTimeField>                        
                    </span>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">통지방법</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                    <span class="d-inline-block" style="width: 205px;">
                        <v-radio-group
                            v-model="items.ntcMthdCd" :rules="[rules.required]"
                            class="radio-dft-Theme mt-0" hide-details :disabled="items.grdnNtcPk > 0" row :key="forceRender">
                            <v-radio v-for="(list, j) in codeItem39" :key="j" class="pa-0 mr-1" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                        </v-radio-group>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">동의여부</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                    <span class="d-inline-block" style="width: 205px;">
                        <v-radio-group
                            class="radio-dft-Theme mt-0"
                            v-model="items.agrYn" :rules="[rules.required]" hide-details row :key="forceRender">
                            <v-radio v-for="(list, j) in codeItem1" :key="j" class="pa-0 mr-1" :ripple="false" :label="list.valcdnm" :value="list.valcd"></v-radio>                                
                        </v-radio-group>
                    </span>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">통지대상</span>
                </v-col>
                <v-col class="pa-1 ma-0" cols="4">
                    <div class="d-flex justify-start align-center" v-if="items.grdnNtcPk > 0">
                        <span>{{items.ntcTrgtRltns}}</span>
                        <span class="px-1">&#47;</span>
                        <span>{{items.ntcTrgtNm}}</span>
                    </div>
                    <div class="d-flex justify-start align-center" v-else>
                        <span style="width:60px;">
                            <v-text-field v-model="items.ntcTrgtRltns" :disabled="items.grdnNtcPk > 0" :rules="[rules.required]" height="28" hide-details outlined dense placeholder='관계'></v-text-field>
                        </span>
                        <span class="pl-2" style="width:100px;">
                            <v-text-field v-model="items.ntcTrgtNm" :disabled="items.grdnNtcPk > 0" :rules="[rules.required]" height="28" hide-details outlined dense placeholder='이름'></v-text-field>
                        </span>
                    </div>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">연락처</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                    <div v-if="items.grdnNtcPk > 0">
                        <span>{{items.ntcTrgtTel1}}</span>
                        <span class="px-1">&#45;</span>
                        <span>{{items.ntcTrgtTel2}}</span>
                        <span class="px-1">&#45;</span>
                        <span>{{items.ntcTrgtTel3}}</span>
                    </div>
                    <span v-else class="d-inline-block pt-0 pb-1">                           
                        <span class="d-inline-block" style="width: 50px;">
                            <v-text-field v-model="items.ntcTrgtTel1" 
                            :disabled="items.grdnNtcPk > 0"
                            :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]" height="28" hide-details outlined dense></v-text-field>
                        </span>
                        <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                        &#45;
                        </span>
                        <span class="d-inline-block" style="width: 60px;">
                            <v-text-field v-model="items.ntcTrgtTel2" :disabled="items.grdnNtcPk > 0"
                            :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]" height="28" hide-details outlined dense></v-text-field>
                        </span>
                        <span class="d-inline-block text-h5 font-weight-bold pa-0 pl-1" style="width: 15px;">
                        &#45;
                        </span>
                        <span class="d-inline-block" style="width: 60px;">
                            <v-text-field v-model="items.ntcTrgtTel3" :disabled="items.grdnNtcPk > 0"
                            :rules="[rules.required, rules.numberCheck, rules.numberSizeCheck]" height="28" hide-details outlined dense></v-text-field>
                        </span>
                    </span>
                </v-col>            
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">통지내용</span>
                </v-col>
                <v-col class="pa-2 ma-0" cols="10">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <div v-if="items.grdnNtcPk > 0" v-html="items.ntcCntnt.replaceAll('\n','<br>')" class="overflow-y-auto" style="height:300px;"></div>
                            <div v-else>
                                <v-textarea v-model="items.ntcCntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                    :rules="[rules.required]"
                                    :disabled="items.grdnNtcPk > 0"
                                    rows="15" outlined dense hide-details no-resize>
                                </v-textarea>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="d-flex" cols="12">
                            <v-spacer></v-spacer>
                            <div class="pr-2" style="font-size:1.0rem !important;">
                                <span>{{items.ntcCntnt?.getBytes()}}</span>
                                <span>/</span>
                                <span>80bytes</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>                           
            </v-row>
        </v-form>
        <div v-if="items.grdnNtcPk > 0" class="d-flex align-center mt-3">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">신체제재 내역</span>
        </div>
        <v-row v-if="items.grdnNtcPk > 0" class="mt-2" no-gutters>
            <v-col class="d-flex" cols="12">
                <div>
                    <v-data-table class="msclnTable smsSndHistTable pt-1" height="80" locale="ko"
                        :headers="smsSndHistHeader" 
                        :items="smsSndHistList" 
                        item-key="mmbrPk" checkbox-color="primary"
                        fixed-header disable-pagination hide-default-footer dense>                        
                        <template v-slot:item='{ item }'>
                            <tr>                                
                                <td class="tableBB" style="height:40px !important; text-align: center;">
                                    <span class="cmmFontSizeOne">{{ item.rcvNm }}</span>
                                    <span class="d-inline-block" style="width: 18px;"></span>
                                </td>
                                <td class="tableBB" style="height:40px !important; text-align: center;">
                                    <span class="cmmFontSizeOne">{{ item.rcvInfm }}</span>
                                    <span class="d-inline-block" style="width: 18px;"></span>
                                </td>
                                <td class="tableBB" style="height:40px !important; text-align: center;">
                                    <span class="cmmFontSizeOne">{{ item.sndMdaNm }}</span>
                                    <span class="d-inline-block" style="width: 18px;"></span>
                                </td>  
                                <td class="tableBB" style="height:40px !important; text-align: center;">
                                    <span class="cmmFontSizeOne">{{ item.sndDt?.beforeDateFormatDot() }}</span>
                                    <span class="d-inline-block" style="width: 18px;"></span>
                                </td>                                                                     
                                <td class="tableBB" style="height:40px !important; text-align: center;">
                                    <span class="cmmFontSizeOne">{{ item.sndSttsCdNm }}</span>
                                    <span class="d-inline-block" style="width: 18px;"></span>
                                </td>     
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </v-col>
        </v-row>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'보호자 통지'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>   
        </div> 
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import { getMultiStcd } from '../../api/index.js';
import { selGrdnNtcInfo,insGrdnNtc,delGrdnNtc } from '../../api/bnftrcrd.js';
import { insBizMsgSmsSnd } from '../../api/othr.js'
import { selESndList } from '../../api/othr.js'
export default {
    name: 'GrdnNtcModel',
    
    components: {
        CmmDateComp,
        CmmTimeField,
        btnModalDelete,
    },

    props : {    
       
    },

    created: function(){       
        getMultiStcd(['39','59','109'])
                .then((response) => ( this.codeMapping(response.data) ))
                .catch((error) => console.log(error))
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
          
        })
    },

    computed: {
       
    },  

    watch: {
        
    },
 
    methods: {  
        onLoad:function(clcd,bzpk,mmbrpk,ntcclcd=''){
            this.bzClcd = clcd  
            this.bzPk = bzpk 
            this.bnMmbrPk = mmbrpk
            this.items.ntcClcd = ntcclcd
            this.getGrdnNtcInfo()
            this.getESndList()
        },  
        onClickBtn:function(key){
            switch (key) {
                case 0:
                    this.onSave()
                    break;
                case 1:
                    this.onSmsSend()
                    break;
                case 2:
                    this.dialogDelete = true
                    break;
                default:
                    break;
            }
        },
        onSave:function(){
            if(this.bzClcd !=null && this.bzClcd != ''&& this.bzPk>0){
                if(this.$refs.mform.validate()){
                    let gFcltyNum = this.$store.getters.getFcltyNum
                    let gBnMmbrPk = this.bnMmbrPk
                    let gUserNm = this.$store.getters.getUserNm

                    let obj = JSON.parse(JSON.stringify(this.items))
                    obj.fcltyNum = gFcltyNum
                    obj.bnMmbrPk = gBnMmbrPk
                    obj.userNm = gUserNm
                    obj.wrtr = gUserNm
                    obj.bzClcd = this.bzClcd
                    obj.bzPk = this.bzPk
                    obj.ntcDt = obj.ntcDt.afterDateFormatHyp()

                    if(obj.ntcTm !=''){
                        let ntcTm = obj.ntcTm.split(":")
                        obj.ntcTmHh = ntcTm[0]
                        obj.ntcTmMm = ntcTm[1]
                    }else{
                        obj.ntcTmHh = ''
                        obj.ntcTmMm = ''
                    }

                    insGrdnNtc(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insPhysSnct : ' + error))
                }
            }
        },
        onSaveAfter:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀

            if(res.statusCode == 200){                                                                      
                this.$emit('modalEvent', 99);
                this.onClose()   
                // this.items.grdnNtcPk = res.result
                // if(this.smsSndHistList.length > 0){
                //     this.filledBtn[1].disabled = true
                // }else{
                //     this.filledBtn[1].disabled = false
                // }
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
            }else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }

            this.$store.commit('setWrnnInf', obj)
        },
        onSmsSend:function(){      
            if(this.$refs.mform.validate()){

                let pObj = {  
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,                
                    bizMsglist:[]
                }
    
                let obj = {
                    sndMda:'2',
                    fcltyNum:this.$store.getters.getFcltyNum,
                    userNm:this.$store.getters.getUserNm,
                    bzClcd:this.bzClcd,
                    bzPk:this.bzPk,
                    eDocClcd:'',
                    prcClcdAr:[],
                    prcCnfmAr:[],
                    rcvCelphn1:this.items.ntcTrgtTel1,
                    rcvCelphn2:this.items.ntcTrgtTel2,
                    rcvCelphn3:this.items.ntcTrgtTel3,
                    rcvNm:this.items.ntcTrgtNm,
                    rcvInfm:this.items.ntcTrgtRltns,
                    msgTtl:'신체제재 작성 및 통지 >  통지문자발송',
                    msgCntnt:this.items.ntcCntnt,
                }

                if(this.bzClcd == '6') obj.eDocClcd = '21'
                else if(this.bzClcd == '7') obj.eDocClcd = '22'

                pObj.bizMsglist.push(obj)

                if(pObj.bizMsglist.length > 0){
                    insBizMsgSmsSnd(pObj)
                        .then((response) => ( this.onSmsSendAfter(response.data) ))
                        .catch((error) => console.log('connect error /othr/insBizMsgNoSnd : ' + error))
                }else{
                    this.snackControll([true, 5000, '수신자를 선택해주세요.', 'error'])
                }
            }
        },
        onSmsSendAfter:function(res){
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀

            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getESndList()  
                this.$emit('modalEvent', 99);              
            }
            else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }

            this.$store.commit('setWrnnInf', obj)
        },
        onDelete:function(res){
            if(res == 9990){  
                
                if(this.items.grdnNtcPk > 0){
                    delGrdnNtc(this.$store.getters.getFcltyNum,this.$store.getters.getUserNm,this.items.grdnNtcPk)
                        .then((response) => ( this.onDeleteAfter(response.data)))
                        .catch((error) => console.log('upload error /bnftrcrd/delGrdnNtc : ' + error))
                }else{
                    this.dialogDelete = false    
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter(res){  
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
          
            if(res.statusCode == 200){                                                
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.$emit('modalEvent', 99);
                this.onClose()             
            }else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }
            this.idx = 0
            this.dialogDelete = false    
            this.$store.commit('setWrnnInf', obj)        
        },
        getGrdnNtcInfo:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum      

            let obj = {
                fcltyNum:gFcltyNum,
                bzClcd:this.bzClcd,
                bzPk:this.bzPk
            }    
            selGrdnNtcInfo(obj)
                .then((response) => ( this.getGrdnNtcInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selPhysSnctInfo : ' + error))
        },
        getGrdnNtcInfoAfter:function(res){
            if(res != '' && res !=null){
                if(res.ntcMthdCd == '2'){
                    this.filledBtn[1].disabled = false
                }
                // this.filledBtn[0].disabled = true
                this.filledBtn[2].disabled = false
                res.ntcDt = res.ntcDt.beforeDateFormatHyp()
                res.ntcTm = res.ntcTmHh+":"+res.ntcTmMm
                this.items = res  
            }else{
                // this.filledBtn[0].disabled = false
                this.filledBtn[1].disabled = true
                this.filledBtn[2].disabled = true
            }                        
        },
        getESndList:function(){
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                bzClcd : this.bzClcd,
                bzPk : this.bzPk,
                eDocClcd: '',
            }

            if(this.bzClcd == '6') obj.eDocClcd = '21'
            else if(this.bzClcd == '7') obj.eDocClcd = '22'

            selESndList(obj)
                .then((response) => ( this.getESndListAfter(response.data) ))
                .catch((error) => console.log('connect error /othr/selESndList : ' + error))
        },
        getESndListAfter:function(res){
            this.smsSndHistList.splice(0)
            
            res.forEach(item => {
                
                switch (item.sndMda) {
                    case '1':
                        item.sndMdaNm = '알림톡'
                        break;
                    case '2':
                        item.sndMdaNm = 'SMS'
                        break;
                    case '3':
                        item.sndMdaNm = 'E-MAIL'
                        break;
                    default:
                        break;
                }

                this.codeItem109.forEach(cd => {
                    if(cd.valcd == item.sndSttsCd) item.sndSttsCdNm = cd.valcdnm
                });

                
            });
            
            this.smsSndHistList = res

            if(res.length > 0){
                this.filledBtn[1].disabled = true
            }
        },
        
        codeMapping: function (res){            
            this.codeItem39 = res.vCd39
            this.codeItem59 = res.vCd59
            this.codeItem109 = res.vCd109
        },  
        snackControll: function (options) {
            this.snackOptions = options
        },
        onClose: function () {
            this.$emit('isClose', 0)            
            this.$refs.mform.reset()
            this.items.grdnNtcPk=''
            // this.filledBtn[0].disabled = true
            this.filledBtn[1].disabled = true
            this.filledBtn[2].disabled = true
            this.$refs.mform.resetValidation()
        },        
    },    
    data: () => ({
        bzClcd:'',
        bzPk:0,
        bnMmbrPk:0,
        forceRender:0,
        codeItem1:[{valcdnm:'동의',valcd:'1'},{valcdnm:'비동의',valcd:'2'}],
        codeItem39:[],
        codeItem59:[],
        codeItem109:[],
        items:{
            grdnNtcPk:0,
            fcltyNum:'',
            ntcClcd:'',
            ntcDt:'',
            bzClcd:'',
            bzPk:0,
            ntcTm:'',
            ntcTmHh:'',
            ntcTmMm:'',
            ntcMthdCd:'',
            ntcTrgtRltns:'',
            ntcTrgtNm:'',
            ntcTrgtTel1:'',
            ntcTrgtTel2:'',
            ntcTrgtTel3:'',
            agrYn:'',
            ntcCntnt:'',
            wrtr:'',
            userNm:'',
        },
        menu:[false],        
        mtimes:false,
        smsSndHistHeader:[
            { text: '수신자', value: 'rcvNm', align: 'center', on: false, sortable: true, width: '145', class: 'black--text sftyHeader py-2'},
            { text: '수신자 정보', value: 'rcvInfm', align: 'center', on: false, sortable: true, width: '150', class: 'black--text sftyHeader py-2'},
            { text: '발송종류', value: 'sndMda', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader py-2'},
            { text: '발송일', value: 'sndDt', align: 'center', on: false, sortable: true, width: '150', class: 'black--text sftyHeader py-2'},
            { text: '전송상태', value: 'sndSttsCd', align: 'center', on: false, sortable: true, width: '100', class: 'black--text sftyHeader py-2'},
        ],
        smsSndHistList:[],
        snackOptions: [false, 3000, '', 'info'],        
        filledBtn: [            
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false, },
            { icon: 'mdi-arrow-expand-right', class: 'ml-1 white--text', color:'blueBtnColor', text: '통지문자발송', disabled: true, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        dialogDelete:false,
    }),
};
</script>