<template>
    <v-sheet class="pa-0 ma-0" style="height:750px;">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <!-- 생활실 목록 -->
            <v-col class="midAreaColor pl-3 py-4 pr-2" cols="3">
                <div class="d-flex justify-left mb-5">
                    <span class="text-h4 font-weight-bold">생활실별 투약관리</span>
                    <v-spacer></v-spacer>
                </div>  
                <div class="mt-2 d-flex align-center">
                    <div style="width:140px;" class="mdctnmgmt-date">
                        <CmmDateComp v-model="ymd" style="background:white;"></CmmDateComp>
                    </div>
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn
                            class="ml-1" color="blueBtnColor" style="background:white;padding: 13px 12px 12px 10px !important"
                            min-width="30" height="25" 
                            @click="onMdctnLvnRmSet"
                            small outlined>
                            <v-icon size="20">mdi-timer-cog-outline</v-icon>
                            <span class="fontOneRem ml-1" style="padding-bottom: 2px;">생활실 시간설정</span>                
                        </v-btn>
                    </div>
                </div>              
                <div class="mt-2 rounded-lg">
                    <v-data-table
                        class="msclnTable" height="600"  locale="ko"
                        :headers="headers" 
                        :items="lvnRmDoseSmryList" 
                        disable-pagination fixed-header hide-default-header hide-default-footer dense>
                        <template v-slot:header="{ props }" >
                            <thead>
                                <tr>
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:10%;">
                                        <div style="padding-left:3px;">
                                            <span class="d-inline-block" style="width: 26px;">
                                                <v-checkbox
                                                    class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                                    v-model="allChckr" :key="forceRender" @click="allChnger"
                                                    true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                                    hide-details>
                                                </v-checkbox>
                                            </span>
                                        </div>
                                    </th>
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:10%;">{{props.headers[0].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:54%;">{{props.headers[1].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBR tableBT" style="width:13%;">{{props.headers[2].text}}</th>
                                    <th class="black--text clmsHeaderCommon tableBT" style="width:13%;">{{props.headers[3].text}}</th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item='{ item }'>
                            <tr>
                                <td class="tableBR tableBB"> 
                                    <div class="d-flex justify-center align-center">
                                        <v-checkbox
                                            class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"
                                            v-model="item.checkr" :key="forceRender" :disabled="item.disChekr" @change="allChekrFunc"
                                            true-value="1" false-value="2" on-icon="mdi-check-circle-outline" off-icon="mdi-minus-circle-outline"
                                            hide-details>
                                        </v-checkbox>
                                    </div>                               
                                </td>
                                <td class="tableBR tableBB">
                                    <div class="d-flex justify-center align-center">{{item.bldFlrNm}}</div>
                                </td>
                                <td class="tableBR tableBB">
                                    <div class="d-flex justify-center align-center">{{item.lvnRmNm}}</div>
                                </td>
                                <td class="tableBR tableBB">
                                    <div class="d-flex justify-center align-center">{{item.doseCnt}}</div>
                                </td>
                                <td class="tableBB">
                                    <div class="d-flex justify-center align-center">{{item.recCnt}}</div>
                                </td>                                
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </v-col>
            <!-- 투약관리 -->
            <v-col class="pl-3 py-4 pr-2" cols="9">
                <div class="d-flex justify-left mb-1">
                    <v-spacer></v-spacer>
                    <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
                </div> 
                <div class="d-flex justify-left align-center mb-2 pr-2">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3" width="20" height="20" @click="$store.commit('setOnDscrp', true), $store.commit('setSltDscrp', 21)" icon>
                        <v-icon size="20" style="padding-top: 2px;">mdi-help-circle-outline</v-icon>
                    </v-btn>
                    <span class="ml-1 mr-3 grey006--text fontOneRem">투약관리 저장시 유의사항</span>
                    <span v-if="progress">
                        <v-progress-circular            
                        :size="30"
                        color="primary"
                        indeterminate
                        ></v-progress-circular>
                    </span>
                    <span v-if="!progress">
                        <v-btn v-for="(list, i) in filledBtn" :key="i"  @click="onClickBtn(list.text)"
                            :class="list.class" height="30" :color="list.color" rounded
                            :disabled="list.disabled"
                            style="font-size: 14px;">
                            <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                            {{list.text}}
                        </v-btn>      
                    </span>      
                </div>       
                <v-form ref="form" lazy-validation>
                <div class="mt-2">
                    <span v-if="progresslist">
                        <v-progress-linear            
                        color="primary"
                        height="5"
                        indeterminate
                        ></v-progress-linear>
                    </span>
                    <div width="100%" class="d-flex pa-0 greyE01 mr-2__5" style="border-top:1px solid #dbdbdb !important;">                                
                        <div class="d-flex justify-center align-center tableBB" style="width:7%;">                                
                        </div>
                        <div style="width:93%;">
                            <div class="d-flex">
                                <div class="d-flex justify-center align-center tableBB" style="width:5%;">
                                </div>
                                <div style="width:91%;">
                                    <div class="d-flex">
                                        <div class="d-flex justify-center align-center tableBB" style="width:20%;">
                                            <span class="black--text font-weight-medium">제공자</span>
                                        </div>
                                        <div class="d-flex justify-center align-center tableBB " style="width:10%;">
                                        </div>
                                        <div class="d-flex justify-center align-center tableBB " style="width:7%;">
                                        </div>
                                        <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                            <div>
                                                <div class="d-flex px-1 py-2 justify-center align-center" style="background: #FFF;">
                                                    <span style="width:60px;">
                                                        <v-text-field class="mdctnField" v-model="wrtrGr[0]" color="blue" height="28" ref="info"
                                                            hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(0)"></v-text-field>
                                                    </span>
                                                </div>
                                            </div>                                  
                                        </div>
                                        <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                            <div>
                                                <div class="d-flex px-1 py-2 justify-center align-center" style="background: #FFF;">
                                                    <span style="width:60px;">
                                                        <v-text-field class="mdctnField" v-model="wrtrGr[1]" color="blue" height="28" ref="info"
                                                            hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(1)"></v-text-field>
                                                    </span>
                                                </div>
                                            </div>                                  
                                        </div>
                                        <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                            <div>
                                                <div class="d-flex px-1 py-2 justify-center align-center" style="background: #FFF;">
                                                    <span style="width:60px;">
                                                       <v-text-field class="mdctnField" v-model="wrtrGr[2]" color="blue" height="28" ref="info"
                                                            hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(2)"></v-text-field>
                                                    </span>
                                                </div>
                                            </div>                                  
                                        </div>
                                        <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                            <div>
                                                <div class="d-flex px-1 py-2 justify-center align-center" style="background: #FFF;">
                                                    <span style="width:60px;">
                                                        <v-text-field class="mdctnField" v-model="wrtrGr[3]" color="blue" height="28" ref="info"
                                                            hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(3)"></v-text-field>
                                                    </span>
                                                </div>
                                            </div>                                  
                                        </div>   
                                        <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                            <div>
                                                <div class="d-flex px-1 py-2 justify-center align-center" style="background: #FFF;">
                                                    <span style="width:60px;">
                                                        <v-text-field class="mdctnField" v-model="wrtrGr[4]" color="blue" height="28" ref="info"
                                                            hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(4)"></v-text-field>
                                                    </span>
                                                </div>
                                            </div>                                  
                                        </div>
                                        <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                            <div>
                                                <div class="d-flex px-1 py-2 justify-center align-center" style="background: #FFF;">
                                                    <span style="width:60px;">
                                                        <v-text-field class="mdctnField" v-model="wrtrGr[5]" color="blue" height="28" ref="info"
                                                            hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(5)"></v-text-field>
                                                    </span>
                                                </div>
                                            </div>                                  
                                        </div>                                             
                                        <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                            <div>
                                                <div class="d-flex px-1 py-2 justify-center align-center" style="background: #FFF;">
                                                    <span style="width:60px;">
                                                        <v-text-field class="mdctnField" v-model="wrtrGr[6]" color="blue" height="28" ref="info"
                                                            hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(6)"></v-text-field>
                                                    </span>
                                                </div>
                                            </div>                                  
                                        </div>
                                        <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                            <div>
                                                <div class="d-flex px-1 py-2 justify-center align-center" style="background: #FFF;">
                                                    <span style="width:60px;">
                                                        <v-text-field class="mdctnField" v-model="wrtrGr[7]" color="blue" height="28" ref="info"
                                                            hide-details outlined dense :key="forceRender" readonly @click="onEmpModel(7)"></v-text-field>
                                                    </span>
                                                </div>
                                            </div>                                  
                                        </div>                                                  
                                        <div class="d-flex justify-center align-center px-1 py-2 ma-0 tableBB tableBL" style="width:7%;">                                                                                    
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-center align-center ma-0 tableBB" style="width:4%;">                                                                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="rmDoseInfList.length > 0" style="height:550px;overflow-y:scroll">
                        <div v-for="(item, i) in rmDoseInfList" :key="i" style="border-bottom:3px solid #005593">
                            <div width="100%" class="d-flex pa-0 greyE01" style="font-size:0.9rem !important">                                
                                <div class="d-flex justify-center align-center tableBB" style="width:7%;">
                                    생활실
                                </div>
                                <div style="width:93%;">
                                    <div class="d-flex">
                                        <div class="d-flex justify-center align-center tableBB tableBL" style="width:5%;">
                                            <span class="black--text font-weight-medium">투약명</span>
                                        </div>
                                        <div style="width:91%;">
                                            <div class="d-flex">
                                                <div class="d-flex justify-center align-center tableBB tableBL" style="width:20%;">
                                                    <span class="black--text font-weight-medium">투약명</span>
                                                </div>
                                                <div class="d-flex justify-center align-center tableBB tableBL" style="width:10%;">
                                                    <span class="black--text font-weight-medium">투약량</span>
                                                </div>
                                                <div class="d-flex justify-center align-center tableBB tableBL" style="width:7%;">
                                                    <span class="black--text font-weight-medium">투약방법</span>
                                                </div>
                                                <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                                    <div>
                                                        <div class="d-flex pa-1 justify-center align-center tableBB"><span class="black--text font-weight-medium">아침식전</span></div>
                                                        <div class="d-flex pa-1 justify-center align-center" style="background: #FFF;">
                                                            <span style="width:60px;"><CmmTimeField v-model="item.lvnRmInfo.brkfsBfTm"></CmmTimeField></span>
                                                        </div>
                                                    </div>                                  
                                                </div>
                                                <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                                    <div>
                                                        <div class="d-flex pa-1 justify-center align-center tableBB"><span class="black--text font-weight-medium">아침식후</span></div>
                                                        <div class="d-flex pa-1 justify-center align-center" style="background: #FFF;">
                                                            <span style="width:60px;"><CmmTimeField v-model="item.lvnRmInfo.brkfsAfTm"></CmmTimeField></span>
                                                        </div>
                                                    </div>                                  
                                                </div>
                                                <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                                    <div>
                                                        <div class="d-flex pa-1 justify-center align-center tableBB"><span class="black--text font-weight-medium">점심식전</span></div>
                                                        <div class="d-flex pa-1 justify-center align-center" style="background: #FFF;">
                                                            <span style="width:60px;"><CmmTimeField v-model="item.lvnRmInfo.lunchBfTm"></CmmTimeField></span>
                                                        </div>
                                                    </div>                                  
                                                </div>
                                                <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                                    <div>
                                                        <div class="d-flex pa-1 justify-center align-center tableBB"><span class="black--text font-weight-medium">점심식후</span></div>
                                                        <div class="d-flex pa-1 justify-center align-center" style="background: #FFF;">
                                                            <span style="width:60px;"><CmmTimeField v-model="item.lvnRmInfo.lunchAfTm"></CmmTimeField></span>
                                                        </div>
                                                    </div>                                  
                                                </div>   
                                                <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                                    <div>
                                                        <div class="d-flex pa-1 justify-center align-center tableBB"><span class="black--text font-weight-medium">저녁식전</span></div>
                                                        <div class="d-flex pa-1 justify-center align-center" style="background: #FFF;">
                                                            <span style="width:60px;"><CmmTimeField v-model="item.lvnRmInfo.dinerBfTm"></CmmTimeField></span>
                                                        </div>
                                                    </div>                                  
                                                </div>
                                                <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                                    <div>
                                                        <div class="d-flex pa-1 justify-center align-center tableBB"><span class="black--text font-weight-medium">저녁식후</span></div>
                                                        <div class="d-flex pa-1 justify-center align-center" style="background: #FFF;">
                                                            <span style="width:60px;"><CmmTimeField v-model="item.lvnRmInfo.dinerAfTm"></CmmTimeField></span>
                                                        </div>
                                                    </div>                                  
                                                </div>                                             
                                               <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                                    <div>
                                                        <div class="d-flex pa-1 justify-center align-center tableBB"><span class="black--text font-weight-medium">취침전</span></div>
                                                        <div class="d-flex pa-1 justify-center align-center" style="background: #FFF;">
                                                            <span style="width:60px;"><CmmTimeField v-model="item.lvnRmInfo.bedBfTm"></CmmTimeField></span>
                                                        </div>
                                                    </div>                                  
                                                </div>
                                                <div class="pa-0 ma-0 tableBB tableBL" style="width:7%;">  
                                                    <div>
                                                        <div class="d-flex pa-1 justify-center align-center tableBB"><span class="black--text font-weight-medium">필요시</span></div>
                                                        <div class="d-flex pa-1 justify-center align-center" style="background: #FFF;">
                                                            <span style="width:60px;"><CmmTimeField v-model="item.lvnRmInfo.needTm"></CmmTimeField></span>
                                                        </div>
                                                    </div>                                  
                                                </div>                                                  
                                                <div class="d-flex justify-center align-center px-1 py-2 ma-0 tableBB tableBL" style="width:7%;">                                                                                    
                                                    <span class="black--text font-weight-medium">처리</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-center align-center ma-0 tableBB tableBL" style="width:4%;">                                                                                    
                                            <span class="black--text font-weight-medium">전체</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div width="100%" class="d-flex pa-0">  
                                <div class="d-flex justify-center align-center tableBB" style="width:7%;">
                                    {{item.lvnRmNm}}
                                </div>
                                <div style="width:93%;">
                                    <div v-for="(list, j) in item.list" :key="j" :class="['d-flex',item.list.length != j+1?'mdctnmgmt-bnmmbr-bottom':'']">
                                        <div class="d-flex justify-center align-center tableBB tableBL" style="width:5%;">
                                            {{list.name}}
                                        </div>
                                        <div style="width:91%;">
                                            <div v-for="(mdctn, k) in list.subList" :key="k" class="d-flex">
                                                <div class="d-flex justify-center align-center tableBB tableBL" style="width:20%;">
                                                    {{mdctn.drugNm}}
                                                </div>
                                                <div class="d-flex justify-center align-center tableBB tableBL" style="width:10%;">
                                                    {{mdctn.drugAmt}}
                                                </div>
                                                <div class="d-flex justify-center align-center tableBB tableBL" style="width:7%;">
                                                    {{mdctn.drugMthd}}
                                                </div>
                                                <div class="d-flex justify-center align-center px-1 py-2 ma-0 tableBB tableBL" style="width:7%;">  
                                                    <div v-if="mdctn.brkfsBfTm != '' && mdctn.brkfsBfTm != undefined && mdctn.dsBrkfsBf=='1' && mdctn.doseRecPk > 0" style="color:#005593">
                                                        {{mdctn.brkfsBfTm}}
                                                    </div>        
                                                    <div v-else>
                                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="mdctn.dsBrkfsBf" true-value="1" false-value="2" hide-details></v-checkbox>
                                                    </div>                          
                                                </div>
                                                <div class="d-flex justify-center align-center px-1 py-2 ma-0 tableBB tableBL" style="width:7%;">                                    
                                                    <div v-if="mdctn.brkfsAfTm != '' && mdctn.brkfsAfTm != undefined && mdctn.dsBrkfsAf=='1' && mdctn.doseRecPk > 0" style="color:#005593">
                                                        {{mdctn.brkfsAfTm}}
                                                    </div>        
                                                    <div v-else>
                                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="mdctn.dsBrkfsAf" true-value="1" false-value="2" hide-details></v-checkbox>
                                                    </div>                                                      
                                                </div>
                                                <div class="d-flex justify-center align-center px-1 py-2 ma-0 tableBB tableBL" style="width:7%;">                                    
                                                    <div v-if="mdctn.lunchBfTm != '' && mdctn.lunchBfTm != undefined && mdctn.dsLunchBf=='1' && mdctn.doseRecPk > 0" style="color:#005593">
                                                        {{mdctn.lunchBfTm}}
                                                    </div>        
                                                    <div v-else>
                                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="mdctn.dsLunchBf" true-value="1" false-value="2" hide-details></v-checkbox>
                                                    </div>                                                     
                                                </div>
                                                <div class="d-flex justify-center align-center px-1 py-2 ma-0 tableBB tableBL" style="width:7%;">                                    
                                                    <div v-if="mdctn.lunchAfTm != '' && mdctn.lunchAfTm != undefined && mdctn.dsLunchAf=='1' && mdctn.doseRecPk > 0" style="color:#005593">
                                                        {{mdctn.lunchAfTm}}
                                                    </div>        
                                                    <div v-else>                                                        
                                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="mdctn.dsLunchAf" true-value="1" false-value="2" hide-details></v-checkbox>
                                                    </div> 
                                                </div>
                                                <div class="d-flex justify-center align-center px-1 py-2 ma-0 tableBB tableBL" style="width:7%;">                                    
                                                    <div v-if="mdctn.dinerBfTm != '' && mdctn.dinerBfTm != undefined && mdctn.dsDinerBf=='1' && mdctn.doseRecPk > 0" style="color:#005593">
                                                        {{mdctn.dinerBfTm}}
                                                    </div>        
                                                    <div v-else>                                                                                                                
                                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="mdctn.dsDinerBf" true-value="1" false-value="2" hide-details></v-checkbox>
                                                    </div> 
                                                </div>
                                                <div class="d-flex justify-center align-center px-1 py-2 ma-0 tableBB tableBL" style="width:7%;">                                    
                                                    <div v-if="mdctn.dinerAfTm != '' && mdctn.dinerAfTm != undefined && mdctn.dsDinerAf=='1' && mdctn.doseRecPk > 0" style="color:#005593">
                                                        {{mdctn.dinerAfTm}}
                                                    </div>        
                                                    <div v-else>                                                                                                                                                                        
                                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="mdctn.dsDinerAf" true-value="1" false-value="2" hide-details></v-checkbox>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-center align-center px-1 py-2 ma-0 tableBB tableBL" style="width:7%;">      
                                                    <div v-if="mdctn.bedTm != '' && mdctn.bedTm != undefined && mdctn.dsBedBf=='1' && mdctn.doseRecPk > 0" style="color:#005593">
                                                        {{mdctn.bedTm}}
                                                    </div>        
                                                    <div v-else>                                                                                                                                                                                                                                
                                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="mdctn.dsBedBf" true-value="1" false-value="2" hide-details></v-checkbox>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-center align-center px-1 py-2 ma-0 tableBB tableBL" style="width:7%;">                                    
                                                    <div v-if="mdctn.needTm != '' && mdctn.needTm != undefined && mdctn.dsNeed=='1' && mdctn.doseRecPk > 0" style="color:#005593">
                                                        {{mdctn.needTm}}
                                                    </div>        
                                                    <div v-else>
                                                        <v-checkbox class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" v-model="mdctn.dsNeed" true-value="1" false-value="2" hide-details></v-checkbox>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-center align-center px-1 py-2 ma-0 tableBB tableBL" style="width:7%;">                                                                                    
                                                    <v-btn v-if="list.recpk == 0"
                                                        color='grey006'
                                                        @click="delRow(i,j,k)"
                                                        icon>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-eraser</v-icon>
                                                            </template>
                                                            <span>지우기</span>
                                                        </v-tooltip>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-center align-center ma-0 tableBB tableBL" style="width:4%;">                                                                     
                                            <v-btn v-if="list.recpk == 0"
                                                color='grey006'
                                                @click="delRow(i,j)"
                                                icon>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon size="20" v-bind="attrs" v-on="on">mdi-eraser</v-icon>
                                                    </template>
                                                    <span>지우기</span>
                                                </v-tooltip>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="d-flex justify-center align-center ma-0 pa-2 tableBB">                                                                                    
                             <span class="black--text font-weight-medium">생활실을 선택해주세요.</span>       
                        </div>     
                    </div>
                </div>
                </v-form>   
            </v-col>
         </v-row>
         <div class="ma-0 pa-0">
            <v-dialog v-model="menu[0]" content-class="round" persistent max-width="1200">
                <MdctnMgmtLvnRmTimer 
                @isClose="isModal(0)"
                @modalEvent="modalEvent(99)"
                ></MdctnMgmtLvnRmTimer>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="menu[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(1)" @outEmp="getEmpInfo" :prtWrtDt="prtWrtDt"></EmpSelector>
            </v-dialog>  
        </div>
    </v-sheet>
</template>

<script>
import CmmTimeField from '../commons/CmmTimeField.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import MdctnMgmtLvnRmTimer from './MdctnMgmtLvnRmTimer.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import { selLvnRmDoseSmryList, selRmDoseInfList, insMultiDoseRec } from '../../api/bnftrcrd.js';

export default {
    name: 'MdctnMgmtLvnRmMdl',

    props : {
        inqYMD:{type:String, default:''},
        lvnRmInfPk:{type:Number, default:0},
        mdctnLvnRmMdlPk:{type:Number, default:0}
    },
        
    components: {
        CmmTimeField,
        CmmDateComp,
        MdctnMgmtLvnRmTimer,
        EmpSelector,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.ymd = this.inqYMD?.beforeDateFormatHyp()
            this.prtWrtDt = this.inqYMD
            this.lvnRmInfPks.splice(0)
            if(this.lvnRmInfPk > 0) this.lvnRmInfPks.push(this.lvnRmInfPk)
            this.getLvnRmDoseSmryList()
        })
    },

    computed: {

    },

    watch:{
        'mdctnLvnRmMdlPk':function(value){
            if(value > 0) {
                this.ymd = this.inqYMD?.beforeDateFormatHyp()    
                this.prtWrtDt = this.inqYMD
                this.lvnRmInfPks.splice(0)
                if(this.lvnRmInfPk > 0) this.lvnRmInfPks.push(this.lvnRmInfPk)                   
            }
        },
        'ymd':function(value){  
            this.resetValue()
            if(value != '') {
                this.getLvnRmDoseSmryList()               
                this.prtWrtDt = value?.afterDateFormatHyp()
            }
        }
    },
    
    methods: {       
        onClickBtn:function(text){
            if(text == '저장') this.onSave()
        },
        resetValue:function(){
            this.lvnRmDoseSmryList.splice(0)            
            this.wrtrGr = ['','','','','','','','']
            this.wrtrGrMmbrPk = [0,0,0,0,0,0,0,0]            
            this.rmDoseInfList.splice(0)
        },
        getLvnRmDoseSmryList(){  
            selLvnRmDoseSmryList(this.$store.getters.getFcltyNum, this.ymd?.afterDateFormatHyp())
                .then((response) => ( this.getLvnRmDoseSmryListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftcrd/selCopayClmHisList : ' + error))
        },
        getRmDoseInfList(){ 
            this.progress = true
            this.progresslist = true
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                lvnRmInfPks : this.lvnRmInfPks,
                inqYMD      : this.ymd?.afterDateFormatHyp(),
            } 
            selRmDoseInfList(obj)
                .then((response) => ( this.getRmDoseInfListAfter(response.data) ))
                .catch((error) => console.log('connect error /bnftcrd/selRmDoseInfList : ' + error))
        },
        getLvnRmDoseSmryListAfter:function(res){
            this.progress = false
            this.lvnRmDoseSmryList.splice(0)
            res.forEach(item => {
                item.disChekr = false            

                if(this.lvnRmInfPks.indexOf(item.lvnRmInfPk) == '-1') item.checkr = '2'
                else item.checkr = '1'

                this.lvnRmDoseSmryList.push(item)
            });

            this.onRmDoseInfList()
        },
        getRmDoseInfListAfter:function(res){  
            
            this.rmDoseInfList.splice(0) 
            this.wrtrGr = ['','','','','','','','']
            this.wrtrGrMmbrPk = [0,0,0,0,0,0,0,0]

            res.forEach(el => {
                //생활실 설정 시간
                if(el.lvnRmInfo.brkfsBfTmHh?.isEmptyVal() && el.lvnRmInfo.brkfsBfTmMm?.isEmptyVal()) el.lvnRmInfo.brkfsBfTm = el.lvnRmInfo.brkfsBfTmHh + ":" + el.lvnRmInfo.brkfsBfTmMm
                if(el.lvnRmInfo.brkfsAfTmHh?.isEmptyVal() && el.lvnRmInfo.brkfsAfTmMm?.isEmptyVal()) el.lvnRmInfo.brkfsAfTm = el.lvnRmInfo.brkfsAfTmHh + ":" + el.lvnRmInfo.brkfsAfTmMm
                if(el.lvnRmInfo.lunchBfTmHh?.isEmptyVal() && el.lvnRmInfo.lunchBfTmMm?.isEmptyVal()) el.lvnRmInfo.lunchBfTm = el.lvnRmInfo.lunchBfTmHh + ":" + el.lvnRmInfo.lunchBfTmMm
                if(el.lvnRmInfo.lunchAfTmHh?.isEmptyVal() && el.lvnRmInfo.lunchAfTmMm?.isEmptyVal()) el.lvnRmInfo.lunchAfTm = el.lvnRmInfo.lunchAfTmHh + ":" + el.lvnRmInfo.lunchAfTmMm
                if(el.lvnRmInfo.dinerBfTmHh?.isEmptyVal() && el.lvnRmInfo.dinerBfTmMm?.isEmptyVal()) el.lvnRmInfo.dinerBfTm = el.lvnRmInfo.dinerBfTmHh + ":" + el.lvnRmInfo.dinerBfTmMm
                if(el.lvnRmInfo.dinerAfTmHh?.isEmptyVal() && el.lvnRmInfo.dinerAfTmMm?.isEmptyVal()) el.lvnRmInfo.dinerAfTm = el.lvnRmInfo.dinerAfTmHh + ":" + el.lvnRmInfo.dinerAfTmMm
                if(el.lvnRmInfo.bedBfTmHh?.isEmptyVal() && el.lvnRmInfo.bedBfTmMm?.isEmptyVal()) el.lvnRmInfo.bedBfTm = el.lvnRmInfo.bedBfTmHh + ":" + el.lvnRmInfo.bedBfTmMm


                el.list.forEach(items => {
                    
                    if(items.recList.length > 0) { 

                        
                        items.subList.splice(0)
                        items.recList.forEach(itm => {
                            
                            let brkfsBfTm   = ""
                            let brkfsAfTm   = ""
                            let lunchBfTm   = ""
                            let lunchAfTm   = ""
                            let dinerBfTm   = ""
                            let dinerAfTm   = ""
                            let bedTm       = ""
                            let needTm      = ""

                            if(items.hisInfo != null){
                                if(items.hisInfo.wrtrGrMmbrPk.length > 0) {
                                    if(items.hisInfo.wrtrGrMmbrPk[0] > 0 && items.hisInfo.wrtrGrMmbrPk[0] != ''){
                                        this.wrtrGr.splice(0,1,items.hisInfo.wrtrGr[0])
                                        this.wrtrGrMmbrPk.splice(0,1,items.hisInfo.wrtrGrMmbrPk[0])

                                        if(itm.brkfsTmHh != null && itm.brkfsTmMm != '' && itm.brkfsTmMm != null && itm.brkfsTmMm != '')
                                            brkfsBfTm = itm.brkfsTmHh +":"+ itm.brkfsTmMm
                                    } 

                                    if(items.hisInfo.wrtrGrMmbrPk[1] > 0 && items.hisInfo.wrtrGrMmbrPk[1] != ''){                                        
                                        this.wrtrGr.splice(1,1,items.hisInfo.wrtrGr[1])
                                        this.wrtrGrMmbrPk.splice(1,1,items.hisInfo.wrtrGrMmbrPk[1])

                                        if(itm.brkfsTmHh != null && itm.brkfsTmHh != '' && itm.brkfsTmMm != null && itm.brkfsTmMm != '')   
                                            brkfsAfTm = itm.brkfsTmHh +":"+ itm.brkfsTmMm
                                    }

                                    if(items.hisInfo.wrtrGrMmbrPk[2] > 0 && items.hisInfo.wrtrGrMmbrPk[2] != ''){
                                        this.wrtrGr.splice(2,1,items.hisInfo.wrtrGr[2])
                                        this.wrtrGrMmbrPk.splice(2,1,items.hisInfo.wrtrGrMmbrPk[2])    

                                        if(itm.lunchTmHh != null && itm.lunchTmHh != '' && itm.lunchTmMm != null && itm.lunchTmMm != '')
                                            lunchBfTm = itm.lunchTmHh +":"+ itm.lunchTmMm
                                    }

                                    if(items.hisInfo.wrtrGrMmbrPk[3] > 0 && items.hisInfo.wrtrGrMmbrPk[3] != ''){
                                        this.wrtrGr.splice(3,1,items.hisInfo.wrtrGr[3])
                                        this.wrtrGrMmbrPk.splice(3,1,items.hisInfo.wrtrGrMmbrPk[3])    

                                        if(itm.lunchTmHh != null && itm.lunchTmHh != '' && itm.lunchTmMm != null && itm.lunchTmMm != '')
                                            lunchAfTm = itm.lunchTmHh +":"+ itm.lunchTmMm
                                    } 

                                    if(items.hisInfo.wrtrGrMmbrPk[4] > 0 && items.hisInfo.wrtrGrMmbrPk[4] != ''){
                                        this.wrtrGr.splice(4,1,items.hisInfo.wrtrGr[4])
                                        this.wrtrGrMmbrPk.splice(4,1,items.hisInfo.wrtrGrMmbrPk[4])   

                                        if(itm.dinerTmHh != null && itm.dinerTmHh != '' && itm.dinerTmMm != null && itm.dinerTmMm != '') 
                                            dinerBfTm = itm.dinerTmHh +":"+ itm.dinerTmMm
                                    }

                                    if(items.hisInfo.wrtrGrMmbrPk[5] > 0 && items.hisInfo.wrtrGrMmbrPk[5] != ''){
                                        this.wrtrGr.splice(5,1,items.hisInfo.wrtrGr[5])
                                        this.wrtrGrMmbrPk.splice(5,1,items.hisInfo.wrtrGrMmbrPk[5])    

                                        if(itm.dinerTmHh != null && itm.dinerTmHh != '' && itm.dinerTmMm != null && itm.dinerTmMm != '') 
                                            dinerAfTm = itm.dinerTmHh +":"+ itm.dinerTmMm
                                    }
                                    
                                    if(items.hisInfo.wrtrGrMmbrPk[6] > 0 && items.hisInfo.wrtrGrMmbrPk[6] != ''){                                        
                                        this.wrtrGr.splice(6,1,items.hisInfo.wrtrGr[6])
                                        this.wrtrGrMmbrPk.splice(6,1,items.hisInfo.wrtrGrMmbrPk[6])    

                                        if(itm.bedTmHh != null && itm.bedTmHh != '' && itm.bedTmMm != null && itm.bedTmMm != '')
                                            bedTm     = itm.bedTmHh +":"+ itm.bedTmMm

                                    }

                                    if(items.hisInfo.wrtrGrMmbrPk[7] > 0 && items.hisInfo.wrtrGrMmbrPk[7] != ''){
                                        this.wrtrGr.splice(7,1,items.hisInfo.wrtrGr[7])
                                        this.wrtrGrMmbrPk.splice(7,1,items.hisInfo.wrtrGrMmbrPk[7])    

                                        if(itm.needTmHh != null && itm.needTmHh != '' && itm.needTmMm != null && itm.needTmMm != '')
                                            needTm    = itm.needTmHh +":"+ itm.needTmMm    
                                    }
                                }
                            }
                            
                            let obj = {
                                doseRecPk   : itm.doseRecPk,
                                doseInfPk   : itm.doseInfPk,
                                drugAmt     : itm.drugAmt,
                                drugMthd    : itm.drugMthd,    
                                drugNm      : itm.drugNm,
                                dsBedBf     : itm.dsBedBf,
                                dsBrkfsAf   : itm.dsBrkfsAf,
                                dsBrkfsBf   : itm.dsBrkfsBf,
                                dsDinerAf   : itm.dsDinerAf,
                                dsDinerBf   : itm.dsDinerBf,
                                dsLunchAf   : itm.dsLunchAf,
                                dsLunchBf   : itm.dsLunchBf,
                                dsNeed      : itm.dsNeed,
                                brkfsBfTm   : brkfsBfTm,
                                brkfsAfTm   : brkfsAfTm,
                                lunchBfTm   : lunchBfTm,
                                lunchAfTm   : lunchAfTm,
                                dinerBfTm   : dinerBfTm,
                                dinerAfTm   : dinerAfTm,
                                bedTm       : bedTm,
                                needTm      : needTm,
                            }
                            
                            items.subList.push(obj)
                        });
                    } else {
                        items.subList.forEach(itm => {
                            itm.needTm = ''
                            itm.dsNeed = '' 
                        });
                    }
                });
                
                this.rmDoseInfList.push(el) 
            });
            this.progress = false
            this.progresslist = false
        },        
        onSave:function(){            
            let data = {list:[]}

            this.rmDoseInfList.forEach(el => {                

                let brkfsBfTm = ""
                if(el.lvnRmInfo.brkfsBfTm?.isEmptyVal()) brkfsBfTm =  el.lvnRmInfo.brkfsBfTm.split(":")

                let brkfsAfTm = ""
                if(el.lvnRmInfo.brkfsAfTm?.isEmptyVal()) brkfsAfTm =  el.lvnRmInfo.brkfsAfTm.split(":")

                let lunchBfTm = ""
                if(el.lvnRmInfo.lunchBfTm?.isEmptyVal()) lunchBfTm =  el.lvnRmInfo.lunchBfTm.split(":")

                let lunchAfTm = ""
                if(el.lvnRmInfo.lunchAfTm?.isEmptyVal()) lunchAfTm =  el.lvnRmInfo.lunchAfTm.split(":")

                let dinerBfTm = ""
                if(el.lvnRmInfo.dinerBfTm?.isEmptyVal()) dinerBfTm =  el.lvnRmInfo.dinerBfTm.split(":")

                let dinerAfTm = ""
                if(el.lvnRmInfo.dinerAfTm?.isEmptyVal()) dinerAfTm =  el.lvnRmInfo.dinerAfTm.split(":")

                let bedBfTm = ""
                if(el.lvnRmInfo.bedBfTm?.isEmptyVal()) bedBfTm =  el.lvnRmInfo.bedBfTm.split(":")

                let needTm = ""
                if(el.lvnRmInfo.needTm?.isEmptyVal()) needTm =  el.lvnRmInfo.needTm.split(":")
                
                el.list.forEach(item => {
                    let obj = {
                        fcltyNum    : this.$store.getters.getFcltyNum,
                        userNm      : this.$store.getters.getUserNm, 
                        bnMmbrPk    : 0,
                        lvnRmInfPk  : el.lvnRmInfPk,
                        recDt       : this.ymd.afterDateFormatHyp(),
                        wrtrGr      : this.wrtrGr,
                        wrtrGrMmbrPk: this.wrtrGrMmbrPk,
                        wrtrMmbrPk1 : this.wrtrGrMmbrPk[0],
                        wrtrMmbrPk2 : this.wrtrGrMmbrPk[1],
                        wrtrMmbrPk3 : this.wrtrGrMmbrPk[2],
                        wrtrMmbrPk4 : this.wrtrGrMmbrPk[3],
                        wrtrMmbrPk5 : this.wrtrGrMmbrPk[4],
                        wrtrMmbrPk6 : this.wrtrGrMmbrPk[5],
                        wrtrMmbrPk7 : this.wrtrGrMmbrPk[6],
                        wrtrMmbrPk8 : this.wrtrGrMmbrPk[7],
                        brkfsTmHh   : '',
                        brkfsTmMm   : '',
                        lunchTmHh   : '',
                        lunchTmMm   : '',
                        dinerTmHh   : '',
                        dinerTmMm   : '',
                        bedTmHh     : '',
                        bedTmMm     : '',
                        needTmHh    : '',
                        needTmMm    : '',
                        doselist:[],
                    }                        

                    obj.bnMmbrPk = item.bnMmbrPk

                    item.subList.forEach(list => {                            
                        let item = {
                            drugNm      : list.drugNm,
                            drugAmt     : list.drugAmt,
                            doseInfPk   : list.doseInfPk,
                            doseRecPk   : list.doseRecPk,
                            drugMthd    : list.drugMthd,
                            dsBrkfsBf   : list.dsBrkfsBf,
                            dsBrkfsAf   : list.dsBrkfsAf,
                            dsLunchBf   : list.dsLunchBf,
                            dsLunchAf   : list.dsLunchAf,
                            dsDinerBf   : list.dsDinerBf,
                            dsDinerAf   : list.dsDinerAf,
                            dsBedBf     : list.dsBedBf,
                            dsNeed      : list.dsNeed,
                            brkfsTmHh   : '',
                            brkfsTmMm   : '',
                            lunchTmHh   : '',
                            lunchTmMm   : '',
                            dinerTmHh   : '',
                            dinerTmMm   : '',
                            bedTmHh     : '',
                            bedTmMm     : '',
                            needTmHh    : '',
                            needTmMm    : '',
                        }

                        if(list.dsBrkfsBf == "1" && brkfsBfTm != ''){
                            item.brkfsTmHh = brkfsBfTm[0]
                            item.brkfsTmMm = brkfsBfTm[1]
                            obj.brkfsTmHh = brkfsBfTm[0]
                            obj.brkfsTmMm = brkfsBfTm[1]
                        }

                        if(list.dsBrkfsAf == "1" && brkfsAfTm != ''){
                            item.brkfsTmHh = brkfsAfTm[0]
                            item.brkfsTmMm = brkfsAfTm[1]
                            obj.brkfsTmHh = brkfsAfTm[0]
                            obj.brkfsTmMm = brkfsAfTm[1]
                        }

                        if(list.dsLunchBf == "1" && lunchBfTm != ''){
                            item.lunchTmHh = lunchBfTm[0]
                            item.lunchTmMm = lunchBfTm[1]
                            obj.lunchTmHh = lunchBfTm[0]
                            obj.lunchTmMm = lunchBfTm[1]
                        }

                        if(list.dsLunchAf == "1" && lunchAfTm != ''){
                            item.lunchTmHh = lunchAfTm[0]
                            item.lunchTmMm = lunchAfTm[1]
                            obj.lunchTmHh = lunchAfTm[0]
                            obj.lunchTmMm = lunchAfTm[1]
                        }

                        if(list.dsDinerBf == "1" && dinerBfTm != ''){
                            item.dinerTmHh = dinerBfTm[0]
                            item.dinerTmMm = dinerBfTm[1]
                            obj.dinerTmHh = dinerBfTm[0]
                            obj.dinerTmMm = dinerBfTm[1]
                        }

                        if(list.dsDinerAf == "1" && dinerAfTm != ''){
                            item.dinerTmHh = dinerAfTm[0]
                            item.dinerTmMm = dinerAfTm[1]
                            obj.dinerTmHh = dinerAfTm[0]
                            obj.dinerTmMm = dinerAfTm[1]
                        }

                        if(list.dsBedBf == "1" && bedBfTm != ''){
                            item.bedTmHh = bedBfTm[0]
                            item.bedTmMm = bedBfTm[1]
                            obj.bedTmHh = bedBfTm[0]
                            obj.bedTmMm = bedBfTm[1]
                        }

                        if(list.dsNeed == "1" && needTm != ''){
                            item.needTmHh = needTm[0]
                            item.needTmMm = needTm[1]
                            obj.needTmHh = needTm[0]
                            obj.needTmMm = needTm[1]
                        }

                        obj.doselist.push(item)
                        
                    });
                    
                    if(obj.doselist.length > 0) data.list.push(obj)                    
                });

            });
            
            if(data.list.length > 0) {
                this.progress = true
                let bl = true
                if(bl) {
                    insMultiDoseRec(data)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('connect error /bnftcrd/insMultiDoseRec : ' + error))
                }
            }else{
                this.snackControll([true, 2500, '대상건 신규를 찾을 수 없습니다.', 'warning'])
            }
        },
        onSaveAfter:function(res){
           if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getLvnRmDoseSmryList()
                this.$emit("modalEvent",99)
            }else if(res.statusCode == 300){
                this.snackControll([true, 3000, res.message, 'warning'])
                this.getLvnRmDoseSmryList()
                this.$emit("modalEvent",99)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onClose: function () {
            this.$emit('isClose')
            this.ymd=''
            this.lvnRmInfPks.splice(0)
        },
        onMdctnLvnRmSet:function(){
            this.menu.splice(0,1,true)
        },
        isModal:function(key){
            this.menu.splice(key,1,false)
        },
        allChnger: function () {
            this.lvnRmInfPks.splice(0)

            if(this.allChckr == '1') {
                this.lvnRmDoseSmryList.forEach(item => {                    
                    if(item.disChekr == false) item.checkr = '1'
                    this.lvnRmInfPks.push(item.lvnRmInfPk)
                });
            }
            else if(this.allChckr == '2') {
                this.lvnRmDoseSmryList.forEach(item => {
                    if(item.disChekr == false) item.checkr = '2'
                });
            }

            this.onRmDoseInfList()
        },
        allChekrFunc: function () {
            this.lvnRmInfPks.splice(0)

            let tCh = '1'
            this.lvnRmDoseSmryList.forEach(el => {
                if(el.checkr == '2') tCh = '2'

                if(el.checkr == '1') this.lvnRmInfPks.push(el.lvnRmInfPk)
            });

            this.allChckr = tCh

            this.onRmDoseInfList()
        },

        onRmDoseInfList:function(){                                    
            if(this.lvnRmInfPks.length > 0){
                this.filledBtn[0].disabled = false
                this.getRmDoseInfList()
            }else{
                this.filledBtn[0].disabled = true
                this.rmDoseInfList.splice(0)
            }
        },
        onEmpModel:function(value){
            this.empidx = value
            this.menu.splice(1, 1, true)
        },
        getEmpInfo: function (obj) {
            this.wrtrGr[this.empidx] = obj[0].name
            this.wrtrGrMmbrPk[this.empidx] = obj[0].mmbrPk
        },
        modalEvent:function(key){
            if(key == 99){
                this.resetValue()
                this.getLvnRmDoseSmryList()
            }
        },
        delRow:function(rownum,key,idx = null){
            if(idx == null){
                this.rmDoseInfList[rownum].list[key].subList.forEach(el => {
                    el.dsBedBf = '2'
                    el.dsBrkfsAf = '2'
                    el.dsBrkfsBf = '2'
                    el.dsDinerAf = '2'
                    el.dsDinerBf = '2'
                    el.dsLunchAf = '2'
                    el.dsLunchBf = '2'
                    el.dsNeed = '2'
                });
            }else{                
                this.rmDoseInfList[rownum].list[key].subList[idx].dsBedBf = '2'
                this.rmDoseInfList[rownum].list[key].subList[idx].dsBrkfsAf = '2'
                this.rmDoseInfList[rownum].list[key].subList[idx].dsBrkfsBf = '2'
                this.rmDoseInfList[rownum].list[key].subList[idx].dsDinerAf = '2'
                this.rmDoseInfList[rownum].list[key].subList[idx].dsDinerBf = '2'
                this.rmDoseInfList[rownum].list[key].subList[idx].dsLunchAf = '2'
                this.rmDoseInfList[rownum].list[key].subList[idx].dsLunchBf = '2'
                this.rmDoseInfList[rownum].list[key].subList[idx].dsNeed = '2'
            }
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },

    data: () => ({             
        forceRender:0,
        allChckr:false,
        prtWrtDt:'',
        ymd:'',   
        wrtrGr:['','','','','','','',''],
        wrtrGrMmbrPk:[0,0,0,0,0,0,0,0],
        menu: [false,false],   
        lvnRmInfPks:[],        
        headers: [
            { text: '층', value: 'bldFlrNm'},
            { text: '생활실', value: 'lvnRmNm'},
            { text: '투약대상', value: 'doseCnt'},
            { text: '투약현황', value: 'recCnt'},          
        ],     
        lvnRmDoseSmryList:[],
        empidx:0,
        hisInfo:{
            bnftOfrRecHisPk:0,wrtrGr:['','','','','','','',''],wrtrGrMmbrPk:[0,0,0,0,0,0,0,0],cntnt:''
            ,dsBrkfsBfTm:'',dsBrkfsAfTm:'',dsLunchBfTm:'',dsLunchAfTm:'',dsDinerBfTm:'',dsDinerAfTm:'',dsBedBfTm:'',dsNeedTm:'',},
        dummyHisInfo:{
            bnftOfrRecHisPk:0,wrtrGr:['','','','','','','',''],wrtrGrMmbrPk:[0,0,0,0,0,0,0,0],cntnt:''
            ,dsBrkfsBfTm:'',dsBrkfsAfTm:'',dsLunchBfTm:'',dsLunchAfTm:'',dsDinerBfTm:'',dsDinerAfTm:'',dsBedBfTm:'',dsNeedTm:'',},
        rmDoseInfList:[],
        filledBtn: [
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: true },
        ],
        snackOptions: [false, 3000, '', 'info'],
        progress:false,
        progresslist:false,
    }),
};
</script>
<style scoped>
.mdctnmgmt-date .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {background: white !important}
.mdctnmgmt-bnmmbr-bottom {border-bottom: 2px solid red;}
</style>