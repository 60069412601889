<template>
    <v-sheet>
        <div class="d-flex align-center">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">건강검진 정보</span>
            <v-spacer></v-spacer>
            <v-btn v-if="btnVisible"
                class="ml-1" color="#3C9099" style="padding: 13px 12px 12px 10px !important"
                min-width="30" height="25"
                @click="getPreHltChcInf"
                small outlined>
                <v-icon size="14">mdi-text-box-search-outline</v-icon>
                <span class="fontOneRem ml-1" style="padding-bottom: 2px;">전일자료</span>                
            </v-btn>
        </div>
        <v-form ref="form" lazy-validation>
            <v-row class="pa-0 mt-1" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">건강검진일자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                    <span class="white d-inline-block pa-1" style="width: 154px;">
                        <CmmDateComp v-model="items.hltChcDt" :required="true"></CmmDateComp>
                    </span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="4">
                    <span class="d-inline-block" style="width: 100px;">
                            <v-text-field v-model="items.wrtr" color="blue" height="28" ref="info" readonly
                                :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                        </span>
                        <v-btn 
                            class="ml-1 brown lighten-3" 
                            small 
                            min-width="30"
                            height="26"  
                            style="padding:0px 1px 0px 2px !important"
                            @click="onModal(0)"
                            >
                            <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                        </v-btn>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">검진장소</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <v-text-field v-model="items.hltChcPlc" :rules="[rules.required]" height="28" hide-details outlined dense></v-text-field>
                </v-col>                
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="2">
                    <span class="black--text font-weight-medium">검진결과</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="10">
                    <v-textarea v-model="items.hltChcRst" class="rmrksArea" style="font-size:0.9rem !important;"
                        rows="7" outlined dense hide-details no-resize>
                    </v-textarea>
                </v-col>                
            </v-row>
        </v-form>
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">건강검진 내역</span>
        </div>
        <div class="mt-2">
            <v-data-table
                class="dft-hvr-tbl" height="320" locale="ko"
                :headers="headers" :items="hltChcInfList"  
                hide-default-header hide-default-footer dense disable-pagination>

                <template v-slot:header="{ props }" >
                    <thead>
                        <tr> 
                            <th class="black--text clmsFixedHeader tableBR" style="width:110px !important;">{{props.headers[0].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:540px !important;">{{props.headers[1].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:300px !important;">{{props.headers[2].text}}</th>
                            <th class="black--text clmsFixedHeader tableBR" style="width:100px !important;">{{props.headers[3].text}}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:item='{ item }'>
                    <tr :class="[inqYMD == item.hltChcDt ? 'onClr' : '']"
                        @click="tableClickEvent(item.hltChcDt)">
                        <td class="clmsBodyCommon tableBR">{{item.hltChcDt?.beforeDateFormatDot()}}</td> 
                        <td class="clmsBodyCommon tableBR">{{item.hltChcRst}}</td>  
                        <td class="clmsBodyCommon tableBR">{{item.hltChcPlc}}</td>      
                        <td class="clmsBodyCommon">{{item.wrtr}}</td>            
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div class="mt-2">            
            <v-pagination
                v-model="page"
                :length="pageCount"
                @input="getPage"
                total-visible="5">
            </v-pagination>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                    <EmpSelector @isClose="isModal(0)" @outEmp="getEmpInfo" :prtWrtDt="$parent.$parent.$parent.$parent.inqYMD"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="'건강검진 정보'" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import CmmDateComp from '../commons/CmmDateComp.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';

import { selHltChcInfList, selHltChcInf, insHltChcInf, delHltChcInf  } from '../../api/bnftrcrd.js';

export default {
    name: 'DctrCareTab',
    
    components: {  
        CmmDateComp,
        EmpSelector,
        btnModalDelete,
    },

    props : {
        
    },

    computed: {
        getYmd:function(){
            return this.$parent.$parent.$parent.$parent.inqYMD
        }
    },

    created: function(){ 
        
    },

    mounted: function(){
        this.$nextTick(function () {
            this.inqYMD = this.$parent.$parent.$parent.$parent.inqYMD            
        })
    },

    watch: {
        'getYmd':function(value){
            this.inqYMD = value 
            this.items = Object.assign({},this.dummyItems)
            this.items.hltChcDt = this.inqYMD.beforeDateFormatHyp()
            this.btnVisible = true
            this.isPreData = false 
        }
    },

    methods: {   
        onLoad:function(){
            this.btnVisible = true
            this.isPreData = false 
            this.page=1
            this.getHltChcInf()
            this.getHltChcInfList(0,this.itemsRows)
            this.$refs.form.resetValidation()
        },
        onSave:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            let gUserNm = this.$store.getters.getUserNm

            if(gBnMmbrPk > 0){
                if(this.$refs.form.validate()){
                    let obj = {
                        fcltyNum: gFcltyNum,
                        mmbrPk : gBnMmbrPk,
                        userNm : gUserNm,
                        hltChcInfPk: this.items.hltChcInfPk,
                        hltChcDt: this.items.hltChcDt?.afterDateFormatHyp(),
                        hltChcPlc: this.items.hltChcPlc,
                        hltChcRst: this.items.hltChcRst,
                        wrtr: this.items.wrtr,
                    }
                    insHltChcInf(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insHltChcInf : ' + error))
                }
            }else{
                let error = {
                    statusCode:500,
                    message:'수급자를 선택해주세요.'
                }
                this.$emit("hlthBlnftTrigger",error)
            }
        },
        onSaveAfter:function(res){            
            this.$emit("hlthBlnftTrigger",res)
            this.onLoad()
            this.isPreData = false 
        }, 
        delAll:function(){
            if(this.items.hltChcInfPk > 0){
                this.dialogDelete = true
            }else{
                let error = {
                    statusCode:500,
                    message:'대상이 존재 하지 않습니다.'
                }
                this.$emit("hlthBlnftTrigger",error)
            }
        },
        //기록 DB 삭제
        onDelete:function(res){
            if(res == 9990){                
                let gFcltyNum = this.$store.getters.getFcltyNum
                let hltChcInfPk = this.items.hltChcInfPk
                
                if(hltChcInfPk > 0){
                    delHltChcInf(gFcltyNum, hltChcInfPk, this.$store.getters.getUserNm)
                        .then((response) => ( this.onDeleteAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/delHltChcInf : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete = false
            }
        },
        onDeleteAfter:function(res){           
            this.dialogDelete = false
            this.$emit("hlthBlnftTrigger",res)
            this.onLoad()
        },
        onModal: function (value) {
            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },
        getPreHltChcInf:function(){
            this.isPreData = true
            let date = new Date(this.$parent.$parent.$parent.$parent.inqYMD?.beforeDateFormatHyp())
            let dateArr = new Date(date.setDate(date.getDate()-1)).toISOString().split('T',2)
            date = dateArr[0]?.afterDateFormatHyp()    
            this.preymd = date
            this.getHltChcInf()
        },
        getHltChcInf:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk
            let gInqYMD = this.$parent.$parent.$parent.$parent.inqYMD

            if(this.isPreData) gInqYMD = this.preymd

            selHltChcInf(gFcltyNum, null, gInqYMD, gBnMmbrPk)
                .then((response) => ( this.getHltChcInfAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selHltChcInf : ' + error))
        },
        getHltChcInfAfter:function(res){    
            if(res.statusCode == '200'){
                
                if(res.result != undefined){                
                    this.items = res.result
                    
                    if(this.isPreData) {
                        this.items.hltChcDt = this.inqYMD.beforeDateFormatHyp() 
                        this.items.hltChcInfPk = 0
                    } else {
                        this.items.hltChcDt = this.items.hltChcDt.beforeDateFormatHyp()
                        this.btnVisible = false
                    }
    
                }else{
                    this.items = Object.assign({},this.dummyItems)
                    this.items.hltChcDt = this.inqYMD.beforeDateFormatHyp()
                }
    
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }

            if(this.isPreData) this.isPreData = false
        },
        getHltChcInfList : function(strt, lmt){            
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum,
                mmbrPk: this.$parent.$parent.$parent.$parent.bnMmbrPk,
                rnStrt:strt,
                rnLmt:lmt, 
            }            
            selHltChcInfList(obj)
                .then((response) => ( this.getHltChcInfListAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selHltChcInfList : ' + error))              
        },
        getHltChcInfListAfter:function(res){
            this.pageCount = Math.ceil(Number(res.rnTot)/this.itemsRows)
            this.hltChcInfList = res.list
        },
        getPage:function(){
            let strt = ((this.page-1)*this.itemsRows)
            this.getHltChcInfList(strt,this.itemsRows)
        },
        tableClickEvent(value){
            if(value != ''){
                this.$emit("setDate",value)
            }
        },
        getEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            this.items.wrtrMmbrPk = obj[0].mmbrPk
        },
        onforce:function(){
            this.forceRender++
        },
    },
    data: () => ({ 
        pk:null,
        inqYMD:'',
        btnType:'',
        pageCount:0,
        itemsRows: 9,
        page:1,
        forceRender:0, 
        items:{fcltyNum:'',mmbrPk:'',hltChcInfPk:0,hltChcDt:'',hltChcPlc:'',hltChcRst:'',wrtr:'',userNm:''},  
        dummyItems:{fcltyNum:'',mmbrPk:'',hltChcInfPk:0,hltChcDt:'',hltChcPlc:'',hltChcRst:'',wrtr:'',userNm:''},
        hltChcInfList:[],
        headers:[
            { text: '건강검진일', value: '', align: 'center', },
            { text: '검진결과', value: '', align: 'center', },
            { text: '검진장소', value: '', align: 'center', },
            { text: '작성자', value: '', align: 'center', },  
        ],        
        menu:[false,], 
        dialog:[false,false],
        rtimes:false,
        mtimes:false,
        snackOptions: [false, 3000, '', 'info'],  
        dialogDelete:false,
        btnVisible:true,
        preymd : '',
        isPreData:false,
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
    }),
};
</script>