<template>
    <v-sheet class="pb-2">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>   
        <v-form ref="tabform" lazy-validation>
            <div class="pa-0 ma-0">            
                <v-row class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                        <span class="black--text font-weight-medium">직원명</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        {{empItems.name}}
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">연령</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        {{empItems.age}}
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">성별</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="d-inline-block pt-1" style="">
                            <v-img class="ic-sex" v-if="empItems.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                            <v-img class="ic-sex" v-else-if="empItems.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">현 직장경력</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        <v-text-field color="blue" height="28" v-model="items.crntCarer" hide-details outlined dense></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                        <span class="black--text font-weight-medium">담당직종</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        {{empItems.jobCd}}
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">결혼여부</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="d-inline-block" style="width:175px;">
                            <v-radio-group class="radio-dft-Theme mt-0" v-model="items.mrgYn" :rules="[rules.required]" hide-details row :key="forceRender">
                                <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="기혼" value="1"></v-radio>
                                <v-radio class="pa-0 ma-0 mr-2" :ripple="false" label="미혼" value="2"></v-radio>
                            </v-radio-group>
                        </span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">작성일자</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="5" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="white d-inline-block" style="width: 154px;">
                            <CmmDateComp v-model="items.wrtDt" :required="true"></CmmDateComp>                            
                        </span>
                    </v-col>            
                </v-row>
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">
                        <span class="black--text font-weight-medium">현재하고 있는 작업&#40;구체적으로&#41;</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">작업기간</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        <v-text-field color="blue" height="28" v-model="items.wrkPrd" placeholder="예) 2년 1개월" hide-details outlined dense></v-text-field>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">작업내용</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="5" style="border-left: 1px solid #dbdbdb !important;">
                        <v-text-field color="blue" height="28" v-model="items.crntWrkCntnt" hide-details outlined dense></v-text-field>
                    </v-col>            
                </v-row>
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">
                        <span class="black--text font-weight-medium">1일 근무시간</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 pl-2 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">휴식시간</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                            <v-text-field color="blue" height="28" v-model="items.brkHr1d" placeholder="예) 50분씩 2회 휴식" hide-details outlined dense></v-text-field>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 pl-2 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">근무시간</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="5" style="border-left: 1px solid #dbdbdb !important;">
                        <span style="width:180px">
                            <v-text-field color="blue" height="28" v-model="items.wrkHr1d" hide-details outlined dense></v-text-field>
                        </span>
                    </v-col>            
                </v-row>
                <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="3">
                        <span class="black--text font-weight-medium">현작업을 하기 전에 했던 작업</span>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 pl-2 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">작업기간</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="2" style="border-left: 1px solid #dbdbdb !important;">
                        <v-text-field color="blue" height="28" v-model="items.bfWrkPrd" placeholder="예) 1년 7개월" hide-details outlined dense></v-text-field>
                    </v-col>
                    <v-col class="greyE01 d-flex justify-center align-center pa-1 pl-2 ma-0" cols="1" style="border-left: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">작업내용</span>
                    </v-col>
                    <v-col class="d-flex justify-start align-center pa-1 pl-2 ma-0" cols="5" style="border-left: 1px solid #dbdbdb !important;">
                        <v-text-field color="blue" height="28" v-model="items.bfWrkCntnt" hide-details outlined dense></v-text-field>
                    </v-col>            
                </v-row>
            </div>
            <div class="pa-0 ma-0 mt-3" style="height:470px;overflow-y:auto">
                <div style="border-bottom: 1px solid #dbdbdb;">
                    <v-row v-for="(item, i) in msdDtlList" :key="i" class="pa-0 ma-0" style="border-top: 1px solid #dbdbdb;" no-gutters>
                        <v-col class="greyE01 d-flex justify-start align-center py-2 pl-2 ma-0" cols="12">
                            <span v-if="item.clsfc3Cdnm.length > 100" class="black--text" style="font-size:0.9em">
                                {{item.clsfc3Cdnm}}
                            </span>
                            <span v-else class="black--text">{{item.clsfc3Cdnm}}</span>
                        </v-col>                
                        <v-col class="py-5 pl-2 ma-0" cols="12" style="border-top: 1px solid #dbdbdb !important;">                        
                            <div v-if="item.clsfc3Cd == '1'" class="d-flex justify-start align-center">
                                <span v-for="(list, j) in item.list" :key="j" class="d-flex">
                                    <span v-if="list.itmCd == '2'" style="width:220px;">
                                        <v-checkbox v-model="list.itmVal" class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;" hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                    </span>
                                    <span v-else style="width:180px;">
                                        <v-checkbox  v-model="list.itmVal" class="dftChcBox-Theme pt-0" style="margin-top: 3px !important;"  hide-details :ripple="false" :label="list.itmCdnm" value="1"></v-checkbox>
                                    </span>
                                </span>
                            </div>
                            <div v-if="item.clsfc3Cd == '2' || item.clsfc3Cd == '5'" class="d-flex justify-start align-center"> 
                                <span class="d-inline-block" style="width: 1000px;">                           
                                    <v-radio-group
                                        class="radio-dft-Theme mt-0"
                                        v-model="item.itmVal" :rules="[rules.required]" hide-details row>
                                        <v-radio v-for="(list, j) in item.list" :key="j" class="pa-0 ma-0" style="width: 180px;" :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                    </v-radio-group>
                                </span>
                            </div>
                            <div v-if="item.clsfc3Cd == '3'" class="justify-start align-center"> 
                                <div style="width: 1000px;">                           
                                    <v-radio-group
                                        class="radio-dft-Theme mt-0"
                                        v-model="item.itmVal1" :rules="[rules.required]" hide-details row>
                                        <v-radio class="pa-0 ma-0" style="width: 180px;" :ripple="false" label="아니요" value="1"></v-radio>
                                        <v-radio class="pa-0 ma-0" style="width: 180px;" :ripple="false" label="예" value="2"></v-radio>
                                    </v-radio-group>
                                </div>
                                <span class="d-flex d-inline-block mt-3" style="width: 1000px;">                           
                                    <v-checkbox class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;" v-model="item.itmVal3"  hide-details :ripple="false" label="류머티스 관절염" value="1"></v-checkbox>
                                    <v-checkbox class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;" v-model="item.itmVal4"  hide-details :ripple="false" label="당뇨병" value="1"></v-checkbox>
                                    <v-checkbox class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;" v-model="item.itmVal5"  hide-details :ripple="false" label="루프스병" value="1"></v-checkbox>
                                    <v-checkbox class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;" v-model="item.itmVal6"  hide-details :ripple="false" label="통풍" value="1"></v-checkbox>
                                    <v-checkbox class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;" v-model="item.itmVal7"  hide-details :ripple="false" label="알코올중독" value="1"></v-checkbox>
                                </span>
                                <span class="d-inline-block  mt-3" style="width: 1000px;">'예'인 경우 현재 상태는?</span>
                                <span class="d-inline-block  mt-1" style="width: 1000px;">                           
                                    <v-radio-group class="radio-dft-Theme mt-0" v-model="item.itmVal2" hide-details row>
                                        <v-radio class="pa-0 ma-0" style="width: 180px;" :ripple="false" label="완치" value="3"></v-radio>
                                        <v-radio class="pa-0 ma-0" style="width: 180px;" :ripple="false" label="치료나 관찰 중" value="4"></v-radio>
                                    </v-radio-group>
                                </span>
                            </div>
                            <div v-if="item.clsfc3Cd == '4'"> 
                                <div style="width: 1000px;">                           
                                    <v-radio-group class="radio-dft-Theme mt-0" v-model="item.itmVal1" :rules="[rules.required]" hide-details row>
                                        <v-radio class="pa-0 ma-0" style="width: 180px;" :ripple="false" label="아니요" value="1"></v-radio>
                                        <v-radio class="pa-0 ma-0" style="width: 180px;" :ripple="false" label="예" value="2"></v-radio>
                                    </v-radio-group>
                                </div>
                                <span class="d-inline-block mt-2" style="width: 200px;">'예'인 경우 상해 부위는?</span>
                                <div class="d-flex justify-start align-center  mt-2">
                                    <span class="d-flex d-inline-block" style="width: 700px;">                           
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;" v-model="item.itmVal2"  hide-details :ripple="false" label="손/손가락/손목" value="1"></v-checkbox>
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;" v-model="item.itmVal3"  hide-details :ripple="false" label="팔/팔꿈치" value="1"></v-checkbox>
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;" v-model="item.itmVal4"  hide-details :ripple="false" label="어께" value="1"></v-checkbox>
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;" v-model="item.itmVal5"  hide-details :ripple="false" label="목" value="1"></v-checkbox>
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;" v-model="item.itmVal6"  hide-details :ripple="false" label="허리" value="1"></v-checkbox>
                                        <v-checkbox class="dftChcBox-Theme pt-0" style="width: 180px; margin-top: 3px !important;" v-model="item.itmVal7"  hide-details :ripple="false" label="다리/발" value="1"></v-checkbox>                                
                                    </span>
                                </div>
                            </div>
                            <div v-if="item.clsfc3Cd == '6'">
                                <div style="width:1050px">                           
                                    <v-radio-group class="radio-dft-Theme mt-0" v-model="item.itmVal" :rules="[rules.required]" hide-details row>
                                        <v-radio v-for="(list, j) in item.list" :key="j" class="py-1 ma-0" :ripple="false" :label="list.itmCdnm" :value="list.itmCd"></v-radio>
                                    </v-radio-group>
                                </div>                            
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-form>
    </v-sheet>
</template>
<script>
import { groupBy } from '../../api/common.js';
import { insMscldChckpDtl } from '../../api/emp.js';
import CmmDateComp from '../commons/CmmDateComp.vue';
export default {
    name: 'MsclsSystmChkWrkTab1',
    
    components: {
        CmmDateComp
    },

    props : {
        
    },

    created: function(){       
    },

    mounted: function(){
        
    },

    computed: {
       
    },  

    watch: {
        
    },

    methods: {
        
        onSave:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum           
            let gUserNm = this.$store.getters.getUserNm

            if(this.$refs.tabform.validate()){
                let chk1 = true
                let chk3 = true
                let chk32 = true
                let chk4 = true

                this.msdDtlList.forEach(e => {   
                    if(e.clsfc3Cd == "1"){
                        chk1 = this.validt1(e)
                    }else if(e.clsfc3Cd == "2" || e.clsfc3Cd == "5" || e.clsfc3Cd == "6" ){
                        e.list = this.setItmVal(e.itmVal , e.list)
                    }else if(e.clsfc3Cd == "3"){
                        e = this.setItmVal3th(e)
                        chk3 = this.validt3(e)
                        chk32 = this.validt32(e)
                    }else if(e.clsfc3Cd == "4"){
                        e = this.setItmVal4th(e)
                        chk4 = this.validt4(e)
                    }
                });

                if(!chk4){
                    this.snackControll([true, 2500, "상해 부위를 1이상 선택해주세요.", 'warning'])
                }
                
                if(!chk32){
                    this.snackControll([true, 2500, " 현재 상태를 선택해주세요.", 'warning'])
                }

                if(!chk3){
                    this.snackControll([true, 2500, "진단 받은 질병을 1이상 선택해주세요.", 'warning'])
                }

                if(!chk1){
                    this.snackControll([true, 2500, "여가 및 취미활동을 1이상 선택해주세요.", 'warning'])
                }

                if(chk1 && chk3 && chk32 && chk4){
                    let obj = {
                        fcltyNum:gFcltyNum,
                        userNm:gUserNm,
                        mscldChckpPk:this.items.mscldChckpPk,
                        empPk:this.empItems.empPk,
                        mrgYn:this.items.mrgYn,
                        crntCarer:this.items.crntCarer,
                        wrtDt:this.items.wrtDt?.afterDateFormatHyp(),
                        crntWrkCntnt:this.items.crntWrkCntnt,
                        wrkPrd:this.items.wrkPrd,
                        wrkHr1d:this.items.wrkHr1d,
                        brkHr1d:this.items.brkHr1d,
                        bfWrkCntnt:this.items.bfWrkCntnt,
                        bfWrkPrd:this.items.bfWrkPrd,
                        worker:this.items.worker,
                        wrkDt:this.items.wrkDt?.afterDateFormatHyp(),
                        wrkCntnt:this.items.wrkCntnt,
                        wrtr:this.items.wrtr,
                        list : []
                    }
    
                    this.msdDtlList.forEach(e => {
                        e.list.forEach(item => {
                            item.fcltyNum = gFcltyNum
                            item.userNm = gUserNm
                            item.empPk = this.empItems.empPk
                            item.examDt = this.items.wrtDt?.afterDateFormatHyp()
                            obj.list.push(item)
                        });
                    });
    
                    insMscldChckpDtl(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /emp/insMscldChckpDtl : ' + error))
                }

            }
            

        },
        onSaveAfter:function(res){
            this.$emit('empMsysTrigger', res)
        },
        setItmVal:function(value, data){
            data.forEach(item => {
                if(item.itmCd == value){
                    item.itmVal = '1'
                }else{
                    item.itmVal = '2'
                }
            });

            return data
        },
        getItmVal:function(data){
            let value = ''
            data.forEach(item => {
                if(item.itmVal == '1'){
                    value = item.itmCd
                }
            });

            return value
        },
        setItmVal3th:function(data){
            data.list.forEach(item => {
                switch (item.itmCd) {
                    case '1':
                        item.itmVal = (data.itmVal1 === item.itmCd)? '1':'2'    
                        break;
                    case '2':
                        item.itmVal = (data.itmVal1 === item.itmCd)? '1':'2'    
                        break;
                    case '3':
                        item.itmVal = (data.itmVal2 === item.itmCd)? '1':'2'    
                        break;
                    case '4':
                        item.itmVal = (data.itmVal2 === item.itmCd)? '1':'2'    
                        break;
                    case '5':
                        item.itmVal = (data.itmVal3 === '1')? '1':'2'    
                        break;
                    case '6':
                        item.itmVal = (data.itmVal4 === '1')? '1':'2'    
                        break;  
                    case '7':
                        item.itmVal = (data.itmVal5 === '1')? '1':'2'    
                        break;
                    case '8':
                        item.itmVal = (data.itmVal6 === '1')? '1':'2'    
                        break;                  
                    case '9':
                        item.itmVal = (data.itmVal7 === '1')? '1':'2'    
                        break;                  
                    default:
                        break;
                }
            });
            return data
        },
        getItmVal3th:function(data){
            data.list.forEach(item => {
                if(item.itmCd ===  '1' || item.itmCd ===  '2'){
                    if(item.itmVal === '1'){
                        data.itmVal1 = item.itmCd
                    }
                } else if (item.itmCd ===  '3' || item.itmCd ===  '4'){
                    if(item.itmVal === '1'){
                        data.itmVal2 = item.itmCd
                    }
                }else if(item.itmCd ===  '5'){
                    data.itmVal3 = item.itmVal
                }else if(item.itmCd ===  '6'){
                    data.itmVal4 = item.itmVal
                }else if(item.itmCd ===  '7'){
                    data.itmVal5 = item.itmVal
                }else if(item.itmCd ===  '8'){
                    data.itmVal6 = item.itmVal
                }else if(item.itmCd ===  '9'){
                    data.itmVal7 = item.itmVal
                }
            });
            return data
        },
        setItmVal4th:function(data){
            data.list.forEach(item => {
                switch (item.itmCd) {
                    case '1':
                        item.itmVal = (data.itmVal1 === item.itmCd)? '1':'2'    
                        break;
                    case '2':
                        item.itmVal = (data.itmVal1 === item.itmCd)? '1':'2'    
                        break;
                    case '3':
                        item.itmVal = (data.itmVal2 === '1')? '1':'2'       
                        break;
                    case '4':
                        item.itmVal = (data.itmVal3 === '1')? '1':'2'      
                        break;
                    case '5':
                        item.itmVal = (data.itmVal4 === '1')? '1':'2'    
                        break;
                    case '6':
                        item.itmVal = (data.itmVal5 === '1')? '1':'2'    
                        break;  
                    case '7':
                        item.itmVal = (data.itmVal6 === '1')? '1':'2'    
                        break;
                    case '8':
                        item.itmVal = (data.itmVal7 === '1')? '1':'2'    
                        break;  
                    default:
                        break;
                }
            });
            return data
        },
        getItmVal4th:function(data){
            data.list.forEach(item => {
                if(item.itmCd ===  '1' || item.itmCd ===  '2'){
                    if(item.itmVal === '1'){
                        data.itmVal1 = item.itmCd
                    }
                } else if(item.itmCd ===  '3'){
                    data.itmVal2 = item.itmVal
                }else if(item.itmCd ===  '4'){
                    data.itmVal3 = item.itmVal
                }else if(item.itmCd ===  '5'){
                    data.itmVal4 = item.itmVal
                }else if(item.itmCd ===  '6'){
                    data.itmVal5 = item.itmVal
                }else if(item.itmCd ===  '7'){
                    data.itmVal6 = item.itmVal
                }else if(item.itmCd ===  '8'){
                    data.itmVal7 = item.itmVal
                }
            });
            return data
        },
        setEmpInfo:function(item){
            this.empItems  = Object.assign({})
            this.empItems = item
        },
        setItems:function(item){
            this.items  = Object.assign({})
            if(item != null && item != ''){ 
                this.items = item
                this.items.wrtDt = item.wrtDt?.beforeDateFormatHyp()
                this.items.wrkDt = item.wrkDt?.beforeDateFormatHyp()
            }

            this.$refs.tabform.resetValidation()
        },
        
        setMsdDtlList:function(list){
            this.msdDtlList.splice(0)

            let data = []

            list.forEach(item => {
                if(item.clsfc2Cd == '1'){
                    data.push(item)
                }
            });
            
            let groups = groupBy(data, 'clsfc3Cd')  

            Object.keys(groups).forEach((key)  => {
                let obj = {
                    clsfc3Cd : key,
                    clsfc3Cdnm : groups[key][0].clsfc3Cdnm,
                    list : groups[key]
                }
                this.msdDtlList.push(obj)
            });            

            this.msdDtlList.forEach(e => {                
                if(e.clsfc3Cd == "2" || e.clsfc3Cd == "5" || e.clsfc3Cd == "6" ){
                    e.itmVal = this.getItmVal(e.list)
                }else if(e.clsfc3Cd == "3"){
                    e = this.getItmVal3th(e)
                }else if(e.clsfc3Cd == "4"){
                    e = this.getItmVal4th(e)
                }
            });

             this.$refs.tabform.resetValidation()
        },
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        validt1:function(data){
            let res = false

            data.list.forEach(item => {
                if(item.itmVal == '1') res = true
            });

            return res
        },
        validt3:function(data){
            let res = false
            
            if(data.itmVal1 == '2') {
                if(data.itmVal3 == '1') res = true
                if(data.itmVal4 == '1') res = true
                if(data.itmVal5 == '1') res = true
                if(data.itmVal6 == '1') res = true
                if(data.itmVal7 == '1') res = true
            }else{
                res = true
            }

            return res
        },
        validt32:function(data){
            let res = false
            
            if(data.itmVal1 == '2') {
                if(data.itmVal2 != '' && data.itmVal2 != undefined) res = true
            }else{
                res = true
            }

            return res
        },
        validt4:function(data){
            let res = false
            
            if(data.itmVal1 == '2') {
                if(data.itmVal2 == '1') res = true
                if(data.itmVal3 == '1') res = true
                if(data.itmVal4 == '1') res = true
                if(data.itmVal5 == '1') res = true
                if(data.itmVal6 == '1') res = true
                if(data.itmVal7 == '1') res = true
            }else{
                res = true
            }

            return res
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    
    data: () => ({   
        empItems:{},
        items:{},
        msdDtlList:[
            {clsfc3Cd:'',clsfc3Cdnm:'',list:[]}
        ],
        forceRender:0, 
        menu:[false,], 
        rtimes:false,
        mtimes:false,
        snackOptions: [false, 3000, '', 'info'],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
    }),
};
</script>