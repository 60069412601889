<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <v-col cols="5">
                <span class="text-h4 font-weight-bold">{{ mdlTtl }}</span>                
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-right" cols="4">
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </v-col>
        </v-row>
        <v-row class="mt-5 pa-0" no-gutters>
            <v-col cols="12">
                <v-tabs
                    v-model="tab" class="tab-dft-Theme" active-class="black"
                    hide-slider center-active>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">
                        {{ item.tab }}
                    </v-tab>
                </v-tabs>

                <v-divider class="bodyColor mb-2 mr-2__5" style="padding:1px 0px;"></v-divider>

                <v-tabs-items v-model="tab" class="mr-2__5">
                    <v-tab-item :transition="false" eager>
                        <BnfcrMltpRR ref="bnfcrMltpRR" :exChekrList="exclsBnfcrList" :rtrYN="rtrInc1" :chkTyp="mode"></BnfcrMltpRR>
                    </v-tab-item>
                    <v-tab-item :transition="false" eager>
                        <EmpMltpWh
                            ref="empMltpWh"
                            :exChekrList="exclsEmpList"
                            :wrkStcd="rtrTyp" :rtrYN="rtrInc2" :chkTyp="mode">
                        </EmpMltpWh>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-row class="mt-1 pa-0" no-gutters>
            <v-spacer></v-spacer>
            <v-col class="mr-2__5" cols="2">                
                <span class="fontEighthRem">
                    기준일&#58;&nbsp;{{ $moment(wrtDt,'YYYYMMDD').format('YYYY.MM.DD') }}&nbsp;
                </span>
            </v-col>
        </v-row>
        <v-row class="mt-4 pa-0" no-gutters>
            <v-col class="d-flex justify-center align-center" cols="12">
                <v-btn
                    class="white--text" color="blueBtnColor" @click="intgrThrw" height="30" samll rounded>
                    <v-icon v-if="mode == '1'">mdi-account-plus</v-icon>
                    <v-icon v-else-if="mode == '2'">mdi-account-multiple-plus</v-icon>
                    <span class="fontOneDotOneRem ml-2" style="padding-bottom: 2px;">선택</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import EmpMltpWh from '../commons/EmpOhrMltpWh.vue';
import BnfcrMltpRR from '../commons/BnfcrMltpRR.vue';

export default {
    name: 'IntgOhrVer',

    props : {
        mdlTtl: { type: String, default: '참석자 선택' },
        cd41: { type: Array, default: () => { return [] } },
        cd42: { type: Array, default: () => { return [] } },
        cd65: { type: Array, default: () => { return [] } },
        fixList: { type: Array, default: () => { return [] } },
        tabInfList: { type: Array, default: () => { return [] } },
        rtrInc1: { type: Boolean, default: false },
        rtrInc2: { type: Boolean, default: false },
        rtrTyp: { type: String, default: '1' },
        mode: { type: String, default: '2' },
        prtWrtDt: { type: String, default: '' },
    },
            
    components: {
        EmpMltpWh,
        BnfcrMltpRR,
    },

    created: function(){
   
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.wrtDt = this.$moment().format('YYYYMMDD')
        })
    },

    computed: {

    },

    watch: {
        'fixList': function () {
            if(this.fixList.length > 0)
                this.typeClsfc()
            else if(this.fixList.length == 0){
                this.exclsBnfcrList.splice(0)
                this.exclsEmpList.splice(0)
            }
        },
        'tabInfList': function () {
            this.tab = -1
            this.tabItems.forEach(items => {
                items.enter = true
            });
            if(this.tabInfList.length > 0){
                this.tabInfList.forEach(items => {
                    this.tabItems[Number(items)-1].enter = false

                    if(this.tab == -1)
                        this.tab = Number(items)-1
                });
            }
        },
        'prtWrtDt': function () {
            if(this.$moment(this.prtWrtDt, 'YYYY-MM-DD', true).isValid())
                this.wrtDt = this.$moment(this.prtWrtDt, 'YYYY-MM-DD', true).format('YYYYMMDD')
            else if(this.$moment(this.prtWrtDt, 'YYYYMMDD', true).isValid())
                this.wrtDt = this.prtWrtDt
            else
                this.wrtDt = this.$moment().format('YYYYMMDD')
        }
    },
        
    methods: {
        typeClsfc: function (){
            this.exclsBnfcrList = this.fixList.filter(v => v.checkr == '1')
            this.exclsEmpList = this.fixList.filter(v => v.checkr == '2')

        },
        intgrThrw: function () {
            let list = []
            this.$refs.empMltpWh.fltrThrw().forEach(el => {
                list.push(el)
            });
            this.$refs.bnfcrMltpRR.fltrThrw().forEach(el => {
                list.push(el)
            });

            this.$emit('intgrResList', list)
            this.onClose()
        },
        onClose: function () {
            this.$emit('onClose', '0')
        },
    },
    
    data: () => ({
        wrtDt:'',
        exclsBnfcrList: [],     //수급자 선택 제외 리스트
        exclsEmpList: [],       //직원 선택 제외 리스트
        tab: -1,
        tabItems: [
            { index: 0, tab: '수급자', class: 'fontOneDotTwoRem px-15', enter: true },
            { index: 1, tab: '직원', class: 'fontOneDotTwoRem px-15', enter: true },
        ],
    }),
};
</script>