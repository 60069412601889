<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">이용요금 결제 상세</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">결제 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">결제내역</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR px-4" style="padding-top: 3px;" cols="2">
                <span class="fontOneRem">{{ pymInf.cntnt1 }}</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">결제상태</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR px-4" style="padding-top: 3px;" cols="2">
                <span class="fontOneRem">{{ pymInf.pymtSttNm }}</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">결제방법</span>
            </v-col>
            <v-col class="txtAlgnMid px-4" style="padding-top: 3px;" cols="2">
                <span class="fontOneRem">{{ pymInf.pymtMthdNm }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">결제일시</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR px-4" style="padding-top: 3px;" cols="4">
                <span class="fontOneRem">{{ pymInf.pymtDtTm }}</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">결제취소일시</span>
            </v-col>
            <v-col class="txtAlgnMid px-4" style="padding-top: 3px;" cols="4">
                <span class="fontOneRem">{{ pymInf.pymtCnclDtTm }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">결제금액</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR px-4" style="padding-top: 3px;" cols="4">
                <span class="fontOneRem">{{ pymInf.pymtAmt?.toLocaleString('ko-KR') }}원</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                <span class="font-weight-medium">결제취소금액</span>
            </v-col>
            <v-col class="txtAlgnMid px-4" style="padding-top: 3px;" cols="4">
                <span class="fontOneRem">{{ (pymInf.cnclAmt + pymInf.cnclTax)?.toLocaleString('ko-KR') }}원</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">{{ pymInf.pymtMthdNm }} 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <div v-if="pymInf.pymtMthdCd == '1'">
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                    <span class="font-weight-medium">예금주</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR px-2" style="padding-top: 3px;" cols="2">
                    <span class="fontOneRem">주식회사 해리지앤디</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                    <span class="font-weight-medium">입금은행</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR px-2" style="padding-top: 3px;" cols="2">
                    <span class="fontOneRem">수협은행</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                    <span class="font-weight-medium">입금계좌</span>
                </v-col>
                <v-col class="txtAlgnMid px-2" style="padding-top: 3px;" cols="2">
                    <span class="fontOneRem">1010-2135-0407</span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        입금자명
                        <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="tableBR px-2 py-1" cols="2">
                    <v-text-field
                        v-model="bnkTrnsInf.dpstrNm" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                    <span class="font-weight-medium">환불받을은행</span>
                </v-col>
                <v-col class="tableBR px-2 py-1" cols="2">
                    <span class="d-inline-block" style="width: 120px;">
                        <v-select
                            v-model="bnkTrnsInf.dpstrBnkCd"
                            :items="bnkList" :disabled="bnkList.length == 0"
                            item-text="bnkNm" item-value="bnkCd"
                            dense hide-details outlined>
                        </v-select>
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                    <span class="font-weight-medium">환불받을계좌</span>
                </v-col>
                <v-col class="px-2 py-1" cols="2">
                    <v-text-field
                        v-model="bnkTrnsInf.dpstrAcct"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        입금자 연락처
                        <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="tableBR px-2 py-1" cols="4">
                    <span class="d-inline-block" style="width: 50px; height: 100%">
                        <v-text-field
                            v-model="bnkTrnsInf.dpstrTel1" @input="aplctSavBtn"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="mx-1">
                        <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 60px; height: 100%">
                        <v-text-field
                            v-model="bnkTrnsInf.dpstrTel2" @input="aplctSavBtn"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="mx-1">
                        <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 60px; height: 100%">
                        <v-text-field
                            v-model="bnkTrnsInf.dpstrTel3" @input="aplctSavBtn"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="px-4 py-1" cols="6">
                    <v-radio-group
                        v-model="bnkTrnsInf.taxInvcCd" @change="aplctSavBtn"
                        :disabled="pymInf.pymtSttNm != '결제대기'"
                        class="radio-dft-Theme mt-0" ref="info" hide-details row>
                        <v-radio
                            class="pr-2" style="padding-bottom: 2px !important;"
                            :ripple="false" label="세금계산서 발급" value="1">
                        </v-radio>
                        <v-radio
                            class="" style="padding-bottom: 2px !important;"
                            :ripple="false" label="현금영수증 발급" value="2">
                        </v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                    <span class="font-weight-medium">거래일시</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                    <span class="font-weight-medium">카드사</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="2">
                    <span class="font-weight-medium">카드사결과</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                    <span class="font-weight-medium">거래금액</span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-1" cols="2">
                    <span class="font-weight-medium">승인결과</span>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0 mr-1" no-gutters>
                <v-col class="txtAlgnMid tableBR pa-1" cols="3">
                    <span class="fontOneRem">{{ creditInf.trdDt }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pa-1" cols="2">
                    <span class="fontOneRem">{{ creditInf.msg1 }}</span>
                </v-col>
                <v-col class="txtAlgnMid tableBR pa-1" cols="2">
                    <span class="fontOneRem">
                        {{ pymInf.pymtMthdCd == '2' ? creditInf.msg2?.split(':')[0] : creditInf.msg2?.split(':')[1] }}
                    </span>
                </v-col>
                <v-col class="txtAlgnEnd tableBR px-4 py-1" cols="3">
                    <span class="fontOneRem">{{ creditInf.amt?.toLocaleString('ko-KR') }}원</span>
                </v-col>
                <v-col class="txtAlgnMid pa-1" cols="2">
                    <span class="fontOneRem">{{ creditInf.authYn == 'O' ? '정상' : '오류' }}</span>
                </v-col>
            </v-row>
        </div>
        <v-divider class="mr-1"></v-divider>
        <v-row v-if="pymInf.pymtMthdCd != '2'" class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">
                <span v-if="bnkTrnsInf.taxInvcCd == '1'">세금계산서</span>
                <span v-else-if="bnkTrnsInf.taxInvcCd == '2'">현금영수증</span>
                발급 정보
            </span>
        </v-row>
        <v-divider v-if="pymInf.pymtMthdCd != '2'" class="mr-1" color="black"></v-divider>
        <div v-if="bnkTrnsInf.taxInvcCd == '1'">
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        사업자 번호
                        <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="tableBR px-2 py-1" cols="3">
                    <v-text-field
                        v-model="fcltyInf.bsnsRgst" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        대표자명
                        <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="tableBR px-2 py-1" cols="2">
                    <v-text-field
                        v-model="fcltyInf.ceo" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="1">
                    <span class="font-weight-medium">담당자명</span>
                </v-col>
                <v-col class="px-2 py-1" cols="2">
                    <v-text-field
                        v-model="fcltyInf.mngr"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        상호
                        <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="tableBR px-2 py-1" cols="3">
                    <v-text-field
                        v-model="fcltyInf.cmpnNm" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="1">
                    <span class="font-weight-medium">전화번호</span>
                </v-col>
                <v-col class="tableBR px-2 py-1" cols="3">
                    <span class="d-inline-block" style="width: 50px; height: 100%">
                        <v-text-field
                            v-model="fcltyInf.tel1"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="mx-1">
                        <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 60px; height: 100%">
                        <v-text-field
                            v-model="fcltyInf.tel2"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="mx-1">
                        <v-icon style="padding-bottom: 2px;" size="16">mdi-minus</v-icon>
                    </span>
                    <span class="d-inline-block" style="width: 60px; height: 100%">
                        <v-text-field
                            v-model="fcltyInf.tel3"
                            height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="1">
                    <span class="font-weight-medium">업종</span>
                </v-col>
                <v-col class="px-2 py-1" cols="2">
                    <v-text-field
                        v-model="fcltyInf.bsnsItm"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        이메일
                        <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="px-2 py-1" cols="5">
                    <span class="d-inline-block" style="width: 172px; height: 100%;">
                        <v-text-field
                            v-model="fcltyInf.emailId" @input="aplctSavBtn"
                            class="" height="28" color="blue"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-icon class="mx-2" style="padding-top: 1px;" size="20" color="greyE00">mdi-at</v-icon>
                    <span class="d-inline-block" style="width: 168px; height: 100%;">
                        <v-text-field
                            v-model="fcltyInf.emailDmn" @input="aplctSavBtn"
                            class="" height="28" color="blue"
                            :disabled="fcltyInf.emailDmnClcd != '0'"
                            hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
                <v-col class="tableBR pr-1 py-1" cols="2">
                    <v-select
                        v-model="fcltyInf.emailDmnClcd"
                        :items="vCd64"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="1">
                    <span class="font-weight-medium">업태</span>
                </v-col>
                <v-col class="px-2 py-1" cols="2">
                    <v-text-field
                        v-model="fcltyInf.bsnsCndtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                    <span class="font-weight-medium">사업장 주소</span>
                </v-col>
                <v-col class="px-2 py-1" cols="10">
                    <span class="d-inline-block" style="width: 70px; height: 100%">
                        <v-text-field
                            v-model="fcltyInf.zip"
                            height="28" readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <v-btn
                        class="white--text ml-1" color="#3C9099" style="padding: 0px 1px 0px 2px !important"
                        min-width="30" height="26" @click="onLclMdl('01', 600)" small>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon size="20" v-bind="attrs" v-on="on">mdi-map-search-outline</v-icon>
                            </template>
                            <span>주소검색</span>
                        </v-tooltip>
                    </v-btn>
                    <span class="d-inline-block ml-2" style="width: 45%; height: 100%">
                        <v-text-field
                            v-model="fcltyInf.addr"
                            height="28" readonly hide-details outlined dense>
                        </v-text-field>
                    </span>
                    <span class="d-inline-block ml-1" style="width: 35%; height: 100%">
                        <v-text-field
                            v-model="fcltyInf.addrDtl" height="28" hide-details outlined dense>
                        </v-text-field>
                    </span>
                </v-col>
            </v-row>
        </div>
        <div v-if="bnkTrnsInf.taxInvcCd == '2'">
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="3">
                    <span class="font-weight-medium posn-rltv">
                        현금 영수증 사용 용도
                        <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="tableBR pl-2 py-1" cols="5">
                    <v-radio-group
                        v-model="cashRcptInf.cashRcptCd" @change="aplctSavBtn"
                        class="radio-dft-Theme mt-0" ref="info" hide-details row>
                        <v-radio
                            class="pr-2" style="padding-bottom: 2px !important;"
                            :ripple="false" label="개인소득 공제용" value="1">
                        </v-radio>
                        <v-radio
                            class="" style="padding-bottom: 2px !important;"
                            :ripple="false" label="사업자 지출 증빙용" value="2">
                        </v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="2">
                    <span class="font-weight-medium posn-rltv">
                        신청자명
                        <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="px-2 py-1" cols="2">
                    <v-text-field
                        v-model="cashRcptInf.ceo" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined dense>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="3">
                    <span class="font-weight-medium posn-rltv">
                        <span v-if="cashRcptInf.cashRcptCd == '1'">휴대폰 번호</span>
                        <span v-else-if="cashRcptInf.cashRcptCd == '2'">사업자 번호</span>
                        <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="px-2 py-1" cols="5">
                    <v-text-field
                        v-model="cashRcptInf.bsnsRgst" @input="aplctSavBtn"
                        height="28" hide-details outlined dense>
                    </v-text-field>
                </v-col>
                <v-col class="px-2" style="padding-top: 7px;" cols="4">
                    <span class="fontOneRem grey006--text">※ '-' 없이 숫자만 입력해 주세요.</span>
                </v-col>
            </v-row>
        </div>
        <v-divider class="mr-1"></v-divider>
        <v-row class="justify-center pa-0 mt-6 mb-2" no-gutters>
            <v-btn
                v-if="pymInf.pymtMthdCd == '1'" @click="rmBnkTrnsf(pymInf.yysPymHisPk)"
                class="mr-4" color="grey006" min-width="110" height="36"
                :disabled="pymInf.pymtSttNm != '결제대기'">
                <span class="white--text fontOneDotTwoRem">입금취소</span>
            </v-btn>
            <v-btn
                v-if="pymInf.pymtMthdCd == '1' && pymInf.pymtSttNm == '결제대기'"
                @click="preBnkTrnsfUpd()"
                class="mr-4" color="yellow002" min-width="110" height="36"
                :disabled="savBtn">
                <span class="white--text fontOneDotTwoRem">결제정보수정</span>
            </v-btn>
            <v-btn
                v-if="bnkTrnsInf.taxInvcCd == '1' && bnkTrnsInf.prcClcd == '3' && pymInf.pymtSttNm == '결제완료'"
                @click="getCashBillUrl(pymInf.taxInvcPk)"
                class="" color="blueBtnColor" min-width="110" height="36">
                <span class="white--text fontOneDotTwoRem">세금계산서</span>
            </v-btn>
            <v-btn
                v-else-if="bnkTrnsInf.taxInvcCd == '2' && bnkTrnsInf.prcClcd == '3' && pymInf.pymtSttNm == '결제완료'"
                @click="getCashBillUrl(pymInf.taxInvcPk)"
                class="" color="blueBtnColor" min-width="110" height="36">
                <span class="white--text fontOneDotTwoRem">현금영수증</span>
            </v-btn>
            <v-btn
                v-else-if="pymInf.pymtMthdCd == '2'" @click="onCrdtRcptMdl(creditInf.trNo)"
                class="" color="blueBtnColor" min-width="110" height="36">
                <span class="white--text fontOneDotTwoRem">카드매출전표</span>
            </v-btn>
        </v-row>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <AdrsSrch v-if="lclMdlInf.typ == '01'" @onDataThr="rflAddrInf" @isClose="rstLclMdlInf"></AdrsSrch>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import AdrsSrch from '../commons/AdrsSrch.vue';               // 주소 검색

export default {
    name: 'UseSvcPymDtlChngMdl',

    components: {
        AdrsSrch,
    },

    props : {
        prtCd64: { type: Array, default: () => { return [] } },     // 이메일
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.vCd64 = [...this.prtCd64]
            this.vCd64.unshift({ valcd: '0', valcdnm: '직접입력' })

            let obj = this.$store.getters.getRootInObj
            this.aplctInf(obj)
        })
    },

    computed: {
      
    },

    watch: {
        'fcltyInf.emailDmnClcd': function (){
            if(this.fcltyInf.emailDmnClcd == '0')
                this.fcltyInf.emailDmn = ''
            else{
                let emlChc = this.prtCd64.findIndex( v => v.valcd == this.fcltyInf.emailDmnClcd )
                if(emlChc > -1)
                    this.fcltyInf.emailDmn = this.prtCd64[emlChc].valcdnm
                else
                    this.fcltyInf.emailDmn = ''
            }
        },
        'prtCd64': function(v){
            this.vCd64.splice(0)
            this.vCd64 = [...v]
            this.vCd64.unshift({ valcd: '0', valcdnm: '직접입력' })
        },
    },

    methods:{
        // 은행코드 조회
        getBnkList: function (){
            let obj = {}
            obj.clcd  = '1'

            http.post('impExp/selBnkList', obj)
                .then((response) => ( this.bnkRst(response.data) ))
                .catch((error) => console.log('connect error /impExp/selBnkList : ' + error))
        },

        bnkRst: function (res){
            if(Array.isArray(res))
                this.bnkList = res

            if(this.bnkList.length > 0)
                this.bnkList.unshift({ bnkCd: '000', bnkNm: '선택' })
        },

        // 결제상세 정보 조회
        getPymDtlInf: function (obj){
            http.post('yyspym/selYysPymDetail', obj)
                .then((response) => ( this.pymDtlRst(response.data) ))
                .catch((error) => console.log('connect error /yyspym/selYysPymDetail : ' + error))
        },

        pymDtlRst: function (res){
            if(res.statusCode == '200'){
                if(this.pymInf.pymtMthdCd == '1'){      // 결제 종류
                    this.bnkTrnsInf.dpstrNm = res.result.bnkTrnsf.dpstrNm
                    this.bnkTrnsInf.dpstrAcct = res.result.bnkTrnsf.dpstrAcct
                    if(res.result.bnkTrnsf.dpstrBnkCd != null)
                        this.bnkTrnsInf.dpstrBnkCd = res.result.bnkTrnsf.dpstrBnkCd
                    this.bnkTrnsInf.dpstrTel1 = res.result.bnkTrnsf.dpstrTel1
                    this.bnkTrnsInf.dpstrTel2 = res.result.bnkTrnsf.dpstrTel2
                    this.bnkTrnsInf.dpstrTel3 = res.result.bnkTrnsf.dpstrTel3
                    this.bnkTrnsInf.prcClcd = res.result.taxInvc.prcClcd
                }
                else {
                    let tmp = res.result.ksnetPayRcv.trddt + ' ' + res.result.ksnetPayRcv.trdtm
                    this.creditInf.trdDt = this.$moment(tmp, 'YYYYMMDD hhmmss').format('YYYY.MM.DD hh:mm:ss')
                    this.creditInf.trdTm = res.result.ksnetPayRcv.trdtm
                    this.creditInf.trNo = res.result.ksnetPayRcv.trno
                    this.creditInf.amt = res.result.ksnetPayRcv.amt
                    this.creditInf.msg1 = res.result.ksnetPayRcv.msg1
                    this.creditInf.msg2 = res.result.ksnetPayRcv.msg2
                    this.creditInf.authYn = res.result.ksnetPayRcv.authyn
                    this.bnkTrnsInf.prcClcd = res.result.taxInvc.prcClcd
                }

                if(this.bnkTrnsInf.taxInvcCd == '1'){   // 영수증 발급 유형
                    this.fcltyInf.cmpnNm = res.result.taxInvc.cmpnNm
                    this.fcltyInf.bsnsRgst = res.result.taxInvc.bsnsRgst
                    this.fcltyInf.ceo = res.result.taxInvc.ceo
                    this.fcltyInf.mngr = res.result.taxInvc.mngr
                    this.fcltyInf.tel1 = res.result.taxInvc.tel1
                    this.fcltyInf.tel2 = res.result.taxInvc.tel2
                    this.fcltyInf.tel3 = res.result.taxInvc.tel3
                    this.fcltyInf.bsnsCndtn = res.result.taxInvc.bsnsCndtn
                    this.fcltyInf.bsnsItm = res.result.taxInvc.bsnsItm
                    this.fcltyInf.zip = res.result.taxInvc.zip
                    this.fcltyInf.addr = res.result.taxInvc.addr
                    this.fcltyInf.addrDtl = res.result.taxInvc.addrDtl
                    this.fcltyInf.emailId = res.result.taxInvc.emailId

                    let chc = this.vCd64.findIndex ( v => v.valcdnm == res.result.taxInvc.emailDmn )
                    if(chc > -1)
                        this.fcltyInf.emailDmnClcd = this.vCd64[chc].valcd
                    else{
                        this.fcltyInf.emailDmnClcd = '0'
                        this.fcltyInf.emailDmn = res.result.taxInvc.emailDmn
                    }
                }
                else if(this.bnkTrnsInf.taxInvcCd == '2'){
                    this.cashRcptInf.cashRcptCd = res.result.taxInvc.cashRcptCd
                    this.cashRcptInf.bsnsRgst = res.result.taxInvc.bsnsRgst
                    this.cashRcptInf.ceo = res.result.taxInvc.ceo
                }
            }

            this.aplctSavBtn()
        },

        // 무통장 입금 수정
        updBnkTrnsf: function (obj){
            http.post('yyspym/updBnkTrnsf', obj)
                .then((response) => ( this.bnkChngRst(response.data) ))
                .catch((error) => console.log('connect error /yyspym/updBnkTrnsf : ' + error))
        },

        bnkChngRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3000                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode != '200'){
                obj.tmr = 5000
                obj.type = 'warning'
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 무통장 입금 취소
        rmBnkTrnsf: function (pk){
            let obj = {}
            obj.yysPymHisPk = pk

            http.post('yyspym/delBnkTrnsf', obj)
                .then((response) => ( this.bnkTrnsfRst(response.data) ))
                .catch((error) => console.log('connect error /yyspym/delBnkTrnsf : ' + error))
        },

        bnkTrnsfRst: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3000                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200')
                this.$parent.$parent.$parent.$parent.mdlOff()
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 세금계산서, 현금영수증 결과 출력
        getCashBillUrl: function (pk){
            let obj = {}
            obj.taxInvcPk = pk

            http.post('yyspym/selCashBillPopUpUrl', obj)
                .then((response) => ( this.onTaxInvcMdl(response.data) ))
                .catch((error) => console.log('connect error /yyspym/selCashBillPopUpUrl : ' + error))
        },

        // 무통장 입금정보 수정 사전작업
        preBnkTrnsfUpd: function (){
            let obj = {}
            obj.yysPymHisPk = this.pymInf.yysPymHisPk
            obj.taxInvcPk = this.pymInf.taxInvcPk
            obj.taxInvcCd = this.bnkTrnsInf.taxInvcCd
            obj.dpstrNm = this.bnkTrnsInf.dpstrNm
            obj.dpstrAcct = this.bnkTrnsInf.dpstrAcct
            obj.dpstrBnkCd = this.bnkTrnsInf.dpstrBnkCd
            obj.dpstrTel1 = this.bnkTrnsInf.dpstrTel1
            obj.dpstrTel2 = this.bnkTrnsInf.dpstrTel2
            obj.dpstrTel3 = this.bnkTrnsInf.dpstrTel3

            switch (this.bnkTrnsInf.taxInvcCd) {
                case '1':
                    obj.cmpnNm = this.fcltyInf.cmpnNm
                    obj.bsnsRgst = this.fcltyInf.bsnsRgst
                    obj.ceo = this.fcltyInf.ceo
                    obj.mngr = this.fcltyInf.mngr
                    obj.tel1 = this.fcltyInf.tel1
                    obj.tel2 = this.fcltyInf.tel2
                    obj.tel3 = this.fcltyInf.tel3
                    obj.emailId = this.fcltyInf.emailId
                    obj.emailDmn = this.fcltyInf.emailDmn
                    obj.bsnsCndtn = this.fcltyInf.bsnsCndtn
                    obj.bsnsItm = this.fcltyInf.bsnsItm
                    obj.zip = this.fcltyInf.zip
                    obj.addr = this.fcltyInf.addr
                    obj.addrDtl = this.fcltyInf.addrDtl
                    break;
                case '2':
                    obj.cashRcptCd = this.cashRcptInf.cashRcptCd
                    obj.bsnsRgst = this.cashRcptInf.bsnsRgst
                    obj.ceo = this.cashRcptInf.ceo
                    break;
                default:
                    break;
            }

            //console.log(obj)
            this.updBnkTrnsf(obj)
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.getBnkList()

            this.pymInf.yysPymHisPk = obj.yysPymHisPk
            this.pymInf.taxInvcPk = obj.taxInvcPk
            this.bnkTrnsInf.taxInvcCd = obj.taxInvcCd
            this.pymInf.trno = obj.trno
            this.pymInf.cntnt1 = obj.cntnt1
            this.pymInf.pymtTotAmt = obj.pymtAmt
            this.pymInf.cnclAmt = obj.cnclAmt
            this.pymInf.cnclTax = obj.cnclTax
            this.pymInf.pymtSttNm = obj.pymtSttNm
            this.pymInf.pymtMthdCd = obj.pymtMthdCd

            switch (obj.pymtMthdCd) {
                case '1':
                    this.pymInf.pymtMthdNm = '무통장 입금'
                    break;
                case '2':
                    this.pymInf.pymtMthdNm = '신용카드 결제'
                    break;
                case '3':
                    this.pymInf.pymtMthdNm = '계좌이체'
                    break;
                case '4':
                    this.pymInf.pymtMthdNm = '예수금'
                    break;
                default:
                    this.pymInf.pymtMthdNm = ''
                    break;
            }

            this.pymInf.pymtDtTm = this.$moment(obj.pymtDt + ' ' + obj.pymtDttm, 'YYYYMMDD hhmmss').format('YYYY.MM.DD hh:mm:ss')
            if(obj.pymtCnclDt != null && obj.pymtCnclDttm != null)
                this.pymInf.pymtCnclDtTm = this.$moment(obj.pymtCnclDt + ' ' + obj.pymtCnclDttm, 'YYYYMMDD hhmmss').format('YYYY.MM.DD hh:mm:ss')

            this.pymInf.pymtAmt = obj.pymtAmt - obj.cnclAmt - obj.cnclTax
       
            this.getPymDtlInf(obj)
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            let cellphoneRule = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/

            if(this.pymInf.pymtMthdCd == '')
                return 0
            if(this.bnkTrnsInf.taxInvcCd == '')
                return 0

            if(this.pymInf.pymtMthdCd != 2){
                switch (this.pymInf.pymtMthdCd) {
                    case '1':
                        if(this.bnkList.length == 0)
                            return 0
                        if(this.bnkTrnsInf.dpstrNm == '')
                            return 0
                        if(this.bnkTrnsInf.dpstrTel1 == '')
                            return 0
                        if(this.bnkTrnsInf.dpstrTel2 == '')
                            return 0
                        if(this.bnkTrnsInf.dpstrTel3 == '')
                            return 0
                        if(!cellphoneRule.test(this.bnkTrnsInf.dpstrTel1+this.bnkTrnsInf.dpstrTel2+this.bnkTrnsInf.dpstrTel3))
                            return 0
                        break;
                    case '3':
                        break;
                    default:
                        break;
                }
                
                switch (this.bnkTrnsInf.taxInvcCd) {
                    case '1':
                        if(this.fcltyInf.cmpnNm == '')
                            return 0
                        if(this.fcltyInf.bsnsRgst == '')
                            return 0
                        if(this.fcltyInf.ceo == '')
                            return 0
                        if(this.fcltyInf.emailId == '')
                            return 0
                        if(this.fcltyInf.emailDmn == '')
                            return 0
                        break;
                    case '2':
                        if(this.cashRcptInf.cashRcptCd == '')
                            return 0
                        if(this.cashRcptInf.bsnsRgst == '')
                            return 0
                        if(this.cashRcptInf.ceo == '')
                            return 0
                        break;
                    default:
                        break;
                }
            }

            this.savBtn = false
        },

        // 신용카드 매출전표 팝업
        onCrdtRcptMdl: function (trno) {
            let receiptWin = 'http://pgims.ksnet.co.kr/pg_infoc/src/bill/credit_view.jsp?tr_no=' + trno
            window.open(receiptWin , '' , 'scrollbars=no, width=448, height=600')
        },

        // 세금계산서, 현금영수증 팝업
        onTaxInvcMdl: function (res) {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200)
                window.open(res.result.url , '' , 'width=810, height=720, menubar=no, toolbar=no, station=no, resizable=no, scrollbars=no')
            else
                this.$store.commit('setWrnnInf', obj)
        },

        // 주소 검색 내용 적용
        rflAddrInf: function (obj) {
            this.fcltyInf.zip = obj.zonecode
            this.fcltyInf.addr = obj.address

            this.rstLclMdlInf()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        savBtn: true,
        pymInf: {           // 결제 정보
            yysPymHisPk: -1, taxInvcPk: -1, trno: null, pymtMthdCd: '', pymtMthdNm: '', pymtSttNm: '',
            cntnt1: '', pymtDtTm: '', pymtCnclDtTm: '', pymtTotAmt: '', pymtAmt: '', cnclAmt: '', cnclTax: ''
        },
        bnkTrnsInf: {
            taxInvcCd: '', prcClcd: '',
            dpstrNm: '', dpstrAcct: '', dpstrBnkCd : '000', dpstrTel1: '', dpstrTel2: '', dpstrTel3: ''
        },
        fcltyInf: {         // 세금계산서 정보
            cmpnNm: '', bsnsRgst: '', ceo: '', mngr: '', tel1: '', tel2: '', tel3: '',
            emailId: '', emailDmn: '', emailDmnClcd: '0', bsnsCndtn: '', bsnsItm: '', zip: '', addr: '', addrDtl: '',
        },
        cashRcptInf: {      // 현금영수증 정보
            cashRcptCd: '1', bsnsRgst: '', ceo: ''
        },
        creditInf: {        // 신용카드 승인 정보
            trNo: '', trdDt: '', trdTm: '', amt: 0, msg1: '',  msg2: '', authYn: '',
        },
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },     // 통합 팝업 제어 (01: 주소검색)
        clsPrcCd: [         // 처리구분코드
            { valcd: '1', valcdnm: '요청' }, { valcd: '2', valcdnm: '진행중' }, { valcd: '3', valcdnm: '발급정상' },
            { valcd: '4', valcdnm: '발급오류' }, { valcd: '5', valcdnm: '발급취소' }, { valcd: '6', valcdnm: '발급취소(팝빌연동불가)' },
        ],
        vCd64: [],          // 이메일
        bnkList: [],        // 은행종류
    })
}
</script>