<template>
    <v-sheet class="rounded-lg rounded-l-0 overflow-auto pa-2" color="" :min-width="areaWidth+100" :max-width="areaWidth+300">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="d-flex" cols="12">
                <v-spacer></v-spacer>
                <div class="d-flex justify-center pl-1 pr-3">
                    <span v-for="(list, i) in filledBtn" :key="i">
                        <v-btn
                            v-if="list.visible"
                            :class="list.class" :color="list.color" height="30"
                            :disabled="list.disabled" @click="onClickBtn(list.text)" samll rounded>
                            <v-icon small>{{list.icon}}</v-icon>
                            <span class="fontOneRem">{{list.text}}</span>
                        </v-btn>
                    </span>
                    <span v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">
                        <v-btn :class="list.class" :color="list.color" height="30"
                            :disabled="list.disabled" @click="onClickOutBtn(list.text)" samll rounded outlined>
                            <v-icon small>{{list.icon}}</v-icon>
                            <span class="fontOneRem">{{list.text}}</span>
                        </v-btn>
                    </span>
                </div>
            </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col cols="12">
                <EmpDtl ref="empDtl" :prtEmpPk="$parent.$parent.sltEmpPk"></EmpDtl>
            </v-col>
        </v-row>
        <v-row class="pa-0 mt-3" no-gutters>
            <v-col cols="12">
                <v-tabs
                    v-model="tab" class="tab-dft-Theme" active-class="black"
                    center-active hide-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>

                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>

                <v-tabs-items v-model="tab">
                    <v-tab-item eager :transition="false">
                        <v-row class="pa-0 mt-4" no-gutters>
                            <v-col cols="12">
                                <expandMonthPicker @nowYearWithMonth="getPickerDate"></expandMonthPicker>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0" no-gutters>
                            <v-col cols="6">
                                <EmpWorkInfo ref="workInfo" :prtEmpPk="$parent.$parent.sltEmpPk"></EmpWorkInfo>
                            </v-col>
                            <v-col cols="6">
                                <EmpWrkYrs ref="wrkYrs" :prtEmpPk="$parent.$parent.sltEmpPk"></EmpWrkYrs>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 mt-6" no-gutters>
                            <v-col cols="12">
                                <EmpWorkHours ref="wrkHours" :prtEmpPk="$parent.$parent.sltEmpPk"></EmpWorkHours>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item :transition="false">
                        <v-row class="pa-0 mt-4" no-gutters>
                            <v-col cols="4">
                                <EmpMajorInsrn ref="majorInsrn" :prtEmpPk="$parent.$parent.sltEmpPk"></EmpMajorInsrn>
                            </v-col>
                            <v-col cols="8">
                                <EmpCrtfInfo ref="crtfInfo" :prtEmpPk="$parent.$parent.sltEmpPk"></EmpCrtfInfo>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item :transition="false">
                        <v-row class="pa-0 mt-2" no-gutters>
                            <v-col cols="12">
                                <EmpDocMgmt ref="docMgmt" :prtEmpPk="$parent.$parent.sltEmpPk"></EmpDocMgmt>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item :transition="false">
                        <v-row class="pa-0 mt-2" no-gutters>
                            <v-col cols="12">
                                <EmpStateHstr ref="empHstr" :prtMmbrPk="$parent.$parent.sltMmbrPk"></EmpStateHstr>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-dialog v-model="menu[3]" max-width="1000" content-class="round" persistent eager>
            <EmpGrpModal ref="empGrpModal" @modalClose="modalChanger"></EmpGrpModal>
        </v-dialog>
        <v-dialog v-model="menu[0]" max-width="1100" content-class="round" persistent eager>
            <EmpInfoNew @modalClose="modalChanger"></EmpInfoNew>
        </v-dialog>
        <v-dialog v-model="menu[1]" max-width="1200" content-class="round" persistent eager>
            <EmpModifyModal ref="modifyModal" @modalClose="modalChanger"></EmpModifyModal>
        </v-dialog>
        <v-dialog v-model="menu[2]" max-width="900" content-class="round" persistent eager>
            <WrkChgState @modalClose="modalChanger"></WrkChgState>
        </v-dialog>
        <v-dialog v-model="menu[4]" max-width="800" content-class="round" persistent>
            <EmpCrtMdl @modalClose="modalChanger"></EmpCrtMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import EmpDtl from '../../components/emp/EmpDtl.vue';
import EmpModifyModal from '../../components/emp/EmpModifyModal.vue';               // 직원정보 수정
import EmpInfoNew from '../../components/emp/EmpInfoNew.vue';                       // 직원정보 등록
import EmpWorkInfo from '../../components/emp/EmpWorkInfo.vue';                     // 근무정보 - 근무현황
import EmpWrkYrs from '../../components/emp/EmpWrkYrs.vue';                         // 근무정보 - 연차이력
import EmpWorkHours from '../../components/emp/EmpWorkHours.vue';                   // 근무정보 - 근무시간
import WrkChgState from '../../components/emp/WrkChgState.vue';                     // 직원 퇴사/휴직/재입사 처리
import EmpMajorInsrn from '../../components/emp/EmpMajorInsrn.vue';                 // 기타정보 - 4대보험
import EmpCrtfInfo from '../../components/emp/EmpCrtfInfo.vue';                     // 기타정보 - 자격증 정보
import EmpDocMgmt from '../../components/emp/EmpDocMgmt.vue';                       // 서류관리 - 서류목록
import EmpGrpModal from '../../components/emp/EmpGrpModal.vue';                     // 직원 조편성 관리
import EmpCrtMdl from '../../components/emp/EmpCrtMdl.vue';                         // 직원 재직/경력 증명서
import EmpStateHstr from '../../components/emp/EmpStateHstr.vue';                   // 재직현황 이력 - 입퇴사이력
import expandMonthPicker from '../../components/commons/expandMonthPicker.vue';

export default {
    name: 'EmpInfMgmt',
    
    components: {
        EmpDtl,
        EmpModifyModal,
        EmpInfoNew,
        EmpWorkInfo,
        EmpWrkYrs,
        EmpWorkHours,
        WrkChgState,
        EmpMajorInsrn,
        EmpCrtfInfo,
        EmpDocMgmt,
        EmpGrpModal,
        EmpCrtMdl,
        EmpStateHstr,
        expandMonthPicker,
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.areaWidth = 1260 - Number(this.$parent.$parent.midAreaWidth)
        })
    },

    computed: {
        getAreaWidth: function () {
            return this.$parent.$parent.midAreaWidth
        },
        getSltEmpPk: function () {
            return this.$parent.$parent.sltEmpPk
        },
    },  

    watch: {
        getAreaWidth: function (value) {
            this.areaWidth = 1260 - Number(value)
        },
        getSltEmpPk: function (value) {
            if(value == -1){
                this.filledBtn[1].disabled = true
                this.tabItems[1].enter = true
                this.tabItems[2].enter = true
                this.tabItems[3].enter = true
                this.tab = 0

                this.outlinedBtn.forEach(items => {
                    items.disabled = true
                });
            }
            else {
                this.filledBtn[1].disabled = false
                this.tabItems[1].enter = false
                this.tabItems[2].enter = false
                this.tabItems[3].enter = false

                this.outlinedBtn.forEach(items => {
                    items.disabled = false
                });
            }
        },
    },

    methods: {
        onClickBtn: function(text) {
            this.$parent.$parent.sltElmnt = ''
            switch (text) {
                case '신규':
                    this.menu.splice(0, 1, true)
                    break;
                case '수정':
                    this.menu.splice(1, 1, true)
                    break;
                case '퇴사/휴직 처리':
                    this.menu.splice(2, 1, true)
                    break;
                case '재입사 처리':
                    this.menu.splice(2, 1, true)
                    break;
                case '조편성 관리':
                    this.menu.splice(3, 1, true)
                    break;  
                default:
                    break;
            }
        },
        onClickOutBtn: function(text) {
            switch (text) {
                case '재직(경력)증명서':
                    this.menu.splice(4, 1, true)
                    break;
                case '개인정보동의서':
                    this.prvAgrPrt()
                    break;
                case '근로계약서':
                    this.wrknCntrPrt()
                    break;
                default:
                    break;
            }
        },
        getPickerDate: function (date) {
            this.date = date
            this.sltDate = date.substr(0, 4) + '년 ' + date.substr(4, 2) + '월'

            if(this.$parent.$parent.sltEmpPk != -1)
                this.$refs.workInfo.getEmpAtndSumList(this.$parent.$parent.sltEmpPk, date)
        },
        //재직, 경력 증명서 출력
        empCrtPrt: function (txt){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.list[0].bzPk.push(this.$parent.$parent.sltEmpPk)
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.prtCd = '10'
            this.prtThrowObj.inTxt1 = txt

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
        //개인정보 동의서 출력
        prvAgrPrt: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.inTxt1 = ''
            // let chc = this.$parent.$parent.empList.findIndex( v => v.empPk == this.$parent.$parent.sltEmpPk )
            // if(chc != -1)
            //     this.prtThrowObj.bnMmbrPk = this.$parent.$parent.empList[chc].mmbrPk
            // else
            //     this.prtThrowObj.bnMmbrPk = 0
            // this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            // this.prtThrowObj.prtCd = '25'
            // console.log(this.prtThrowObj)
            // this.$refs.reportMgmt.prtDataCall(this.prtThrowObj)
            // this.menu.splice(5, 1, true)
        },
        //근로 계약서 출력
        wrknCntrPrt: function (){
            this.prtThrowObj.list[0].bzPk.splice(0)
            this.prtThrowObj.inTxt1 = ''

            this.prtThrowObj.list[0].bzPk.push(this.$parent.$parent.sltEmpPk)
            this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
            this.prtThrowObj.prtCd = '12'

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
            });
        },
        modalChanger: function(value) {
            this.menu.splice(value, 1, false)
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
    },
    data: () => ({
        tab: 0,
        date: '',
        sltDate: '',
        menu: [ false, false, false, false, false, ],
        areaWidth: 708,
        snackOptions: [false, 3000, '', 'info'],
        tabItems: [
            { index: 0, tab: '근무정보', class: 'fontOneDotTwoRem px-15', enter: false },
            { index: 1, tab: '기타정보', class: 'fontOneDotTwoRem px-15', enter: true },
            { index: 2, tab: '서류관리', class: 'fontOneDotTwoRem px-15', enter: true },
            { index: 3, tab: '재직현황 이력', class: 'fontOneDotTwoRem px-15', enter: true },
        ],
        filledBtn: [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '신규', disabled: false, visible: true },
            { icon: 'mdi-text-box-edit-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '수정', disabled: true, visible: true },
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '퇴사/휴직 처리', disabled: true, visible: true },
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'blue001', text: '재입사 처리', disabled: true, visible: false },
            { icon: 'mdi-account-group', class: 'ml-1 white--text', color:'blue001', text: '조편성 관리', disabled: false, visible: true },
        ],
        outlinedBtn: [
            { icon: 'mdi-clipboard-text-outline', class: 'ml-1 white--text', color:'grey006', text: '재직(경력)증명서', disabled: true, },
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006 ', text: '개인정보동의서', disabled: true, },
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '근로계약서', disabled: true, },
            // { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '직원정보', disabled: true, },
        ],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '18', inTxt1: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>