<template>
    <v-sheet>
        <div style="width:1330px;">
            <v-row no-gutters>
                <v-col cols="5">
                    <div :class="['d-flex greyE01 pa-0', empwrListCnt > 19 ? 'mr-2__5' : '']" no-gutters>
                        <div class="d-flex justify-center align-center pa-1  ma-0 tableBT" style="width:20%;">
                            <span class="black--text font-weight-medium">교육대상자</span>
                        </div>   
                        <div class="d-flex" style="width:80%;">                    
                            <div class=" d-flex justify-center align-center pa-1 ma-0 tableBL tableBT" style="width:50%;">
                                <span class="black--text font-weight-medium">교육명</span>
                            </div>                                        
                            <div class="d-flex justify-center align-center pa-1 ma-0 tableBL tableBT" style="width:30%;">
                                <span class="black--text font-weight-medium">교육일자</span>
                            </div>                                        
                            <div class="d-flex justify-center align-center pa-1 ma-0 tableBL tableBT" style="width:20%;">
                                <span class="black--text font-weight-medium">서명</span>
                            </div>
                        </div>
                    </div>
                    <v-divider class=""></v-divider>
                    <div style="height:635px;overflow-y:auto">
                        <div class="d-flex pa-0 ma-0" no-gutters>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;border-bottom: 1px solid #dbdbdb !important;">
                                <span class="black--text font-weight-medium">시설장</span>
                            </div>
                            <div v-if="empwrList1.length > 0" style="width: 80%;">
                                <v-row
                                    class="pa-0 tableBB"
                                    v-for="(list, j) in empwrList1" :key="j"
                                    @click="tableClickEvent(list.eduRecPk)"
                                    no-gutters>
                                    <span
                                        :class="[
                                            'd-inline-block text-overflow tableBL pa-1',
                                            sltEduRecPk == list.eduRecPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white',
                                            list.eduRecPk == null ? '' : 'crsShp-Pointer'
                                        ]"
                                        style="width: 50%; height: auto;">
                                        <span class="fontOneRem">{{ list.eduNm }}</span>
                                    </span>
                                    <span
                                        :class="[
                                            'd-inline-block txtAlgnMid tableBL pa-1',
                                            sltEduRecPk == list.eduRecPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white',
                                            list.eduRecPk == null ? '' : 'crsShp-Pointer'
                                        ]"
                                        style="width: 30%; height: auto;">
                                        <span class="fontOneRem">{{ list.eduDtStrt?.beforeDateFormatDot() }}</span>
                                    </span>
                                    <span
                                        class="d-inline-block txtAlgnMid tableBL px-1 pb-1"
                                        style="width: 20%; height: auto; padding-top: 3px;">
                                        <v-btn
                                            class="" height="26" style="min-width: 46px; width:46px; border: 1px solid #666;"
                                            @click="onSignMdl(list.eduRecPk)"
                                            depressed>
                                            {{ list.eduSigCnt?.toLocaleString('ko-KR')}}&#47;{{list.eduAtndCnt?.toLocaleString('ko-KR') }}
                                        </v-btn>
                                    </span>
                                </v-row>
                            </div>
                            <div v-else style="width:80%;">
                                <div class="fixedCol d-flex disSltRow" style="width:100%;height:100%;">                                
                                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL tableBB" style="width:50%;">
                                    </div>                                        
                                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL tableBB" style="width:30%;">
                                    </div>                                        
                                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL tableBB" style="width:20%;">
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <div class="d-flex pa-0 ma-0" no-gutters>
                            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:20%;">
                                <span class="black--text font-weight-medium">직원</span>
                            </div>
                            <div v-if="empwrList2.length > 0" style="width: 80%;">
                                <v-row
                                    class="pa-0 tableBB"
                                    v-for="(list, j) in empwrList2" :key="j"
                                    @click="tableClickEvent(list.eduRecPk)"
                                    no-gutters>
                                    <span
                                        :class="[
                                            'd-inline-block text-overflow tableBL pa-1',
                                            sltEduRecPk == list.eduRecPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white',
                                            list.eduRecPk == null ? '' : 'crsShp-Pointer'
                                        ]"
                                        style="width: 50%; height: auto;">
                                        <span class="fontOneRem">{{ list.eduNm }}</span>
                                    </span>
                                    <span
                                        :class="[
                                            'd-inline-block txtAlgnMid tableBL pa-1',
                                            sltEduRecPk == list.eduRecPk ? 'nmlBfClrImp-blue003' : 'nmlBfClrImp-white',
                                            list.eduRecPk == null ? '' : 'crsShp-Pointer'
                                        ]"
                                        style="width: 30%; height: auto;">
                                        <span class="fontOneRem">{{ list.eduDtStrt?.beforeDateFormatDot() }}</span>
                                    </span>
                                    <span
                                        class="d-inline-block txtAlgnMid tableBL px-1 pb-1"
                                        style="width: 20%; height: auto; padding-top: 3px;">
                                        <v-btn
                                            class="" height="26" style="min-width: 46px; width:46px; border: 1px solid #666;"
                                            @click="onSignMdl(list.eduRecPk)"
                                            depressed>
                                            {{ list.eduSigCnt?.toLocaleString('ko-KR')}}&#47;{{list.eduAtndCnt?.toLocaleString('ko-KR') }}
                                        </v-btn>
                                    </span>
                                </v-row>
                            </div>
                            <div v-else style="width:80%;">
                                <div class="fixedCol d-flex disSltRow" style="width:100%;height:100%;">                                
                                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL tableBB" style="width:50%;">
                                    </div>                                        
                                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL tableBB" style="width:30%;">
                                    </div>                                        
                                    <div class="d-flex justify-center align-center pa-1 ma-0 tableBL tableBB" style="width:20%;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col class="pl-2" cols="7">
                    <v-form ref="form" lazy-validation>
                    <v-divider class=""></v-divider>
                    <v-row no-gutters>
                        <v-col class="greyE01 py-1" style="text-align: center;" cols="2">
                            <span class="black--text font-weight-medium">교육명</span>
                        </v-col>
                        <v-col class="d-flex pa-1 ma-0 align-center tableBL" cols="10">
                            <v-text-field v-model="items.eduNm" color="blue" height="28" :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row no-gutters>
                        <v-col class="greyE01 py-1" style="text-align: center;" cols="2">
                            <span class="black--text font-weight-medium">교육강사</span>
                        </v-col>
                        <v-col class="d-flex pa-1 ma-0 align-center tableBL" cols="4">
                            <span class="d-inline-block" style="width: 100px;">
                                <v-text-field v-model="items.instrNm" color="blue" height="28" :rules="[rules.required]" hide-details outlined dense :key="forceRender"></v-text-field>
                            </span>
                        </v-col>
                        <v-col class="greyE01 py-1" style="text-align: center;" cols="2">
                            <span class="black--text font-weight-medium">작성자</span>
                        </v-col>
                        <v-col class="d-flex pa-1 ma-0 align-center tableBL" cols="4">
                            <span class="d-inline-block" style="width: 100px;">
                                <v-text-field
                                    v-model="items.wrtr" color="blue" ref="info" height="28"
                                    :rules="[rules.required, rules.strCheck]" :key="forceRender"
                                    hide-details outlined readonly dense>
                                </v-text-field>
                            </span>
                            <v-btn 
                                class="ml-1 brown lighten-3" 
                                :disabled="items.eduDtStrt == ''"
                                small 
                                min-width="30"
                                height="26"  
                                style="padding:0px 1px 0px 2px !important"
                                @click="onWrtr"
                                >
                                <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row no-gutters>
                        <v-col class="greyE01 py-1" style="text-align: center;" cols="2">
                            <span class="black--text font-weight-medium">교육일시</span>
                        </v-col>
                        <v-col class="d-flex pa-1 ma-0 align-center tableBL" cols="10">
                            <span class="d-inline-block" style="width: 133px;">
                                <CmmDateComp v-model="items.eduDtStrt" :required="true"></CmmDateComp>
                            </span>
                            <span class="white d-inline-block pa-1" style="width: 80px;">
                                <CmmTimeField v-model="items.eduTmStrt" :required="true"></CmmTimeField>                                     
                            </span>
                            <span class="px-2">&#126;</span>
                            <!-- <span class="d-inline-block" style="width: 133px;">
                                <CmmDateComp v-model="items.eduDtEnd" :required="true" :minDt="items.eduDtStrt"></CmmDateComp>
                            </span> -->
                            <span class="white d-inline-block pa-1" style="width: 80px;">
                                <CmmTimeField v-model="items.eduTmEnd" :required="true"></CmmTimeField>                                     
                            </span>
                        </v-col>            
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row no-gutters>
                        <v-col class="greyE01 py-1" style="text-align: center;" cols="2">
                            <span class="black--text font-weight-medium">주관처</span>
                        </v-col>
                        <v-col class="d-flex pa-1 ma-0 align-center tableBL" cols="4">
                            <v-text-field
                                v-model="items.orgnz" color="blue" ref="info" height="28"
                                :rules="[rules.required]" :key="forceRender"
                                hide-details outlined dense>
                            </v-text-field>
                        </v-col>
                        <v-col class="greyE01 py-1" style="text-align: center;" cols="2">
                            <span class="black--text font-weight-medium">교육장소</span>
                        </v-col>
                        <v-col class="d-flex pa-1 ma-0 align-center tableBL" cols="4">
                            <v-text-field
                                v-model="items.eduLoc" color="blue" ref="info" height="28"
                                :rules="[rules.required]" :key="forceRender"
                                hide-details outlined dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" style="text-align: center;" cols="2">
                            <span class="black--text font-weight-medium">참석자</span>
                            <v-btn 
                                :disabled="items.eduDtStrt == ''"
                                class="ml-1 brown lighten-3" 
                                small 
                                min-width="30"
                                height="25"                          
                                style="padding:0px 1px 0px 2px !important"
                                @click="onAtnd"
                                >
                                <v-icon size="20"  class="white--text">mdi-account-multiple</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="d-flex pa-1 ma-0 justify-start align-center tableBL" style="position:relative;" cols="10">
                            <div style="height:60px;overflow-y:auto">
                                <v-chip 
                                    v-for="(list, j) in exclsList" :key="j" 
                                    class="pa-2 mr-1"
                                    close
                                    label                            
                                    color="blue"
                                    outlined
                                    small      
                                    @click:close="delAtndRow(j)"                                
                                    >
                                    {{list.name}}
                                </v-chip>
                            </div>
                            <span style="position:absolute;bottom:9px;right:20px;">
                                <v-btn
                                    class="" color="#3C9099" style="padding:0px 1px 0px 2px !important"
                                    min-width="28" height="28" @click="dialog.splice(4,1,true)"
                                    outlined
                                    dark small>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-arrow-expand</v-icon>
                                        </template>
                                        <span>확대</span>
                                    </v-tooltip>
                                </v-btn>
                            </span> 
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" style="text-align: center;" cols="2">
                            <span class="black--text font-weight-medium">교육내용</span>
                        </v-col>
                        <v-col class="d-flex pa-1 ma-0 align-center tableBL" cols="10" style="position:relative">
                            <v-textarea v-model="items.eduCntnt" class="rmrksArea" style="font-size:0.9rem !important;"
                                    rows="5" outlined dense hide-details no-resize>
                            </v-textarea>  
                            <span style="position:absolute;bottom:9px;right:20px;">
                                <v-btn
                                    class="" color="#3C9099" style="padding:0px 1px 0px 2px !important"
                                    min-width="28" height="28" @click="onCmnts('eduCntnt')"
                                    outlined
                                    dark small>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-arrow-expand</v-icon>
                                        </template>
                                        <span>확대</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" style="text-align: center;" cols="2">
                            <div class="d-flex justify-start align-center" style="width:95px">
                                <span class="black--text font-weight-medium">사진첨부</span>
                                <v-file-input 
                                    class="pl-1"
                                    v-model="images" 
                                    :accept="imgAccept"
                                    dense hide-details truncate-length="5" 
                                    multiple counter
                                    prepend-icon="mdi-camera-outline"
                                    hide-input 
                                    @change="upImgFile()"
                                    ></v-file-input>
                            </div>
                        </v-col>
                        <v-col class="d-flex pa-1 ma-0 align-center tableBL" cols="10">
                            <div class="d-flex justify-start align-center" style="height:100px;overflow-y:auto">
                                <span class="ma-1 d-inline-block" v-for="(item,i) in imageRes" :key="i">
                                    <imageCard 
                                        ref="imageCard" 
                                        :imgSrc="item.src" 
                                        :imgWidth="'100'" 
                                        @isEvent="deleteImg(i)" 
                                        @onDown="fileDownload(i,2)"></imageCard>
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" style="text-align: center;" cols="2">
                            <div class="d-flex align-center" style="width:95px">
                                <span class="black--text font-weight-medium">자료첨부</span>
                                <v-file-input 
                                    v-model="files" 
                                    dense hide-details truncate-length="5" 
                                    multiple counter
                                    hide-input                                         
                                    outlined 
                                    @change="upFile()"
                                    ></v-file-input>
                            </div>
                        </v-col>
                        <v-col class="d-flex pa-1 ma-0 align-center tableBL" cols="10">
                            <div style="width:100%;height:100px;overflow-y:auto">                      
                                <v-chip v-for="(item, j) in fileRes" :key="j"                        
                                    label                        
                                    close
                                    class="ma-1"
                                    outlined
                                    @click="fileDownload(j,1)"
                                    @click:close="delfiles(j)"
                                >
                                    <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                                        mdi-file-pdf-box
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                                        mdi-microsoft-excel
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                                        mdi-file-powerpoint
                                    </v-icon>
                                    <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                                        mdi-file-word
                                    </v-icon>
                                    <v-icon v-else color='cyan' left>
                                        mdi-file-document
                                    </v-icon>
                                    {{ item.orgFileNm }}
                                </v-chip> 
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    <v-row no-gutters>
                        <v-col class="greyE01 d-flex justify-center align-center py-1" style="text-align: center;" cols="2">
                            <span class="black--text font-weight-medium">비고</span>
                        </v-col>
                        <v-col class="d-flex pa-1 ma-0 align-center tableBL" cols="10" style="position:relative">
                            <v-textarea v-model="items.rmrks" class="rmrksArea" style="font-size:0.9rem !important;"
                                    rows="3" outlined dense hide-details no-resize>
                            </v-textarea> 
                            <span style="position:absolute;bottom:9px;right:20px;">
                                <v-btn
                                    class="" color="#3C9099" style="padding:0px 1px 0px 2px !important"
                                    min-width="28" height="28" @click="onCmnts('rmrks')"
                                    outlined
                                    dark small>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-arrow-expand</v-icon>
                                        </template>
                                        <span>확대</span>
                                    </v-tooltip>
                                </v-btn>
                            </span>
                        </v-col>
                    </v-row>
                    <v-divider class=""></v-divider>
                    </v-form>
                </v-col>
            </v-row>
        </div>       
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[2]" max-width="800" eager>    
                <edctnJrnlSignMdl ref="edctnJrnlSignMdl" @modalEvent="modalEvent"></edctnJrnlSignMdl>  
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[3]" eager content-class="round" persistent max-width="800">
                <CmntsMdl v-if="dialog[3]" @isClose="isModal(3)" @outText="setDtldGls" :mdlTtl = "mdlTtl" :mdlCmnts="mdlCmnts" :hSize='30'></CmntsMdl>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[4]" content-class="round" persistent max-width="800">
                <AtndMdl v-if="dialog[4]" @isClose="isModal(4)" @outText="setDtldGls" :mdlTtl = "'참석자 상세보기'" :atndList="exclsList"></AtndMdl>
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogDelete[0]" max-width="500">    
                <btnModalDelete :title="fileTitle" @madalState="delAtchdFile"></btnModalDelete>  
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogDelete[1]" max-width="500">    
                <btnModalDelete :title="atndTitle" @madalState="delAtnd"></btnModalDelete>  
            </v-dialog>
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialogDelete[2]" max-width="500">    
                <btnModalDelete :title="'교육명 : '+items.eduNm" @madalState="delEduRec"></btnModalDelete>  
            </v-dialog>
        </div>  
        <div class="pa-0 ma-0">
            <v-dialog v-model="bizMsg" max-width="1100">    
                <bizMsgMdl 
                    ref="bizMsgMdl"                    
                    :bizMsgData="eSndData"
                    :bizMsgDocClcd="eDocClcd"
                    :bizMsgBzPk="sltEduRecPk"
                    :bizMsgShow="bizMsg"
                    :bizMsgTitle="eCntnt"
                    :bizMsgCntnt="eCntnt"
                    @madalState="onSendClose"
                ></bizMsgMdl>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import { remoteURL } from '@/api/baseAxios';
import { tmpFileUpload, selAtchdFileList, fileDownload, delAtchdFile} from '../../api/index.js';
import { selEduRec704List, selEduRecInfo, selEduAtndList, insEduRec, delEduAtnd, delEduRec } from '../../api/emp.js';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import imageCard from '../commons/imageCard.vue';
import CmntsMdl from '../commons/CmntsMdl.vue';
import btnModalDelete from '../bnfcr/BnfcrDeleteConfirm.vue';
import edctnJrnlSignMdl from './EdctnJrnlSignMdl.vue';
import bizMsgMdl from '../commons/BizMsgMdl.vue';
import AtndMdl from '../commons/AtndMdl.vue';

export default {
    name: 'EmpwrMmt',
    
    props : {
       
    },

    components: {    
        CmmDateComp,
        CmmTimeField,
        imageCard,
        CmntsMdl,
        btnModalDelete,
        edctnJrnlSignMdl,
        bizMsgMdl,
        AtndMdl,
    },

    created: function(){
       
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            // this.yyyy = this.$parent.$parent.$parent.$parent.year
            // this.getEduRec704List()
        })
    },

    computed: {
        getYear:function(){
            return this.$parent.$parent.$parent.$parent.year
        }
    },

    watch:{ 
        'getYear':function(value){
            //초기화
            this.clearFrm()
            this.yyyy = value
            this.getEduRec704List()
            this.sltEduRecPk = -1
        },
        'sltEduRecPk':function(value){
            
            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)

            this.clearEduRec()

            if(value > 0){
                this.getEduRecInfo()
                this.$parent.$parent.$parent.$parent.outlinedBtn[0].disabled = false
            }else{
                this.exclsList.splice(0)
                this.eduAtndList.splice(0)
                this.$parent.$parent.$parent.$parent.outlinedBtn[0].disabled = true
            }
        }
    },
        
    methods: {
        frmReset:function(){
            this.sltEduRecPk = 0
        },
        getEduRec704List:function(){
            let obj = {
                fcltyNum    : this.$store.getters.getFcltyNum,
                inqYYYY     : this.yyyy,
                valClcdS    : ['41','42','65','74',],
            }     
            this.loading = true
            selEduRec704List(obj)
                .then((response) => ( this.getEduRec704ListAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEduRec704List : ' + error))
        },
        getEduRec704ListAfter:function(res){
            if(res.statusCode == 200){                                                
                if(res.result != null){
                    
                    if(res.result.code != null){
                        res.result.code.vCd41.forEach(cd => {
                            this.vCd41.push(cd)
                        });

                        res.result.code.vCd42.forEach(cd => {
                            this.vCd42.push(cd)
                        });

                        res.result.code.vCd65.forEach(cd => {
                            this.vCd65.push(cd)
                        });
                    }

                    if(res.result.list != null){
                        res.result.list.forEach(el => {
                            el.eduDtStrt = this.$moment(el.eduDtStrt,'YYYYMMDD').format('YYYY.MM.DD')

                            if(el.jobClCd=='1') this.empwrList1.push(el)
                            else this.empwrList2.push(el)

                        });
                    }

                    this.empwrListCnt = this.empwrList1.length+this.empwrList2.length
                }
            }
            else{
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
            }

            this.loading = false
        },
        clearFrm:function(){
            this.empwrListCnt = 0
            this.empwrList1.splice(0)
            this.empwrList2.splice(0)
            this.vCd41.splice(0)
            this.vCd42.splice(0)
            this.vCd65.splice(0)
        },
        tableClickEvent:function(pk){            
            this.sltEduRecPk = pk            
        },
        clearEduRec:function(){            
            this.items.eduTypcd		        = ''     //교육종류코드
            this.items.eduClcd			    = ''    //교육구분코드
            this.items.eduSubjCd		    = ''    //교육과목코드
            this.items.eduNm			    = ''    //교육명
            this.items.instrNm			    = ''    //교육강사명
            this.items.eduDtStrt		    = ''    //교육시작일자
            this.items.eduTmStrt            = ''
            this.items.eduTmHhStrt		    = ''    //교육시작시간_시
            this.items.eduTmMmStrt		    = ''    //교육시작시간_분
            this.items.eduDtEnd		        = ''    //교육종료일자
            this.items.eduTmEnd             = ''
            this.items.eduTmHhEnd		    = ''    //교육종료시간_시
            this.items.eduTmMmEnd		    = ''    //교육종료시간_분
            this.items.orgnz			    = ''    //주관처
            this.items.eduLoc			    = ''    //교육장소
            this.items.eduMthdCd		    = ''    //교육방법코드
            this.items.eduCntnt		        = ''        //교육내용
            this.items.wrtr			        = ''        //작성자
            this.items.rmrks			    = ''        //비고                
        },
        getEduRecInfo:function(){  
            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                eduRecPk : this.sltEduRecPk,
            }     
            selEduRecInfo(obj)
                .then((response) => ( this.getEduRecInfoAfter(response.data) ))
                .catch((error) => console.log('connect error /emp/selEduRecInfo : ' + error))
        },
        getEduRecInfoAfter:function(res){    
            if(res != null && res != ''){
                this.items.eduTypcd		        = res.eduTypcd
                this.items.eduClcd			    = res.eduClcd
                this.items.eduSubjCd		    = res.eduSubjCd
                this.items.eduNm			    = res.eduNm
                this.items.instrNm			    = res.instrNm
                this.items.eduDtStrt		    = this.$moment(res.eduDtStrt,'YYYYMMDD').format('YYYY-MM-DD')
                this.items.eduTmHhStrt		    = res.eduTmHhStrt
                this.items.eduTmMmStrt		    = res.eduTmMmStrt
                this.items.eduDtEnd		        = res.eduDtEnd
                this.items.eduTmHhEnd		    = res.eduTmHhEnd
                this.items.eduTmMmEnd		    = res.eduTmMmEnd
                this.items.orgnz			    = res.orgnz
                this.items.eduLoc			    = res.eduLoc
                this.items.eduMthdCd		    = res.eduMthdCd
                this.items.eduCntnt		        = res.eduCntnt
                this.items.wrtr			        = res.wrtr
                this.items.rmrks			    = res.rmrks

                this.items.eduTmStrt            =  this.items.eduTmHhStrt+':'+this.items.eduTmMmStrt
                this.items.eduTmEnd            =  this.items.eduTmHhEnd+':'+this.items.eduTmMmEnd

                this.getEduAtndList()
                this.getAtchdFileList()
            }

            this.$refs.form.resetValidation()
        },  
        getEduAtndList:function(){  
            this.exclsList.splice(0)
            this.eduAtndList.splice(0)

            let obj = {
                fcltyNum : this.$store.getters.getFcltyNum,
                eduRecPk : this.sltEduRecPk,
            }  

            if(this.sltEduRecPk > 0){
                selEduAtndList(obj)
                    .then((response) => ( this.getEduAtndListAfter(response.data) ))
                    .catch((error) => console.log('connect error /emp/selEduAtndList : ' + error))
            }
        },
        getEduAtndListAfter:function(res){
            this.eduAtndList = res
            this.eduAtndList.forEach(e => {
                let obj = {
                    eduAtndPk:e.eduAtndPk,
                    name:e.atndNm,
                    mmbrpk:e.mmbrPk,
                    disChekr:true,
                    checkr:(e.pstnCd=='1')?'1':'2'
                }

                this.exclsList.push(obj)
            });
        },          
        onSave:function(){

            if(this.$refs.form.validate()){
                let obj = {                    
                    fcltyNum        : this.$store.getters.getFcltyNum ,
                    userNm          : this.$store.getters.getUserNm,
                    eduRecPk        : this.sltEduRecPk,
                    eduTypcd		: '704',
                    eduClcd			: this.items.eduClcd,
                    eduSubjCd		: this.items.eduSubjCd,
                    eduNm			: this.items.eduNm,
                    instrNm			: this.items.instrNm,
                    eduDtStrt		: this.$moment(this.items.eduDtStrt,'YYYY-MM-DD').format('YYYYMMDD'),
                    eduTmHhStrt		: '',
                    eduTmMmStrt		: '',
                    eduDtEnd		: '',
                    eduTmHhEnd		: '',
                    eduTmMmEnd		: '',
                    orgnz			: this.items.orgnz,
                    eduLoc			: this.items.eduLoc,
                    eduMthdCd		: this.items.eduMthdCd,
                    eduCntnt		: this.items.eduCntnt,
                    wrtr			: this.items.wrtr,
                    rmrks			: this.items.rmrks,
                    
                    atndList:[],
                    files:[],
                }

                if(this.items.eduTmStrt !='' ) {
                    let eduTmStrt = this.items.eduTmStrt.split(":")
                    obj.eduTmHhStrt = eduTmStrt[0]
                    obj.eduTmMmStrt = eduTmStrt[1]
                }

                if(this.items.eduTmEnd !='' ) {
                    let eduTmEnd = this.items.eduTmEnd.split(":")
                    obj.eduTmHhEnd = eduTmEnd[0]
                    obj.eduTmMmEnd = eduTmEnd[1]
                }

                this.imageRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){                        
                        obj.files.push(x)
                    }
                })

                this.fileRes.forEach(x=>{
                    if(x.atchdFilePk == undefined){
                        x.fileKndCd = '1'
                        obj.files.push(x)
                    }
                })

                this.exclsList.forEach(x => {
                    let atnd = {
                        fcltyNum:this.$store.getters.getFcltyNum  ,
                        userNm:this.$store.getters.getUserNm,
                        atndNm:x.name,
                        mmbrPk:x.mmbrpk,
                        eduAtndPk:x.eduAtndPk,
                        pstnCd:(x.checkr=='1')?x.checkr:'3',
                    }
                    
                    obj.atndList.push(atnd)
                });

                if(this.exclsList.length > 0){
                    insEduRec(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('connect error /emp/insEduRec : ' + error))
                }else{
                    this.$parent.$parent.$parent.$parent.snackControll([true, 5000, '참가자를 선택해주세요.', 'error'])
                }
            }
        }, 
        onSaveAfter:function(res){            
            if(res.statusCode == 200){
                this.clearFrm()
                this.getEduRec704List()
                if(this.sltEduRecPk == 0) this.sltEduRecPk = Number(res.result)
                else{
                    this.clearEduRec()
                    this.getEduRecInfo()
                }
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
            }else{
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, res.message, 'error'])
            }
        },
        delAtndRow:function(idx){
            if(this.exclsList[idx].eduAtndPk > 0){
                if(this.exclsList.length > 1){
                    this.atndPk = this.exclsList[idx].eduAtndPk
                    this.atndTitle = '참석자 : '+this.exclsList[idx].name
                    this.dialogDelete.splice(1,1,true)
                }else{
                    this.$parent.$parent.$parent.$parent.snackControll([true, 5000, '삭제 할 수 없습니다! 최소 1인 이상 존재하여야 합니다.', 'error'])
                }
            }else{
                this.atndPk = -1
                 this.exclsList.splice(idx,1)
            }
        },
        delAtnd:function(res){
            if(res == 9990){                
                if(this.atndPk > 0){ 
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm : this.$store.getters.getUserNm,
                        eduAtndPk: this.atndPk,
                    }

                    delEduAtnd(obj)
                        .then((response) => ( this.delAtndAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete.splice(1,1,false)
            }
        },   
        delAtndAfter:function(res){            
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.getEduAtndList()    
                this.clearFrm()
                this.getEduRec704List()
                this.atndPk = 0
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            
            this.dialogDelete.splice(1,1,false)
        },
        upFile: function () {            
            let formData = new FormData();
            
            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files);
                    tmpFileUpload(formData)
                        .then((response) => ( this.upFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }            
        },
        upFileAfter:function(res){

            res.files.forEach(x=>{
                let fileNm = x.fileNm.split(".")
                x.ext = fileNm[1]
                this.fileRes.push(x)
            })

            this.files = null
        },
        upImgFile: function () {     
            let formData = new FormData();
            
            if(this.images != null){
                this.images.forEach(element => {
                    formData.append("files", element)
                })

                if(this.images.length > 0){
                    tmpFileUpload(formData)
                        .then((response) => ( this.upImgFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
                }
            }

        },
        upImgFileAfter:function(res){
            
            res.files.forEach(x=>{
                x.src = remoteURL + 'opr/imgPrview?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                this.imageRes.push(x)
            })       

            this.images = null
        },
        getAtchdFileList:function(){
            this.files = null
            this.images = null
            this.fileRes.splice(0)
            this.imageRes.splice(0)
            
            let fcltyNum  = this.$store.getters.getFcltyNum            
            let clcd = '10'
            selAtchdFileList(fcltyNum, clcd, this.sltEduRecPk)
                    .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                    .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
        },
        getAtchdFileListAfter:function(res){
            res.forEach(x=>{
                if(x.fileKndCd == "2"){
                    x.src = remoteURL + 'opr/imgView?fileNm='+x.fileNm+'&filePth='+x.filePth+'&fileKndCd='+x.fileKndCd
                    this.imageRes.push(x)
                }else{
                    let fileNm = x.fileNm.split(".")
                    x.ext = fileNm[1]  
                    this.fileRes.push(x)
                }
            })
        },
        fileDownload:function(idx, type){
            if(type == 1){
                if(this.fileRes[idx] != undefined){
                    fileDownload(this.fileRes[idx])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }
            }else if(type==2){
                if(this.imageRes[idx] != undefined){
                    fileDownload(this.imageRes[idx])
                        .then((response) => ( this.fileDownLoaderAfter(response) ))
                        .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
                }
            }
        },
        fileDownLoaderAfter: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])
       
            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
 
            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        delfiles:function(idx){
            if(this.fileRes[idx].atchdFilePk != undefined){
                this.key = 1
                this.idx = idx
                this.fileTitle = '파일 : '+this.fileRes[idx].orgFileNm
                this.dialogDelete.splice(0,1,true)
            }else{
                this.fileRes.splice(idx,1)
            }
        },    
        deleteImg(idx){
            if(this.imageRes[idx].atchdFilePk != undefined){
                this.key = 2
                this.idx = idx
                this.fileTitle = '파일 : '+this.imageRes[idx].orgFileNm
                this.dialogDelete.splice(0,1,true)
            }else{
                this.imageRes.splice(idx,1)
            }
        },
        delAtchdFile:function(res){
            if(res == 9990){
                let pk = 0
                if(this.key == 1){
                    pk = this.fileRes[this.idx].atchdFilePk
                }else{
                    pk = this.imageRes[this.idx].atchdFilePk
                }

                if(pk > 0){
                    let fcltyNum  = this.$store.getters.getFcltyNum  
                    delAtchdFile(fcltyNum, pk)
                        .then((response) => ( this.delAtchdFileAfter(response.data) ))
                        .catch((error) => console.log('upload error /opr/insMtngRcrdFile : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete.splice(0,1,false)
            }
        },       
        delAtchdFileAfter:function(res){
            if(res.statusCode == 200){
                this.$parent.$parent.$parent.$parent.snackControll([true, 2500, res.message, 'info'])
                this.getAtchdFileList()
                this.key = 0
                this.idx = 0
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete.splice(0,1,false)
        },
        onDelete:function(){
            if(this.sltEduRecPk > 0){
                this.dialogDelete.splice(2,1,true)
            }else{
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, '교육을(를) 선택해주세요.', 'warning'])
            }
        },
        delEduRec:function(res){            
            if(res == 9990){
                if(this.sltEduRecPk > 0){
                    let obj = {
                        fcltyNum: this.$store.getters.getFcltyNum,
                        userNm: this.$store.getters.getUserNm,
                        eduRecPk:this.sltEduRecPk,
                    }
                    delEduRec(obj)
                        .then((response) => ( this.delEduRecAfter(response.data) ))
                        .catch((error) => console.log('upload error /emp/delEduRec : ' + error))
                }
            }else if(res == 7){
                this.dialogDelete.splice(2,1,false)
            }
        },
        delEduRecAfter:function(res){            
            if(res.statusCode == 200){
                this.sltEduRecPk = -1
                this.clearFrm()
                this.getEduRec704List()
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete.splice(2,1,false)
        },
         //세부내용 확대
        onCmnts:function(typ){
            this.mdlTyp = typ
            this.mdlTtl     = (typ == 'eduCntnt')?'교육내용':'비고'
            this.mdlCmnts   = (typ == 'eduCntnt')? this.items.eduCntnt:this.items.rmrks

            this.dialog.splice(3, 1, true)
        },
        setDtldGls:function(txt){
            if(this.mdlTyp == 'eduCntnt'){
                this.items.eduCntnt = txt
            }else{
                this.items.rmrks = txt
            }
        },
        onModal: function (value) { 
            if(value == 0 || value == 1) this.prtWrtDt = this.items.eduDtStrt

            this.dialog.splice(value, 1, true)
        },
        isModal: function (value) {
            this.dialog.splice(value, 1, false)
        },        
        onSignMdl:function(pk){            
            this.sltEduRecPk = pk   

            if(this.sltEduRecPk != null){
                this.dialog.splice(2, 1, true)
                this.$refs.edctnJrnlSignMdl.onLoad(pk)
            }
        },
        modalEvent: function (event) { 
            if(event == 99){
                this.clearFrm()
                this.getEduRec704List()
            }else{
                this.dialog.splice(event, 1, false)
            }      
        },
        onSend:function(){            

            if(this.sltEduRecPk > 0){
                this.bizMsg = true
                this.eCntnt = this.items.eduNm
                this.eSndData.splice(0)
                this.exclsList.forEach(item => {
                    this.eSndData.push(item.mmbrpk)
                });
            }else{
                this.$parent.$parent.$parent.$parent.snackControll([true, 5000, '교육을(를) 선택해주세요.', 'warning'])
            }
        },
        onSendClose:function(){
            this.bizMsg = false
        },
        onWrtr:function(){            
            this.$emit('onWrtr', this.items.eduDtStrt, 0)
        },

        onAtnd:function(){
            this.$emit('onAtnd', this.items.eduDtStrt, 1, this.exclsList)
        },
        setEmpInfo: function (obj) {
            this.items.wrtr = obj[0].name
            ++this.forceRender
        },
        setAplyList: function (arr){

            arr.forEach(e => {  
                let mmbrpk = 0
                let cd = ''

                switch (e.dtTyp) {
                    case 'bnfcr':
                        cd = '1'
                        mmbrpk = e.bnMmbrPk
                        break;
                    case 'emp':
                        cd = '2'
                        mmbrpk = e.mmbrPk
                        break;
                    default:
                        break;
                }            

                if(this.exclsList.findIndex( v => v.mmbrpk == mmbrpk) == -1){
                    let obj = {
                        name:e.name,
                        mmbrpk:mmbrpk,
                        disChekr:false,
                        checkr:cd
                    }

                    this.exclsList.push(obj)
                }

            });            
        },
    },
    
    data: () => ({
        yyyy:'',
        vCd41:[],
        vCd42:[],
        vCd65:[],
        loading:false,
        sltEduRecPk:-1,
        empwrHeader: [
            { text: '교육대상자', value: '', sortable: false,},
            { text: '교육명', value: '', sortable: false,},
            { text: '교육일자', value: '', sortable: false,},
            { text: '서명', value: '', sortable: false,},
        ],
        empwrList1 : [],
        empwrList2 : [],
        empwrListCnt : 0,
        forceRender:0,
        prtWrtDt:'',        
        atndPk:-1,
        items:{
            eduTypcd		: '', //교육종류코드
            eduClcd			: '', //교육구분코드
            eduSubjCd		: '', //교육과목코드
            eduNm			: '', //교육명
            instrNm			: '', //교육강사명
            eduDtStrt		: '', //교육시작일자
            eduTmStrt       : '',
            eduTmHhStrt		: '', //교육시작시간_시
            eduTmMmStrt		: '', //교육시작시간_분
            eduDtEnd		: '', //교육종료일자
            eduTmEnd        : '',
            eduTmHhEnd		: '', //교육종료시간_시
            eduTmMmEnd		: '', //교육종료시간_분
            orgnz			: '', //주관처
            eduLoc			: '', //교육장소
            eduMthdCd		: '', //교육방법코드
            eduCntnt		: '', //교육내용
            wrtr			: '', //작성자
            rmrks			: '', //비고
        },
        atndTitle:'',
        exclsList:[],
        eduAtndList:[],
        fileTitle:'',
        files:null,
        fileRes:[],
        images:null, 
        imageRes:[],
        imgAccept:'image/*', 
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        dialog:[false,false,false,false,false,],
        dialogDelete:[false,false,false],
        mdlTyp:'',
        mdlTtl:'',
        mdlCmnts:'',
        bizMsg:false,
        eSndData:[],
        eDocClcd :8,
        eCntnt:'',
    }),
};
</script>