<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">
                <span>직원 {{ bscsInf.mdfClcd == '2' ? '조편성' : '직종' }} 정보 수정</span>
            </span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">직원 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">직원명</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span class="fontOneRem">{{ bscsInf.name }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">입사일</span>
            </v-col>
            <v-col class="px-4" style="padding-top: 3px;" cols="7">
                <span class="fontOneRem">{{ bscsInf.entDt }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">{{ bscsInf.mdfClcd == '2' ? '조편성' : '담당직종' }}</span>
            </v-col>
            <v-col class="px-4" style="padding-top: 3px;" cols="7">
                <span class="fontOneRem">{{ bscsInf.mdfClcd == '2' ? bscsInf.grpNm : bscsInf.jobCdNm }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">
                {{ bscsInf.mdfClcd == '2' ? '조편성' : '담당직종' }} 수정
            </span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="3">
                <span class="font-weight-medium posn-rltv">
                    변경 후 {{ bscsInf.mdfClcd == '2' ? '조편성' : '직종' }}
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col v-if="bscsInf.mdfClcd == '1'" class="px-3 py-1" cols="7">
                <span class="d-inline-block rounded white" style="width: 135px;">
                    <v-select
                        v-model="jobCd"
                        :items="jobList" @change="aplctSavBtn"
                        item-text="valcdnm" item-value="valcd"
                        dense hide-details outlined>
                    </v-select>
                </span>
            </v-col>
            <v-col v-else class="d-flex align-center py-1 px-3" cols="7">
                <span class="d-inline-block" style="width: 135px; padding-top: 1px;">
                    <v-text-field
                        v-model="grpNm" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined readonly dense>
                    </v-text-field>
                </span>
                <v-btn
                    class="ml-1" color="brown lighten-3" style="padding: 0px 1px 0px 2px !important;"
                    min-width="30" height="26"
                    @click="onLclMdl('02', 600)"
                    dark small>
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon size="20" v-bind="attrs" v-on="on">mdi-account-multiple</v-icon>
                        </template>
                        <span>그룹 선택</span>
                    </v-tooltip>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR" style="padding-top: 7px;" cols="3">
                <span class="font-weight-medium posn-rltv">
                    적용시작일
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-3 py-1" cols="9">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="aplStrt" @input="aplctSavBtn" :minDt="bscsInf.aplStrt" :required="true">
                    </CmmDateComp>
                </span>
                <span class="grey006--text fontOneRem ml-5">※ 마지막 적용시작일 이후만 입력 가능</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-6" cols="3">
                <span class="font-weight-medium">비고</span>
            </v-col>
            <v-col class="px-3 py-1" cols="9">
                <v-textarea
                    class="rmrksArea" rows="3"
                    v-model="rmrks"
                    no-resize hide-details outlined dense>
                </v-textarea>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>
        <v-row class="pa-0 mt-6 mb-3 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">
                직원 {{ bscsInf.mdfClcd == '2' ? '조편성' : '직종' }} 정보 변경이력
            </span>
        </v-row>
        <v-divider :class="[mdfList.length > 5 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
        <v-row
            :class="['pa-0 nmlBfClrImp-greyE01', mdfList.length > 5 ? 'mr-2__5' : 'mr-1']"
            no-gutters>
            <v-col class="txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">변경일자</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="4">
                <span class="font-weight-medium">직종</span>
            </v-col>
            <v-col class="txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">적용시작일</span>
            </v-col>
            <v-col class="txtAlgnMid py-1" cols="2">
                <span class="font-weight-medium">처리</span>
            </v-col>
        </v-row>
        <v-divider :class="[mdfList.length > 5 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="mdfList.length > 0" class="overflow-y-auto" style="width: 100%; height: 150px;">
            <v-row
                :class="['tableBB', mdfList.length > 5 ? '' : 'mr-1']"
                v-for="(itm, i) in mdfList" :key="i"
                no-gutters>
                <v-col class="fontOneRem txtAlgnMid tableBR py-1" cols="3">
                    <span>{{ $moment(itm.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col class="fontOneRem txtAlgnMid tableBR pa-1" cols="4">
                    <span>{{ itm.valnm }}</span>
                </v-col>
                <v-col class="fontOneRem txtAlgnMid tableBR pa-1" cols="3">
                    <span>{{ $moment(itm.aplStrt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                </v-col>
                <v-col class="txtAlgnMid px-2" cols="2">
                    <v-btn
                        class="nmlBfClrImp-white ml-1"
                        color="blackBtnColor" min-width="20" height="20"
                        :disabled="mdfList.length == 0"
                        @click="rmMdfHisBtn(i)"
                        small icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" size="18">mdi-trash-can-outline</v-icon>
                            </template>
                            <span>삭제</span>
                        </v-tooltip>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 변경이력이 존재하지 않습니다.</span>
            </v-col>
        </v-row>
        <v-divider class="white my-2"></v-divider>

        <!-- 팝업 영역 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <EmpGrpChcMdl
                v-if="lclMdlInf.typ == '02'"
                :grpList="grpList"
                @modalClose="rstLclMdlInf" @grpObj="aplyGrp">
            </EmpGrpChcMdl>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

import EmpGrpChcMdl from './EmpGrpChcMdl.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';

export default {
    name: 'EmpJobChngMdl',

    components: {
        EmpGrpChcMdl,
        CmmDateComp,
    },

    props : {

    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            let obj = this.$store.getters.getRootInObj
            this.aplctInf(obj)
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '1'){
                let chc = this.mdfList.findIndex( v => v.rmPosn == true )
                if(chc > -1)
                    this.rmEmpMdf(this.mdfList[chc])

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                delete this.mdfList[chc].rmPosn
                this.runAble = '0'
            }
        },
    },

    methods:{
        // 직원 직종정보/조편성 변경이력
        getEmpMdfList: function (pk, clcd){
            this.mdfList.splice(0)

            let obj = {}
            obj.empPk = pk
            obj.mdfClcd = clcd       // 1: 직종, 2: 조편성

            http.post('emp/selEmpMdfHisList', obj)
                .then((response) => ( this.empMdfRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpMdfHisList : ' + error))
        },

        empMdfRst: function (res){
            if(Array.isArray(res.result.list))
                this.mdfList = res.result.list

            if(res.result.mdfHisInf != null){
                //this.bscsInf.jobCd = res.result.mdfHisInf.val
                //this.bscsInf.jobCdNm = res.result.mdfHisInf.valnm
                this.bscsInf.aplStrt = this.$moment(res.result.mdfHisInf.aplStrt, 'YYYYMMDD').add(1, 'days').format('YYYY-MM-DD')
            }
        },

        // 직원 직종정보/조편성 저장
        setEmpMdf: function (obj){
            this.savBtn = true

            http.post('emp/insEmpMdfHis', obj)
                .then((response) => ( this.insEmpMdf(response.data) ))
                .catch((error) => console.log('connect error /emp/insEmpMdfHis : ' + error))
        },

        insEmpMdf: function (res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 2500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                           // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == '200'){
                this.rstFld()
                this.getEmpMdfList(this.bscsInf.empPk, this.bscsInf.mdfClcd)
            }
            else
                obj.type = 'warning'

            this.$store.commit('setWrnnInf', obj)
        },

        // 직원 직종정보/조편성 저장 삭제
        rmEmpMdf: function (obj){
            let oObj = {}
            oObj.empMdfHisPk = obj.empMdfHisPk
            oObj.mdfClcd = obj.mdfClcd
            oObj.empPk = this.bscsInf.empPk

            http.post('emp/delEmpMdfHis', oObj)
                .then((response) => ( this.insEmpMdf(response.data) ))
                .catch((error) => console.log('connect error /emp/delEmpMdfHis : ' + error))
        },

         // 기본정보 적용
        aplctInf: function (obj) {
            this.bscsInf.mdfClcd = obj.mdfClcd
            this.bscsInf.empPk = obj.empPk
            this.bscsInf.mmbrPk = obj.mmbrPk
            this.bscsInf.name = obj.name
            this.bscsInf.entDt = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY.MM.DD')
            this.bscsInf.rtrDt = this.$moment(obj.rtrDt, 'YYYYMMDD').format('YYYY.MM.DD')
            this.bscsInf.wrkStcd = obj.wrkStcd

            if(obj.mdfClcd == '1'){
                this.bscsInf.jobCdNm = obj.jobCdNm

                let idx = obj.jobList.findIndex( v => v.valcdnm == obj.jobCdNm )
                if(idx > -1)
                    this.bscsInf.jobCd = obj.jobList[idx].valcd
                else
                    this.bscsInf.jobCd = ''
    
                this.jobCd = this.bscsInf.jobCd
                this.jobCdNm = this.bscsInf.jobCdNm
                this.jobList = obj.jobList
            }
            else if(obj.mdfClcd == '2'){
                this.bscsInf.empGrpPk = obj.empGrpPk
                this.bscsInf.grpNm = obj.grpNm
                
                this.empGrpPk = this.bscsInf.empGrpPk
                this.grpNm = this.bscsInf.grpNm
                this.grpList = obj.grpList
            }

            //this.rtrDt = this.$moment(obj.rtrDt, 'YYYYMMDD').format('YYYY-MM-DD')

            this.aplctSavBtn()

            this.getEmpMdfList(this.bscsInf.empPk, this.bscsInf.mdfClcd)
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true
            // let tmpDt = this.$moment(this.bscsInf.entDt, 'YYYY.MM.DD').format('YYYY-MM-DD')

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.aplStrt) == false)
                return 0
            if(this.bscsInf.mdfClcd == '1')
                if(this.jobCd == '')
                    return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3000                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = '잘못된 요청입니다.'              // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(this.$moment(this.bscsInf.aplStrt).isAfter(this.$moment(this.aplStrt))){
                obj.title = '기 등록된 적용시작일과 중복됩니다.'
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let insObj = {}
            insObj.empPk = this.bscsInf.empPk
            insObj.mmbrPk = this.bscsInf.mmbrPk
            insObj.name = this.bscsInf.name
            insObj.mdfClcd = this.bscsInf.mdfClcd
            if(this.bscsInf.mdfClcd == '1'){
                insObj.val = this.jobCd
                let idx = this.jobList.findIndex( v => v.valcd == this.jobCd )
                if(idx > -1)
                    insObj.valNm = this.jobList[idx].valcdnm
                else
                    insObj.valNm = ''
            }
            else if(this.bscsInf.mdfClcd == '2'){
                insObj.val = this.empGrpPk
                insObj.valNm = this.grpNm
            }
            insObj.wrtDt = this.$moment().format('YYYYMMDD')
            insObj.aplStrt = this.$moment(this.aplStrt, 'YYYY-MM-DD').format('YYYYMMDD')
            insObj.rmrks = this.rmrks
            //insObj.aplYn = '2'

            if(insObj.empPk != -1 && insObj.mmbrPk != -1)
                this.setEmpMdf(insObj)
            else
                this.$store.commit('setWrnnInf', obj)
        },

        rmMdfHisBtn: function (idx) {
            this.mdfList[idx].rmPosn = true
            this.runAble = '1'

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = '적용시작일: ' + this.$moment(this.mdfList[idx].aplStrt, 'YYYYMMDD').format('YYYY.MM.DD')
            if(this.bscsInf.mdfClcd == '1')
                obj.cntnt = ['직원 직종정보 변경이력을 삭제하시겠습니까?']
            else if(this.bscsInf.mdfClcd == '2')
                obj.cntnt = ['직원 조편성 변경이력을 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });   
        },

        // 조편성 선택 적용
        aplyGrp: function (v) {
            this.empGrpPk = v.empGrpPk
            this.grpNm = v.grpNm
        },

        // 입력필드 초기화
        rstFld: function () {
            this.empGrpPk = ''
            this.aplStrt = ''
            this.rmrks = ''
            this.jobCd = ''
            this.jobCdNm = ''
            this.grpNm = ''

            this.aplctSavBtn()
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        savBtn: true,
        runAble: '0',
        bscsInf: {
            empPk: -1, mmbrPk: -1, empGrpPk: -1, name: '', entDt: '', rtrDt: '', wrkStcd: '', jobCd: '', jobCdNm: '',
            aplStrt: '', grpNm: '', mdfClcd: '', val: '', valNm: '담당직종'
        },
        jobCd: '',
        jobCdNm: '',
        empGrpPk: '',
        grpNm: '',
        aplStrt: '',
        rmrks: '',
        jobList: [],
        grpList: [],
        mdfList: [],
        lclMdlInf: { isOn: false, maxWidth: 600, typ: '' },             // 통합 팝업 제어 (02: 조편성 선택)
    })
}
</script>