<template>
    <v-sheet class="ma-0 pa-7" rounded="lg" min-height="700">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div>
        <div class="d-flex justify-left align-center mb-2 ">
            <span class="text-h4 font-weight-bold">특별침실 사용기록</span>
            <v-spacer></v-spacer>            
            <v-icon class="pl-2" color="black" x-large @click="onClose">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-left align-center mb-2">
            <v-spacer></v-spacer>
            <v-btn v-for="(list, i) in filledBtn" :key="i"  @click="onClickBtn(i)"
                :class="list.class" height="30" :color="list.color" rounded
                :disabled="list.disabled"
                style="font-size: 14px;">
                <v-icon class="mr-1" size="18">{{list.icon}}</v-icon>
                {{list.text}}
            </v-btn>            
        </div>
        <v-form ref="mform" lazy-validation>
            <v-row class="pa-0 mt-2" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">수급자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="2">{{bnfcrInfo.name}}</v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">성별</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pl-2 py-1 ma-0" cols="2">
                    <span class="">
                        <v-img class="ic-sex" v-if="bnfcrInfo.gndr == '여'" src="../../assets/icon/ic-female.svg"></v-img>
                        <v-img class="ic-sex" v-else-if="bnfcrInfo.gndr == '남'" src="../../assets/icon/ic-male.svg"></v-img>
                    </span>
                    <span class="ml-1">&#40;{{bnfcrInfo.age}}&#41;</span>
                </v-col>
                <v-col class="greyE01 d-flex justify-center align-center pa-0 ma-0" cols="1">
                    <span class="black--text font-weight-medium">주요질환</span>
                </v-col>
                <v-col class="pa-1 ma-0 d-flex justify-center align-center" cols="5">
                    <!-- {{items.majorDsss}} -->
                    <v-tooltip
                        v-model="majorDsssTooltip"
                        bottom
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            text
                            v-bind="attrs"
                            v-on="on"
                            height="20"
                            style="padding:0px 5px !important;"
                            >
                            {{bnfcrInfo.majorDsss}}
                            </v-btn>
                        </template>
                        <span>{{bnfcrInfo.majorOrgDsss}}</span>
                    </v-tooltip>
                </v-col>
            </v-row>    
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">제재기간</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="5">
                    <span class="white d-inline-block" style="width: 130px;border-radius: 10px;">                           
                        <CmmDateComp v-model="items.useDtStrt" :required="true"></CmmDateComp>
                    </span>
                    <span class="px-1">&#126;</span>
                    <span class="white d-inline-block" style="width: 130px;border-radius: 10px;">                           
                        <CmmDateComp v-model="items.useDtEnd" :required="true" :minDt="items.useDtStrt"></CmmDateComp>
                    </span>
                </v-col>   
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">특별침실</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="2">
                    <span class="white d-inline-block" style="width: 130px;border-radius: 10px;">
                        <v-text-field v-model="items.lvnRmNm" color="blue" height="28" ref="info"
                            :rules="[rules.required, rules.strCheck]" hide-details outlined dense :key="forceRender" readonly></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1"  
                        color="#FFB6B9"
                        x-small 
                        min-width="30"
                        height="25"                         
                        style="padding:0px 1px 0px 2px !important"
                        @click="onModel(2)"
                        >
                            <v-icon class="white--text" size="20">mdi-bed-king-outline</v-icon>
                    </v-btn>
                </v-col>         
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">작성자</span>
                </v-col>
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="2">
                    <span class="white d-inline-block" style="width: 70px;border-radius: 10px;">
                        <v-text-field v-model="items.wrtr" color="blue" height="28" ref="info"
                            :rules="[rules.required, rules.strCheck]" hide-details outlined dense :key="forceRender" readonly></v-text-field>
                    </span>
                    <v-btn 
                        class="ml-1 brown lighten-3 "  
                        x-small 
                        min-width="30"
                        height="25"                          
                        style="padding:0px 1px 0px 2px !important"
                        @click="onEmpModel('spc')"
                        >
                        <v-icon class="white--text" size="20">mdi-account-edit</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #dbdbdb;" no-gutters>
                <v-col class="greyE01 d-flex justify-center align-center pa-1 ma-0" cols="1">
                    <span class="black--text font-weight-medium">사용사유</span>
                </v-col>                
                <v-col class="d-flex justify-start align-center pa-1 ma-0" cols="11">
                    <v-textarea v-model="items.useRsn" class="rmrksArea" no-resize  style="font-size:0.9rem !important;"
                        rows="2" outlined dense hide-details>
                    </v-textarea>
                </v-col>
            </v-row>        
        </v-form>
        <div class="d-flex align-center mt-2">
            <v-icon style="font-size: 28px;" color="black">mdi-chevron-right</v-icon>
            <span class="text-h6 font-weight-bold">관찰기록</span>
        </div>
        <div :class="['d-flex pa-0', spcHlthNsgList.length > 8 ? 'mr-2__5' : '']" no-gutters style="border-top: 1px solid #dbdbdb !important;border-bottom: 1px solid #dbdbdb !important;">
            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:14%;">                                
                <span class="black--text font-weight-medium">관찰일</span>
            </div>
            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                <span class="black--text font-weight-medium">관찰시간</span>
            </div>
            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">                                
                <span class="black--text font-weight-medium">혈압&#40;고&#47;저&#41;</span>
            </div>
            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                <span class="black--text font-weight-medium">맥박&#40;회&#47;분&#41;</span>
            </div>
            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">                                
                <span class="black--text font-weight-medium">체온&#40;°C&#41;</span>
            </div>            
            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;">                                
                <span class="black--text font-weight-medium">관찰내용</span>
            </div>
             <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                <span class="black--text font-weight-medium">관찰자</span>
            </div>
            <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">                                
                <v-btn 
                    class="mr-1" 
                    color="blue200" 
                    height="26" 
                    @click="addRow"
                    icon>
                    <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
            </div>
        </div>
        <div v-if="spcHlthNsgList.length > 0">
            <div style="height:365px;overflow-y:auto"> 
                <div v-for="(item,i) in spcHlthNsgList" :key="i" class="d-flex pa-0 ma-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:14%;">                                
                        <span class="white d-inline-block" style="width: 154px;border-radius: 10px;">                           
                            <CmmDateComp v-model="item.recDt" :required="true" :maxDt="items.useDtEnd"></CmmDateComp>
                        </span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                                    
                        <span class="white d-inline-block pa-1" style="width: 80px;"> 
                            <CmmTimeField v-model="item.obsrvTm" :required="true"></CmmTimeField>  
                        </span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">                                
                        <div class="d-flex justify-center align-center">
                        <span style="width:70px;">
                            <v-text-field v-model="item.bldPrsL" color="blue" height="28" type="number" min="0" hide-details outlined dense></v-text-field>
                        </span>
                        <span class="px-1">&#47;</span>
                        <span style="width:70px;">
                            <v-text-field v-model="item.bldPrsH" color="blue" height="28" type="number" min="0" hide-details outlined dense></v-text-field>
                        </span>
                        </div>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                        <span style="width:70px;">
                            <v-text-field v-model="item.pulse" color="blue" height="28" type="number" min="0" hide-details outlined dense></v-text-field>
                        </span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">                                
                        <span style="width:70px;">
                            <v-text-field v-model="item.tmprt" color="blue" height="28" type="number" min="0" hide-details outlined dense></v-text-field>
                        </span>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:25%;border-left: 1px solid #dbdbdb !important;">                                                        
                        <v-text-field v-model="item.obsrvCntnt" color="blue" height="28" hide-details outlined dense></v-text-field>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:10%;border-left: 1px solid #dbdbdb !important;">                                
                        <span class="white d-inline-block" style="width: 70px;border-radius: 10px;">
                            <v-text-field v-model="item.wrtr" color="blue" height="28" ref="info"
                                :rules="[rules.required, rules.strCheck]" hide-details outlined dense :key="forceRender" readonly></v-text-field>
                        </span>
                        <v-btn 
                            class="ml-1 brown lighten-3 "  
                            x-small 
                            min-width="30"
                            height="25"                          
                            style="padding:0px 1px 0px 2px !important"
                            @click="onEmpModel('hlth',i)"
                            >
                            <v-icon class="white--text" size="20">mdi-account-edit</v-icon>
                        </v-btn>
                    </div>
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:8%;border-left: 1px solid #dbdbdb !important;">                                                                                        
                        <v-btn icon color='blue200' @click="onSaveRow(i)">
                            <v-icon>mdi-content-save-outline</v-icon>
                        </v-btn>
                        <v-btn icon color='grey006' @click="delRow(i)">                                            
                            <v-icon v-if="item.hlthVtlRecPk > 0">mdi-trash-can-outline</v-icon>
                            <v-icon v-else>mdi-minus-circle-outline</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="d-flex justify-center align-center pa-1 ma-0" style="width:1230px;border-bottom: 1px solid #dbdbdb !important;">                                
                데이터가 없습니다.
            </div>
        </div>
        <div class="ma-0 pa-0">
            <v-dialog eager v-model="dialog[0]" max-width="700">    
                <grdnNtcModel 
                    ref="grdnNtcModel"                   
                    @isClose="isModal(0)"
                    @modalEvent="spclBURtrigger"
                    ></grdnNtcModel>                  
            </v-dialog>   
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[1]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isModal(1)" @outEmp="getEmpInfo" :prtWrtDt="items.useDtStrt"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[2]" eager content-class="round" persistent max-width="650">
                <LvnRmSelector @isClose="isModal(2)" @outLvnRm="getLvnRm"></LvnRmSelector>
            </v-dialog>  
        </div>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialogDelete" max-width="500">    
                <btnModalDelete :title="delTitle" @madalState="onDelete"></btnModalDelete>  
            </v-dialog>
        </div>
    </v-sheet>
</template>
<script>
import grdnNtcModel from './GrdnNtcModel.vue';
import EmpSelector from '../commons/EmpSelector.vue';
import LvnRmSelector from '../commons/LvnRmSelector.vue';
import btnModalDelete from '../../components/bnfcr/BnfcrDeleteConfirm.vue';
import CmmDateComp from '../commons/CmmDateComp.vue';
import CmmTimeField from '../commons/CmmTimeField.vue';
import { selSpclbdUseInfo, selHlthVtlRecList, insSpclbdUse, insHlthVtlRec, delHlthVtlRec, delSpclbdUse } from '../../api/bnftrcrd.js';

export default {
    name: 'SpclBdrmUseRcrdWrt',
    
    components: {
        grdnNtcModel,
        EmpSelector,
        LvnRmSelector,
        btnModalDelete,
        CmmDateComp,
        CmmTimeField,
    },

    props : {
        bnfcrInfo:{type:Object, default:new Object}
    },

    created: function(){       
      
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            
        })
    },

    computed: {
       
    },  

    watch: {
       
    },

    methods: {      
        onLoad:function(value){
            this.getSpclbdUseInfo(value)
        },
        getSpclbdUseInfo:function(value){
            let gFcltyNum = this.$store.getters.getFcltyNum                
            selSpclbdUseInfo(gFcltyNum,value)
                .then((response) => ( this.getSpclbdUseInfoAfter(response.data) ))
                .catch((error) => console.log('upload error /bnftrcrd/selPhysSnctInfo : ' + error))
        },
        getSpclbdUseInfoAfter:function(res){
            this.filledBtn[0].disabled = false
            this.filledBtn[2].disabled = false
            res.useDtStrt = res.useDtStrt.beforeDateFormatHyp()
            res.useDtEnd = res.useDtEnd.beforeDateFormatHyp()
            this.items = res  
            if(this.items.spclbdUsePk > 0){
                this.getHlthVtlRecList()
            }
        },  
        getHlthVtlRecList(){
            let fcltyNum = this.$store.getters.getFcltyNum
            let obj = {
                fcltyNum: fcltyNum, 
                clsfc1Cd: '302',
                clsfc2Cd: '3',
                bnMmbrPk: this.bnfcrInfo.bnMmbrPk,
                spclbdUsePk : this.items.spclbdUsePk
            }            
            selHlthVtlRecList(obj)
                .then((response) => ( this.getHlthVtlRecListAfter(response.data)))
                .catch((error) => console.log(error))
        },
        getHlthVtlRecListAfter:function(res){
            // this.spcHlthNsgList.splice(0)

            res.forEach(e => {
                e.recDt = e.recDt?.beforeDateFormatHyp()
                if(e.obsrvTmHh != '' && e.obsrvTmHh != null){
                    e.obsrvTm = e.obsrvTmHh +':'+e.obsrvTmMm
                }
            });

            this.spcHlthNsgList = res
            
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        onClickBtn:function(key){
            
           switch (key) {
            case 0:
                this.dialog.splice(key, 1, true) 
                this.$refs.grdnNtcModel.onLoad('7',this.items.spclbdUsePk,this.bnfcrInfo.bnMmbrPk,'2')                
                break;
            case 1:
                this.onSave()
                break;
            case 2:
                    this.type='all'
                    this.delTitle='특별침실 사용기록'
                    this.dialogDelete = true
                break;
            default:
                break;
           }
        },
        onSave:function(){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.bnfcrInfo.bnMmbrPk
            let gUserNm = this.$store.getters.getUserNm

            if(gBnMmbrPk > 0 && this.$refs.mform.validate()){
                let obj = JSON.parse(JSON.stringify(this.items))
                
                obj.fcltyNum = gFcltyNum
                obj.bnMmbrPk = gBnMmbrPk
                obj.userNm = gUserNm
                obj.useDtStrt = obj.useDtStrt.afterDateFormatHyp()
                obj.useDtEnd = obj.useDtEnd.afterDateFormatHyp()

                insSpclbdUse(obj)
                        .then((response) => ( this.onSaveAfter(response.data) ))
                        .catch((error) => console.log('upload error /bnftrcrd/insSpclbdUse : ' + error))
            }
        },
        onSaveAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.onLoad(res.resultPk)
                this.filledBtn[0].disabled = false
                this.filledBtn[2].disabled = false
                this.$emit('modalEvent', 99);
            }else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        onSaveRow:function(idx){
            let gFcltyNum = this.$store.getters.getFcltyNum
            let gBnMmbrPk = this.bnfcrInfo.bnMmbrPk
            let gUserNm = this.$store.getters.getUserNm
            let saveChk = true

            if(this.spcHlthNsgList[idx].wrtr == ''){
                saveChk = false
                this.snackControll([true, 2500, '관찰자을 선택해주세요.', 'warning']) 
            }

            if(this.spcHlthNsgList[idx].obsrvTm == ''){
                saveChk = false
                this.snackControll([true, 2500, '관찰시간을 선택해주세요.', 'warning']) 
            }

            if(this.spcHlthNsgList[idx].recDt == ''){
                saveChk = false
                this.snackControll([true, 2500, '관찰일를 선택해주세요.', 'warning']) 
            }

            if(gBnMmbrPk > 0 && saveChk && this.items.spclbdUsePk > 0){
                let obj = JSON.parse(JSON.stringify(this.spcHlthNsgList[idx]))

                obj.spclbdUsePk = this.items.spclbdUsePk
                obj.recDt = obj.recDt.afterDateFormatHyp()
                obj.fcltyNum = gFcltyNum
                obj.bnMmbrPk = gBnMmbrPk
                obj.userNm = gUserNm
                
                if(obj.obsrvTm !=''){
                    let obsrvTm = obj.obsrvTm.split(":")
                    obj.obsrvTmHh = obsrvTm[0]
                    obj.obsrvTmMm = obsrvTm[1]
                }else{
                    obj.obsrvTmHh = ''
                    obj.obsrvTmMm = ''
                }

                insHlthVtlRec(obj)
                        .then((response) => ( this.onSaveRowAfter(response.data) ))
                        .catch((error) => console.log('error /bnftrcrd/insHlthVtlRec : ' + error))
            }
        },
        onSaveRowAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                this.getHlthVtlRecList(res.result)
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onClose: function () {
            this.$emit('isClose', 0)
            this.$refs.mform.reset()
            this.items.spclbdUsePk = 0
            this.items.bnMmbrPk = 0
            this.filledBtn[0].disabled = true
            this.filledBtn[2].disabled = true
            this.spcHlthNsgList.splice(0)
            this.$refs.mform.resetValidation()
        },   
        onforce(key){
            this.mtimes[key] = false
            ++this.forceRender
        },
        onModel:function (key) {
            this.dialog.splice(key, 1, true)
        },       
        isModal: function (key) {
            this.dialog.splice(key, 1, false)
        },          
        spclBURtrigger: function (event) { 
            if(event == 99){
                this.$emit('modalEvent', 99)
            }     
        },  
        datePickClose: function (value) {
            this.menu.splice(value, 1, false)
        },
        datePickClose1: function (value) {
            this.menu1.splice(value, 1, false)
        },
        onEmpModel:function(type,idx=0){
            this.empType = type
            this.idx = idx      
            this.dialog.splice(1, 1, true)      
        },
        getEmpInfo: function (obj) {
            if(this.empType=='spc')
                this.items.wrtr = obj[0].name
            else
                this.spcHlthNsgList[this.idx].wrtr = obj[0].name
        },
        getLvnRm: function (obj) {
            this.items.lvnRmInfPk = obj.lvnRmInfPk
            this.items.lvnRmNm = obj.lvnRmNm
        },
        addRow:function(){
            if(this.items.spclbdUsePk > 0){
                this.spcHlthNsgList.push({fcltyNum:'',hlthVtlRecPk:0,bnMmbrPk:0,recDt:'',
                    clsfc1Cd:'302',clsfc2Cd:'3',spclbdUsePk:0,obsrvTm:'',obsrvTmHh:'',obsrvTmMm:'',
                    bldPrsH:'',bldPrsL:'',pulse:'',tmprt:'',rsprt:'',bldSgr:'',wght:'',obsrvCntnt:'',wrtr:'',})
                if(this.spcHlthNsgList.length >= this.menu1.length) this.menu1.push(false)
            }else{
                this.snackControll([true, 2500, '특별침실 사용기록을 저장해주세요.', 'warning']) 
            }
        },
        delRow:function(idx){
            if(this.spcHlthNsgList[idx].hlthVtlRecPk > 0){
                this.type='hlth'
                this.delTitle='관찰기록'
                this.idx = idx
                this.dialogDelete = true
            }else{
                this.spcHlthNsgList.splice(idx,1)
            }
        },
        onDelete:function(res){
            let gFcltyNum = this.$store.getters.getFcltyNum    
            let gUserNm = this.$store.getters.getUserNm
            let gBnMmbrPk = this.bnfcrInfo.bnMmbrPk

            if(res == 9990){
                if(this.type=="all"){
                    let spclbdUsePk = this.items.spclbdUsePk
                    if(spclbdUsePk > 0){                        
                        delSpclbdUse(gFcltyNum,gUserNm,spclbdUsePk)
                            .then((response) => ( this.onDeleteAfter(response.data)))
                            .catch((error) => console.log('error /bnftrcrd/delSpclbdUse : ' + error))
                    }
                }else if(this.type=="hlth"){
                    let hlthVtlRecPk = this.spcHlthNsgList[this.idx].hlthVtlRecPk
                    if(hlthVtlRecPk > 0){
                        
                        let obj = {
                            fcltyNum: gFcltyNum,
                            bnMmbrPk : gBnMmbrPk,
                            userNm : gUserNm,
                            hlthVtlRecPk : hlthVtlRecPk
                        }

                        delHlthVtlRec(obj)
                            .then((response) => ( this.onDeleteAfter(response.data)))
                            .catch((error) => console.log('upload error /bnftrcrd/delHlthVtlRec : ' + error))
                    }
                }
            }else if(res == 7){
                this.dialogDelete = false
            }

        },
        onDeleteAfter:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
                if(this.type=="hlth"){
                    this.spcHlthNsgList.splice(this.idx,1)
                }else if(this.type=="all"){
                    this.$emit('modalEvent', 99)
                    this.onClose()
                }   
                this.idx = 0
                this.type = ''
                this.delTitle = ''
            }else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
            this.dialogDelete = false
        },
    },    
    data: () => ({
        idx:0,
        type:'',
        delTitle:'',
        empType:'',
        forceRender:0,
        majorDsssTooltip:false,
        snackOptions: [false, 3000, '', 'info'],
        menu:[false,false,],
        menu1:[false],
        dialog:[false,false,],        
        mtimes:[false,false,false,false,false,false,false,],
        items:{
            fcltyNum:'',
            spclbdUsePk:0,
            bnMmbrPk:0,
            lvnRmInfPk:0,
            lvnRmNm:'',
            useDtStrt:'',
            useDtEnd:'',
            useRsn:'',
            wrtr:'',
            userNm:'',
        },
        spcHlthNsgList:[],
        filledBtn: [            
            { icon: 'mdi-bullhorn-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '보호자통지', disabled: true, },
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', disabled: true, },
        ],
        rules: {
            selectCheck: value => value?.length > 0 || 'Required.',
            required: value => !!value || 'Required.',
            selCheck: value => !( value == '선택' || value == '' ) || 'Required.',
            strCheck: value => /^[a-zA-Z가-힣0-9]*$/.test(value) || 'String Validate.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            numberCheck: value => /^[0-9]*$/.test(value) || 'Number Validate.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        }, 
        dialogDelete:false,
    }),
};
</script>