<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-row class="pa-0 justify-center align-center" no-gutters>
            <span class="text-h4 font-weight-bold">기관 인력배치 관리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="offMdl()" x-large>mdi-close</v-icon>
        </v-row>
        <v-divider class="mt-6 white"></v-divider>
        <v-row class="pa-0 mb-2 align-center posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">인력배치 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-form
            class="mr-1"
            ref="prsnInf"
            lazy-validation>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                    <span class="font-weight-medium posn-rltv">
                        인력배치기준
                        <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="d-flex align-center px-4" cols="9">
                    <span class="d-inline-block" style="width: 100px;">
                        <v-select
                            v-model="prsnAlctInf.itmCd1"
                            :items="prtCd137"
                            :rules="[rules.required]"
                            @change="setAplStrt(prsnAlctInf.itmCd1)"
                            item-text="valcdnm" item-value="valcd"                            
                            dense hide-details outlined>
                        </v-select>
                    </span>                    
                </v-col>
            </v-row>
            <v-divider class="mr-1"></v-divider>
            <v-row class="pa-0" no-gutters>
                <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                    <span class="font-weight-medium posn-rltv">
                        적용시작월
                        <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                            <v-icon color="red">mdi-star-four-points-small</v-icon>
                        </span>
                    </span>
                </v-col>
                <v-col class="d-flex align-center px-4" cols="9">                    
                    <span class="d-inline-block" style="width: 134px;">
                        <CmmDateYMComp
                            v-model="prsnAlctInf.itmVal1"
                            :minDt="aplStrt"
                            :required="true">
                        </CmmDateYMComp>
                    </span>
                    <span class="d-inline-block red--text ml-1 fontEighthRem">
                        월도 {{ aplStrt }} 이후만 입력 가능합니다.
                    </span>
                </v-col>
            </v-row>
        </v-form>
        <v-divider class="mr-1 mb-3"></v-divider>
        <v-row class="mb-3" no-gutters>
            <v-spacer></v-spacer>
            <v-btn @click="onSav()" class="white--text"
                samll height="30" color="blueBtnColor" rounded>
                <v-icon small>mdi-content-save-outline</v-icon>
                <span style="font-size: 1rem;">저장</span>
            </v-btn>             
            <v-spacer></v-spacer>  
        </v-row>
        <v-row class="pa-0 mb-2 align-center posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">기관 인력배치 내역</span>
        </v-row>
        <v-divider :class="[fcltyInitList.length > 5 ? 'mr-2__5' : 'mr-1']" color="black"></v-divider>
        <v-row :class="[fcltyInitList.length > 5 ? 'mr-2__5' : 'mr-1']" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium">기준연도</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium">인력배치기준</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium">적용 시작월</span>
            </v-col>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid py-2" cols="3">
                <span class="font-weight-medium">작성일자</span>
            </v-col>
        </v-row>
        <v-divider :class="[fcltyInitList.length > 5 ? 'mr-2__5' : 'mr-1']"></v-divider>
        <div v-if="fcltyInitList.length > 0" class="overflow-y-auto" style="width: 100%; height: 150px;">
            <v-row
                :class="['tableBB', fcltyInitList.length > 5 ? '' : 'mr-1']"
                v-for="(itm, i) in fcltyInitList" :key="i"
                no-gutters>
                <v-col class="posn-rltv txtAlgnMid tableBR" style="padding-top: 2px;" cols="3">
                    <span>{{ $moment(itm.itmVal1,'YYYYMM').format('YYYY') }}</span>
                </v-col>
                <v-col class="fontOneRem txtAlgnMid tableBR py-1" cols="3">
                    <span>{{ itm.itmVal2 }}</span>
                </v-col>
                <v-col class="fontOneRem txtAlgnMid tableBR py-1" cols="3">
                    <span>{{ $moment(itm.itmVal1,'YYYYMM').format('YYYY.MM') }}</span>
                </v-col>
                <v-col class="fontOneRem txtAlgnMid overflow-text-hidden pa-1" cols="3">
                    <span>{{ $moment(itm.wrtDt,'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                </v-col>                
            </v-row>
        </div>
        <v-row v-else class="tableBB pa-0" no-gutters>
            <v-col class="txtAlgnMid py-2" cols="12">
                <span class="fontOneRem grey006--text">※ 변경내역이 존재하지 않습니다.</span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';
import CmmDateYMComp from '../commons/CmmDateYMComp.vue';

export default {
    name: 'PrsnAlctMdl',
    
    components: {
        CmmDateYMComp,
    },
    
    props : {        
        prtCd137: { type: Array, default: () => { return [] } },     
    },

    created: function(){

    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.aplStrt = this.$moment(this.prtCd137[0].valdesc,'YYYYMM').format('YYYY-MM')
            this.prsnAlctInf.itmCd1 = this.prtCd137[0].valcd
            this.getFcltyInitList()
        })
    },

    computed: {
       
    },

    watch: {
        
    },
        
    methods: {
        // 기본정보 적용
        aplctInf: function (obj) {
            this.prsnAlctInf.itmCd1  = obj.itmCd1
            this.prsnAlctInf.itmVal1 = this.$moment(obj.itmVal1,'YYYYMM').format('YYYY-MM')
            this.prsnAlctInf.itmVal2 = obj.itmVal2    
            this.aplStrt = this.$moment(obj.itmVal1,'YYYYMM').format('YYYY-MM')        
        },
        // 팝업 닫기/확인
        offMdl: function () {
            this.$emit('modalClose')
        },
        getFcltyInitList:function(){    
            this.fcltyInitList.splice(0)       
            http.post('opr/selFcltyPrsnAlctList', {})
                .then((response) => ( this.rstFcltyInitListAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selFcltyPrsnAlctList : ' + error))
        },
        rstFcltyInitListAfter:function(res){            
            if (res.statusCode == 200) {
                res.result.forEach(el => {
                    this.fcltyInitList.push(el)
                });

                if(this.fcltyInitList.length > 0){
                    this.aplctInf(this.fcltyInitList[0])
                }

            } else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
            
        },
        //인력배치 저장
        onSav:function(){
            if(this.$refs.prsnInf.validate()){
                let idx = this.prtCd137.findIndex(v=>v.valcd == this.prsnAlctInf.itmCd1)
                let inqYYMM = this.$moment(this.prsnAlctInf.itmVal1,'YYYY-MM').format('YYYYMM')
                let obj = {
                    initClcd    : '6',
                    inqYYMM     : inqYYMM,
                    itmCd1      : this.prsnAlctInf.itmCd1,
                    itmVal1     : inqYYMM,
                    itmCd2      : null,
                    itmVal2     : this.prtCd137[idx].valcdnm,
                }
                http.post('opr/insPrsnAlct', obj)
                    .then((response) => ( this.setFcltyInitAfter(response.data) ))
                    .catch((error) => console.log('connect error /opr/insPrsnAlct : ' + error))
            }else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = '적용시작월은 이전월도보다 큰 경우에만 저장 할 수 있습니다.'         // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }
        },
        setFcltyInitAfter:function(res){
            console.log(res)
            let obj = {}
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message        // 경고 타이틀            
            
            if (res.statusCode == 200) {
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getFcltyInitList()
                this.$emit('modalReload')
            } else {
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)  
                obj.cntnt = res.messageList    // 경고 내용 (arr만 출력됨!)          
            }
    
            this.$store.commit('setWrnnInf', obj)
        },
        //적용시작일 체크
        setAplStrt:function(key){
            let idx = this.prtCd137.findIndex(v=>v.valcd == key)
            
            if(idx> -1){
                let valdesc = this.prtCd137[idx].valdesc //202302

                if(valdesc != '') {
                    if(this.fcltyInitList.length > 0){

                        let itmVal1 = this.fcltyInitList[0].itmVal1
                        
                        //선택된 날짜보다 크다면
                        let bl1 = this.$moment(valdesc+'01', 'YYYYMMDD').isAfter(this.$moment(itmVal1+'01', 'YYYYMMDD'))
                                                
                        if(bl1) 
                            this.aplStrt = this.$moment(valdesc, 'YYYYMM').format('YYYY-MM')
                        else 
                            this.aplStrt =  this.$moment(itmVal1, 'YYYYMM').format('YYYY-MM')
                        

                    } else {
                        this.aplStrt = this.$moment(valdesc, 'YYYYMM').format('YYYY-MM')
                    }
                }
            }
        }
    },


    data: () => ({
        aplStrt:'',
        prsnAlctInf : {
            fcltyInitPk:-1,
            initClcd:'',
            itmCd1:'',
            itmVal1:'',
            itmCd2:'',
            itmVal2:'',
        },
        fcltyInitList:[],
        headers: [
            { text: '기준연도', value: '', sortable: true, },
            { text: '인력배치기준', value: '', sortable: true, },
            { text: '적용 시작월', value: '', sortable: true, },
            { text: '작성일자', value: '', sortable: true, },
        ],
        rules: {
            fcltyConfirm: value => value.length == 11 || '잘못된 기관코드 입니다.',
            bsnsConfirm: value => value.length == 10 || '잘못된 사업자 번호 입니다.',
            numberCheck: value => /^[0-9]*$/.test(value) || '숫자만 입력 가능합니다.',
            numberSizeCheck: value => !(value && value.length > 4) || 'size to bigger',
            required: value => !!value || '필수입력 항목 입니다.',
            strIdSize: value => value.length > 1 || '2자리 이상 입력해주세요.',
            regExp: value => !(/[^\w\sㄱ-힣()0-9 ]/g.test(value)) || '특수문자는 입력이 불가능 합니다.',
            passwdReq: value => value.length > 3 || '비밀번호는 4자리 이상 입니다.',
            emailDomain: value => /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value) || 'Email Validate.',
        },
    }),
};
</script>