<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-3" color="" min-width="1260" max-width="1560" height="835">
        <v-divider class="mb-1 white"></v-divider>
        <v-row class="pa-0" no-gutters>
            <span class="hamletFont text-h5 topTitleColor--text font-weight-black">7-7. 직원건강관리</span>
        </v-row>
        <v-divider class="mb-4 white"></v-divider>
        <v-tabs
            v-model="tab"
            class="tab-dft-Theme" active-class="black"
            center-active hide-slider>
            <v-tab
                v-for="item in tabItems" :key="item.tab"
                :class="item.class" :disabled="item.enter">
                {{ item.tab }}
            </v-tab>
        </v-tabs>
        <v-divider class="bodyColor mb-2" style="padding: 1px 0px;"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="d-flex align-center" cols="4">
                <exBtn class="mr-1" :btnData="exBtn[0]" @btnClosed="expandClose" @changeCheck="filterSearch" />
                <exBtn class="mr-1" :btnData="exBtn[1]" @btnClosed="expandClose" @changeCheck="filterSearch" />

                <span class="d-inline-block" style="width: 106px; height: 34px;">
                    <v-text-field
                        class="txt-Theme" height="34" label="이름조회"
                        background-color="white" prepend-inner-icon="mdi-magnify"
                        @input="setSearchText"
                        single-line outlined dense filled hide-details>
                    </v-text-field>
                </span>
            </v-col>
            <v-spacer></v-spacer>
            <expandYearOnlyPicker style="margin-top: 2px;" @nowYear="getYear"></expandYearOnlyPicker>
            <v-spacer></v-spacer>
            <v-col class="" cols="4"></v-col>
        </v-row>
        <v-divider class="mb-1 white"></v-divider>
        <v-tabs-items v-model="tab">
            <v-tab-item transition="false">
                <v-data-table
                    v-if="tab == 0"
                    class="" locale="ko" height="672" min-width="1360"
                    :loading="isLoad" :loading-text="loadMsg"
                    :headers="empHlthHdr" :items="empHlthList" 
                    :search="schDummy" :custom-filter="customFilter"               
                    disable-pagination hide-default-footer hide-default-header fixed-header dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>                            
                                <th :class="props.headers[0].class" :style="props.headers[0].style" colspan="1" rowspan='2'>
                                    <span class="black--text">{{ props.headers[0].text }}</span>
                                </th>
                                <th :class="props.headers[0].class" :style="props.headers[1].style" colspan="1" rowspan='2'>
                                    <span class="black--text">{{ props.headers[1].text }}</span>
                                </th>
                                <th :class="props.headers[0].class" :style="props.headers[2].style" colspan="1" rowspan='2'>
                                    <span class="black--text">{{ props.headers[2].text }}</span>
                                </th>
                                <th :class="props.headers[0].class" :style="props.headers[3].style" colspan="1" rowspan='2'>
                                    <span class="black--text">{{ props.headers[3].text }}</span>
                                </th>                            
                                <th :class="props.headers[0].class" :style="props.headers[4].style" colspan="1" rowspan='2'>
                                    <span class="black--text">{{ props.headers[4].text }}</span>
                                </th>
                                <th :class="props.headers[0].class" :style="props.headers[5].style" colspan="1" rowspan='2'>
                                    <span class="black--text">{{ props.headers[5].text }}</span>
                                </th>
                                <th :class="props.headers[0].class" :style="props.headers[6].style" colspan="1" rowspan='2'>
                                    <span class="black--text">{{ props.headers[6].text }}</span>
                                </th>
                                <th :class="props.headers[0].class" :style="props.headers[7].style" colspan="1" rowspan='2'>
                                    <span class="black--text">{{ props.headers[7].text }}</span>
                                </th>
                                <th :class="props.headers[8].class" :style="props.headers[8].style" style="" colspan="2" rowspan='1'>
                                    <span class="black--text">{{ props.headers[8].text }}</span>
                                </th>
                            </tr>
                            <tr class="">
                                <th :class="props.headers[9].class" :style="props.headers[9].style">
                                    <span class="black--text">{{ props.headers[9].text }}</span>
                                </th>
                                <th :class="props.headers[10].class" :style="props.headers[10].style">
                                    <span class="black--text">{{ props.headers[10].text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>     
                    <template v-slot:item='{ item }'>
                        <tr>                        
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="fontOneRem">{{ item.wrkStcd }}</span>
                            </td>
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="fontOneRem">{{ item.jobCd }}</span>
                            </td>
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="fontOneRem">{{ item.name }}</span>
                            </td>
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="fontOneRem">{{ $moment(item.rsdntBirth, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                            </td>
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="d-inline-block pt-1" style="">
                                    <v-img class="ic-sex" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                    <v-img class="ic-sex" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                </span>
                            </td>
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="fontOneRem">{{ $moment(item.entDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                            </td>
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="fontOneRem">
                                    {{ item.rtrDt == '' ? '' : $moment(item.rtrDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                                </span>
                            </td>
                            <td
                                class="crsShp-Pointer txtAlgnMid tableBB tableBR py-0 px-2"
                                @click="onRglrChckp( item )">
                                <span v-if="item.hltChcDt == ''">
                                    <span v-if="item.rtrYn == '2'" class="red--text fontOneRem">미작성</span>
                                    <span v-else class="fontOneRem">퇴직</span>
                                </span>
                                <span v-else class="fontOneRem">
                                    {{ item.hltChcDt == '' ? '' : $moment(item.hltChcDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                                </span>
                            </td>
                            <td
                                class="crsShp-Pointer txtAlgnMid tableBB tableBR py-0 px-2"
                                @click="onMsclsChck( item , 0 )">
                                <span v-if="item.wrtDt == ''">
                                    <span v-if="item.rtrYn == '2'" class="fontOneRem red--text">미작성</span>
                                    <span v-else class="fontOneRem">퇴직</span>
                                </span>
                                <span v-else class="fontOneRem">
                                    {{ item.wrtDt == '' ? '' : $moment(item.wrtDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                                </span>
                            </td>
                            <td
                                class="crsShp-Pointer txtAlgnMid tableBB py-0 px-2"
                                @click="onMsclsChck( item , 1 )">
                                <div class="d-flex justify-center align-center" style="height:100%" >
                                    <div v-if="item.wrkTgYn == '1'">
                                        <span v-if="item.wrkDt == ''">
                                            <span v-if="item.rtrYn == '2'" class="fontOneRem red--text">미작성</span>
                                            <span v-else class="fontOneRem">퇴직</span>
                                        </span>
                                        <span v-else class="fontOneRem">
                                            {{ item.wrkDt == '' ? '' : $moment(item.wrkDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                                        </span>
                                    </div>
                                    <div v-else>
                                        <span class="fontOneRem">해당없음</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item transition="false">
                <v-data-table
                    v-if="tab == 1"
                    class="" locale="ko" height="680" min-width="1360"
                    :loading="isLoad" :loading-text="loadMsg"
                    :headers="rglrChckpHdr" :items="rglrChckpList"
                    :search="schDummy" :custom-filter="customFilter"               
                    disable-pagination hide-default-footer hide-default-header fixed-header dense>
                    <template v-slot:header="{ props }" >
                        <thead>
                            <tr>                            
                                <th :class="props.headers[0].class" :style="props.headers[0].style">
                                    <span class="black--text">{{ props.headers[0].text }}</span>
                                </th>
                                <th :class="props.headers[0].class" :style="props.headers[1].style">
                                    <span class="black--text">{{ props.headers[1].text }}</span>
                                </th>
                                <th :class="props.headers[0].class" :style="props.headers[2].style">
                                    <span class="black--text">{{ props.headers[2].text }}</span>
                                </th>
                                <th :class="props.headers[0].class" :style="props.headers[3].style">
                                    <span class="black--text">{{ props.headers[3].text }}</span>
                                </th>                            
                                <th :class="props.headers[0].class" :style="props.headers[4].style">
                                    <span class="black--text">{{ props.headers[4].text }}</span>
                                </th>
                                <th :class="props.headers[0].class" :style="props.headers[5].style">
                                    <span class="black--text">{{ props.headers[5].text }}</span>
                                </th>
                                <th :class="props.headers[0].class" :style="props.headers[6].style">
                                    <span class="black--text">{{ props.headers[6].text }}</span>
                                </th>
                                <th :class="props.headers[7].class" :style="props.headers[7].style">
                                    <span class="black--text">{{ props.headers[7].text }}</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item='{ item, index }'>
                        <tr>                        
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="fontOneRem">{{ index + 1 }}</span>
                            </td>
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="fontOneRem">{{ item.name }}</span>
                            </td>
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="d-inline-block pt-1" style="">
                                    <v-img class="ic-sex" v-if="item.gndr == 'F'" src="../../assets/icon/ic-female.svg"></v-img>
                                    <v-img class="ic-sex" v-else-if="item.gndr == 'M'" src="../../assets/icon/ic-male.svg"></v-img>
                                </span>
                            </td>
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="fontOneRem">{{ $moment(item.rsdntBirth, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                            </td>
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="fontOneRem">{{ item.jobCd }}</span>
                            </td>
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="fontOneRem">{{ $moment(item.entDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                            </td>
                            <td class="txtAlgnMid tableBB tableBR py-0 px-2">
                                <span class="fontOneRem">
                                    {{ item.rtrDt == '' ? '' : $moment(item.rtrDt, 'YYYYMMDD').format('YYYY.MM.DD') }}
                                </span>
                            </td>
                            <td
                                class="crsShp-Pointer txtAlgnMid tableBB py-0 px-2"
                                @click="onBfrJnnHlthChck( item )">
                                <!-- entHlthChdt 제출일 hlthExmDt 검진일 -->
                                <span v-if="item.hlthExmDt == ''" class="fontOneRem red--text">미작성</span>
                                <span v-else class="fontOneRem">{{ $moment(item.entHlthChdt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>
    </v-sheet>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';
import { http } from '@/api/baseAxios';

import exBtn from '../../components/commons/expandButtonCustom.vue';
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';

export default {
    name: 'EmpHlthMgmt',
    
    components: {
        exBtn,
        expandYearOnlyPicker,
    },

    created: function(){
       
    },

    computed: {
        getRootActv: function () {
            return this.$store.getters.getRootActv
        },
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getCode(['42', '70'])
        })
    },

    watch: {
        getRootActv: function (v) {
            // 서브모달 OFF - 재조회
            if(this.runAble == '1' && !v){
                this.rflSltList()
                this.runAble = '0'
            }
        },
        'tab': function () {
            this.rflSltList()
        },
    },
    
    methods: {
        // 코드 조회
        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd42 = res.vCd42
            this.vCd70 = res.vCd70

            this.vCd42.forEach(code => {
                this.exBtn[1].expandList.push(code.valcdnm)
            })
            this.vCd70.forEach(code => {
                this.exBtn[0].expandList.push(code.valcdnm)
            })

            if(this.empHlthList.length > 0){
                switch (this.tab) {
                    case 0:
                        this.getEmpHltChcList()
                        break;
                    case 1:
                        this.getEmpBfrEntList()
                        break;
                    default:
                        break;
                }
            }
        },
        // 직원건강관리 다건 조회
        getEmpHltChcList: function(){
            this.isLoad = true
            this.empHlthList.splice(0)

            let obj = {}
            obj.fcltyNum = this.$store.getters.getFcltyNum
            obj.inqYYYY = this.year

            http.post('emp/selEmpHltChcList', obj)
                .then((response) => ( this.empHltChcRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpHltChcList : ' + error))
        },
        empHltChcRst: function(res){
            if(Array.isArray(res)){
                res.forEach(item => {

                    item.rtrYn = '2'
                    if(item.entDt == null) item.entDt = ''
                    if(item.rtrDt == null) item.rtrDt = ''
                    if(item.hltChcDt == null) item.hltChcDt = ''
                    if(item.wrtDt == null) item.wrtDt = ''
                    if(item.wrkDt == null) item.wrkDt = ''

                    if(item.rtrDt != null && item.rtrDt != ''){
                        let nowYear = Number(this.year)
                        let rtrYear = Number(item.rtrDt?.slice(0,4))

                        if(rtrYear <= nowYear)
                            item.rtrYn = '1'
                    }

                    if(this.vCd42.length > 0){
                        this.vCd42.forEach(cd => {
                            if(item.jobCd == cd.valcd) item.jobCd = cd.valcdnm
                        })
                    }
                    if(this.vCd70.length > 0){
                        this.vCd70.forEach(cd => {
                            if(item.wrkStcd == cd.valcd) item.wrkStcd = cd.valcdnm
                        })
                    }
                });

                this.empHlthList = res
            }
            else
                console.log('empHltChcList return type missmatch')

            this.isLoad = false
        },
        // 입사전 건강검진 다건 조회
        getEmpBfrEntList: function(){
            this.isLoad = true
            //this.empHlthList.splice(0)

            let obj = {}
            obj.inqYYYY = this.year

            http.post('emp/selEmpBfrEntList', obj)
                .then((response) => ( this.empBfrEntRst(response.data) ))
                .catch((error) => console.log('connect error /emp/selEmpBfrEntList : ' + error))
        },
        empBfrEntRst: function (res) {
            if(res.statusCode == '200'){
                res.result.list.forEach(itm => {
                    if(itm.hlthExmDt == null) itm.hlthExmDt = ''
                    if(itm.hlthIssDt == null) itm.hlthIssDt = ''
                    if(itm.hlthJdgmDt == null) itm.hlthJdgmDt = ''
                    if(itm.rtrDt == null) itm.rtrDt = ''

                    if(this.vCd42.length > 0){
                        this.vCd42.forEach(cd => {
                            if(itm.jobCd == cd.valcd) itm.jobCd = cd.valcdnm
                        })
                    }
                    if(this.vCd70.length > 0){
                        this.vCd70.forEach(cd => {
                            if(itm.wrkStcd == cd.valcd) itm.wrkStcd = cd.valcdnm
                        })
                    }
                });
                this.rglrChckpList = res.result.list
            }
            else
                console.log('EmpBfrEntList return type missmatch')

            this.isLoad = false
        },
        setSearchText: function (value) {
            this.schField = value
            this.schDummy = this.schDummy + '.'
        },
        customFilter: function (value, search, filter) {
            //value: items, search: search key, filter: array column
            let col0 = filter.name.includes(this.schField)
            
            let col1 = true
            if(this.schChkField.wrkStcd.length != 0){
                this.schChkField.wrkStcd.forEach((stcd, i) => {
                    if(i == 0) col1 = filter.wrkStcd.includes(stcd)
                    else col1 = col1 || filter.wrkStcd.includes(stcd)
                });
            }

            let col2 = true
            if(this.schChkField.jobCd.length != 0){
                this.schChkField.jobCd.forEach((stcd, i) => {
                    if(i == 0) col2 = filter.jobCd.includes(stcd)
                    else col2 = col2 || filter.jobCd.includes(stcd)
                });
            }

            return col0 && col1 && col2;
        },
        filterSearch: function (sch) {
            switch(this.schChkField.target) {
                case 'wrkStcd': this.schChkField.wrkStcd.splice(0)
                    sch.forEach((item) => {
                        this.schChkField.wrkStcd.push(item)
                    });
                    break;
                case 'jobCd': this.schChkField.jobCd.splice(0)
                    sch.forEach((item) => { this.schChkField.jobCd.push(item) });
                    break;
            }
          
            this.schDummy = this.schDummy + '.'
        },
        expandClose: function (index) {
            switch(index) {
                case 0: this.schChkField.target = 'wrkStcd'
                    break;
                case 1: this.schChkField.target = 'jobCd'
                    break;
                default: this.schChkField.target = ''
                    break;
            }
        },
        // 연도 변경 반영
        getYear: function (yyyy){
            this.year = yyyy
            this.rflSltList()
        },
        // 탭 별 해당 리스트 조회
        rflSltList: function () {
            switch (this.tab) {
                case 0:
                    this.getEmpHltChcList()
                    break;
                case 1:
                    this.getEmpBfrEntList()
                    break;
                default:
                    break;
            }
        },
        // 정기검진 모달
        onRglrChckp: function (inObj) {
            let obj = {}
            obj.mmbrPk = inObj.mmbrPk
            obj.empPk = inObj.empPk
            obj.hltChcInfPk = inObj.hltChcInfPk
            obj.name = inObj.name
            obj.jobCd = inObj.jobCd
            obj.rsdntBirth = inObj.rsdntBirth
            obj.gndr = inObj.gndr
            obj.entDt = inObj.entDt
            obj.trgYear = this.year

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '7-03', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },
        // 근골격계 증상 조사 모달
        onMsclsChck: function (obj, tIdx) {
            obj.tabIndex = tIdx

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 1400, prsst: true, typ: '7-04', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },
        // 입사전 건강검진 제출 모달
        onBfrJnnHlthChck: function (inObj) {
            let obj = {}
            obj.mmbrPk = inObj.mmbrPk
            obj.empPk = inObj.empPk
            obj.name = inObj.name
            obj.jobCd = inObj.jobCd
            obj.rsdntBirth = inObj.rsdntBirth
            obj.gndr = inObj.gndr
            obj.entDt = inObj.entDt
            obj.entHlthChdt = inObj.entHlthChdt
            obj.hlthExmDt = inObj.hlthExmDt
            obj.hlthJdgmDt = inObj.hlthJdgmDt
            obj.hlthIssDt = inObj.hlthIssDt

            this.$store.commit('setRootInf', {
                actv: true, mxWdth: 600, prsst: true, typ: '7-05', inObj: obj, outObj: null, runEvt: false,
            });

            this.runAble = '1'
        },
    },

    data: () => ({
        tab: 0,
        year: '',
        isLoad: true,
        loadMsg: '잠시만 기다려주세요.',
        runAble: '0',
        schField: '',
        schDummy: '',
        schChkField: { wrkStcd: [], jobCd: [], target: 'wrkStcd' },
        empHlthHdr: [
            { text: '상태', value: '', align: 'center', class: 'clmsHeaderCommon tableBR tableBT', style: 'width: 80px !important;' },
            { text: '직종', value: '', align: 'center', style: 'width: 170px !important;' },
            { text: '이름', value: '', align: 'center', style: 'width: 130px !important;' },
            { text: '생년월일', value: '', align: 'center', style: 'width: 120px !important;' },
            { text: '성별', value: '', align: 'center', style: 'width: 80px !important;' },
            { text: '입사일', value: '', align: 'center', style: 'width: 120px !important;' },
            { text: '퇴사일', value: '', align: 'center', style: 'width: 120px !important;' },
            { text: '건강검진(연1회)', value: '', align: 'center', style: 'width: 130px !important;' },
            { text: '근골격계 증상검사', value: '', align: 'center', class: 'clmsHeaderCommon tableBT', style: '' },
            { text: '작성일자', value: '', align: 'center', class: 'clmsHeaderCommon tableBR', style: 'width: 120px !important;' },
            { text: '조치일자', value: '', align: 'center', class: 'clmsHeaderCommon', style: 'width: 120px !important;' },
        ],
        rglrChckpHdr: [
            { text: '연번', value: '', align: 'center', class: 'clmsHeaderCommon py-2 tableBR tableBT', style: 'width: 80px !important;' },
            { text: '이름', value: '', align: 'center', style: 'width: 130px !important;' },
            { text: '성별', value: '', align: 'center', style: 'width: 80px !important;' },
            { text: '생년월일', value: '', align: 'center', style: 'width: 120px !important;' },
            { text: '직종', value: '', align: 'center', style: 'width: 170px !important;' },
            { text: '입사일', value: '', align: 'center', style: 'width: 120px !important;' },
            { text: '퇴사일', value: '', align: 'center', style: 'width: 120px !important;' },
            { text: '입사전 건강검진 제출일', value: '', align: 'center', class: 'clmsHeaderCommon tableBT', style: 'width: 300px !important;' },
        ],
        tabItems: [
            { index: 0, tab: '정기검진', class: 'fontOneDotTwoRem px-10', enter: false },
            { index: 1, tab: '입사전 건강검진', class: 'fontOneDotTwoRem px-10', enter: false },
        ],
        exBtn: [
            { index:  0, btnTitle: '상태', btnIcon: 'house', exWidth: 104, exHeigth: 100, expandList: [], },
            { index:  1, btnTitle: '직종', btnIcon: 'business', exWidth: 134, exHeigth: 220, expandList: [], },
        ],
        empHlthList:[],
        rglrChckpList:[],
        vCd42: [],      //직원 직종 코드
        vCd70: [],      //직원 상태 코드
    }),
};
</script>