<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">
        <v-form ref="mdlform" lazy-validation>
            <v-snackbar v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="800"
                class="pa-0" :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{ snackOptions[2] }}</span>
                </div>
            </v-snackbar>
            <div class="d-flex justify-left">
                <span class="text-h4 font-weight-bold">프로그램&nbsp;선택</span>
                <v-spacer></v-spacer>
                <v-icon color="black" x-large @click="onClose">mdi-close</v-icon>
            </div>
            <v-row class="mt-5" no-gutters>
                <v-col cols="6" class="d-flex justify-start">

                </v-col>
                <v-col cols="6" class="d-flex justify-end" v-if="btnShow1 != 2">
                    <span class="d-inline-block">
                        <v-btn class="white--text" height="30" color="blueBtnColor" rounded @click="onAction">
                            <v-icon small>mdi-check</v-icon>
                            선택완료
                        </v-btn>
                    </span>
                </v-col>
            </v-row>
            <div class="d-flex mt-2"
                style="width:744px; border-top:1px solid #dbdbdb !important; border-bottom:1px solid #dbdbdb !important;">
                <div class="greyE01 py-2" style="width:5%;text-align:center; border-right:1px solid #dbdbdb !important;">
                    <v-checkbox class="fmlyHeaderCheck" style="padding:0px 7px;" v-model="allChecker"
                        :readonly="allReadonly" @change="allChk" hide-details v-if="btnShow1 != 2"></v-checkbox>
                </div>
                <div class="greyE01 py-2" style="width:20%;text-align:center; border-right:1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">프로그램 유형</span>
                </div>
                <div class="greyE01 py-2" style="width:20%;text-align:center; border-right:1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">지표구분</span>
                </div>
                <div class="greyE01 py-2" style="width:55%;text-align:center; border-right:1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium" style="text-align:center;">프로그램</span>
                </div>
            </div>
            <div v-if="items.length > 0">
                <div class="d-flex" style="width:744px; border-bottom: 1px solid #dbdbdb !important;"
                    v-for="(list, i) in items" :key="i">
                    <div class="py-2 justify-center"
                        style="width:5%;text-align:center; border-right:1px solid #dbdbdb !important;">
                        <v-checkbox class="fmlyHeaderCheck" style="padding:0px 7px" v-model="list.chkyn" @change="selChk"
                            hide-details :key="forceRender"></v-checkbox>
                    </div>
                    <div class="py-2" style="width:20%;text-align:center; border-right:1px solid #dbdbdb !important;">
                        <span style="width: 80%; ">{{ list.prgTypNm }}</span>
                    </div>
                    <div class="py-2" style="width:20%;text-align:center; border-right:1px solid #dbdbdb !important;">
                        <span class="d-inline-block">
                            <v-chip :class="('ic-level level0' + list.idxCd)" class="" style="width: 50px;">{{ list.idxCdNm }}</v-chip>
                        </span>
                    </div>
                    <div class="py-2" style="width:55%;text-align:center;">
                        <span style="width: 80%;">{{ list.prgNm }}</span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex" style="width:744px; border-bottom: 1px solid #dbdbdb !important;">
                    <div class="py-2" style="width:100%;text-align:center;">
                        <span class="" style="text-align:center;">데이터가 없습니다.</span>
                    </div>
                </div>
            </div>
        </v-form>
    </v-sheet>
</template>

<script>
import { getAllPrgIM, selPrgTypMnglist, insPrgBnfcrgrInf, delPrgBnfcrgrInf } from '../../api/prgrm.js';

export default {
    name: 'PrgrmMultiSelector',

    props: {
        prtWrtDt: { type: String, default: '' },
        prgChkItems : {type:Array, default: new Array()},
        prgNoItems : {type:Array, default: new Array()},
    },

    components: {
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.getAllPrgIM()
        })
    },

    computed: {
        getPrgCd: function () {
            return this.$parent.$parent.$parent.$parent.vCd40
        }
    },

    watch: {
        getPrgCd: function (v) {
            if(v.length > 0)
                this.getAllPrgIM()
        },
    },

    methods: {
        onShow(){               
            this.prgDummyList = Object.assign([], this.items)      

            if(this.prgChkItems != null){
                if(this.prgChkItems.length > 0)
                    this.setReadonlys(this.prgChkItems)
                    
            }

            if(this.prgNoItems != null){
                if(this.prgNoItems.length > 0)
                    this.setNoDisplay(this.prgNoItems)
            }
        },
        getAllPrgIM: function () {
            getAllPrgIM(this.$store.getters.getFcltyNum)
                .then((response) => (this.getAllPrgIMAfter(response.data)))
                .catch((error) => console.log('connect error prgrm/selPrgInfInfo : ' + error))
        },
        getAllPrgIMAfter: function (res) {
            if(res.length > 0){
                res.forEach(itm => {
                    itm.chkyn = false
                    this.$parent.$parent.$parent.$parent.vCd40.forEach(idx => {
                        if(itm.idxCd == idx.valcd)
                            itm.idxCdNm = idx.valcdnm?.slice(0, 2)
                    });
                });

                this.items = res
            }
        },
        onSave(key) {
            if (this.$refs.mdlform.validate()) {
                let obj = {
                    bnfcrgrDesc: this.items[key].bnfcrgrDesc,
                    bnfcrgrNm: this.items[key].bnfcrgrNm,
                    fcltyNum: this.$store.getters.getFcltyNum,
                    userNm: this.$store.getters.getUserNm,
                    prgBnfcrgrInfPk: this.items[key].prgBnfcrgrInfPk,
                    useYn: '1',
                }
                insPrgBnfcrgrInf(obj)
                    .then((response) => (this.insPrgBnfcrgrInfAfter(response.data)))
                    .catch((error) => console.log(error))
            }
        },
        insPrgBnfcrgrInfAfter(res) {

            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onDelete(key) {
            if (this.items[key].prgBnfcrgrInfPk > 0) {
                let obj = {
                    fcltyNum: this.$store.getters.getFcltyNum,
                    prgBnfcrgrInfPk: this.items[key].prgBnfcrgrInfPk,
                    userNm: this.$store.getters.getUserNm,
                }
                delPrgBnfcrgrInf(obj)
                    .then((response) => (this.delPrgBnfcrgrInfAfter(response.data)))
                    .catch((error) => console.log(error))
            } else {
                this.items.splice(key, 1)
            }
        },
        delPrgBnfcrgrInfAfter(res) {
            if (res.statusCode == 200) {
                this.snackControll([true, 2500, res.message, 'info'])
                this.onShow()
                this.$emit('reload', true)

            } else {
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },
        onAction: function () {
            let selected = []

            this.items.forEach(x => {
                if (x.chkyn) {
                    selected.push(x)
                }
            })
            this.$emit('outEmp', selected)
            this.onClose()
        },

        onClose: function () {
            this.allChecker = false
            this.items.forEach(x=>{
                x.chkyn = false
            })
            ++this.forceRender
            this.$emit('isClose', '.')
        },

        expandClose: function () {

            this.$refs.exBtnClose.isClose()

        },

        allChk: function () {
            this.items.forEach(x => {
                if (this.allChecker) {
                    x.chkyn = true
                } else {
                    x.chkyn = false
                }
            })
        },
        selChk: function () {
            let chk = true
            this.items.forEach(x => {
                if (!x.chkyn) {
                    chk = false
                }
            })
            
            this.allChecker = chk
        },
        snackControll: function (options) {
            this.snackOptions = options
        },
        btnShowMethod: function () {
            let pN = 1
            this.btnShow = pN
        },
        btnShowMethod2: function () {
            let pN1 = 2
            this.btnShow1 = pN1
        },
        getPrgTypMng() {
            let fcltyNum = this.$store.getters.getFcltyNum
            this.valClcd = '40'
            this.valClcdnm = '지표구분'
            selPrgTypMnglist(fcltyNum, '40')
                .then((response) => (this.resData(response.data)))
                .catch((error) => console.log(error))
        },
        resData(data) {
            this.items1 = data
        },
        setReadonlys:function(prgInfPks){   
            this.items.forEach((x)=>{
                let bl = prgInfPks.includes(x.prgInfPk)
                if(bl){
                    x.chkyn = true
                } 
            })
            ++this.forceRender
            this.selChk()
        },
        setNoDisplay:function(prgInfPks){
            this.allReadonly = false
            this.prgDummyList.splice(0)

            this.items.forEach(x=>{
                let bl = prgInfPks.includes(x.prgInfPk)
                if(!bl){
                    this.prgDummyList.push(x)
                }  
            })            
        }
    },

    data: () => ({
        items: [],
        items1: [],
        prgDummyList:[],
        wrtDt: '',
        field: '',
        allChecker: false,
        allReadonly: false,
        forceRender: 0,
        exBtn: [
            {
                index: 0,
                btnTitle: '직종',
                btnIcon: 'business',
                exWidth: 134,
                exHeigth: 220,
                expandList: [],
            },
        ],
        snackOptions: [false, 3000, '', 'info'],
        btnShow: 0,
        btnShow1: 0,
    }),
};
</script>
<style>
.empSearchField div div div label {
    margin-top: 0px;
    top: 3px !important;
}

.empSearchField div .v-input__slot {
    padding: 0 14px 0 18px !important;
}
</style>