<template>
    <v-sheet class="ma-0" rounded="lg">
        <v-row class="pa-0" no-gutters>
            <span class="text-h4 font-weight-bold">정기검진 관리</span>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="$parent.$parent.$parent.$parent.mdlOff" x-large>mdi-close</v-icon>
        </v-row>
        <v-row class="pa-0 mt-4 mb-2 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">직원 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">직원명</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span class="mr-2">{{ bscsInf.name }}</span>
                &#40;
                <span v-if="bscsInf.gndr == 'M'">남</span>
                <span v-else-if="bscsInf.gndr == 'F'">여</span>
                &#41;
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">생년월일</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span class="mr-2">{{ $moment(bscsInf.rsdntBirth, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                &#40;
                <span>만 {{ bscsInf.age }}세</span>
                &#41;
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-1" cols="3">
                <span class="font-weight-medium">입사일</span>
            </v-col>
            <v-col class="px-4 py-1" cols="7">
                <span class="mr-2">{{ $moment(bscsInf.entDt, 'YYYYMMDD').format('YYYY.MM.DD') }}</span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0 mt-4 mb-2 posn-rltv" no-gutters>
            <span class="d-inlne-block" style="width: 20px; height: 20px; position: absolute; top: -4px; left: -6px;">
                <v-icon class="pt-1" color="black" size="24">mdi-chevron-right</v-icon>
            </span>
            <span class="fontOneDotTwoRem font-weight-medium ml-6">건강검진 정보</span>
        </v-row>
        <v-divider class="mr-1" color="black"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 txtAlgnMid tableBR py-2" cols="3">
                <span class="font-weight-medium posn-rltv">
                    건강검진일
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="d-flex align-center px-4" cols="9">
                <span class="d-inline-block" style="width: 134px; padding-top: 1px;">
                    <CmmDateComp
                        v-model="hltChcDt"
                        :minDt="$moment(bscsInf.trgYear+'0101', 'YYYYMMDD').format('YYYY-MM-DD')"
                        :maxDt="$moment(bscsInf.trgYear+'1231', 'YYYYMMDD').format('YYYY-MM-DD')"
                        @input="aplctSavBtn">
                    </CmmDateComp>
                </span>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center" cols="3">
                <span class="font-weight-medium posn-rltv">
                    작성자
                    <span class="d-inline-block" style="position: absolute; top: -2px; right: -20px;">
                        <v-icon color="red">mdi-star-four-points-small</v-icon>
                    </span>
                </span>
            </v-col>
            <v-col class="px-4 py-1" cols="9">
                <span class="d-inline-block" style="width: 100px; padding-top: 1px;">
                    <v-text-field
                        v-model="wrtr" @input="aplctSavBtn"
                        class="" height="28" color="blue"
                        hide-details outlined readonly dense>
                    </v-text-field>
                </span>
                <v-btn 
                    class="ml-1 brown lighten-3" style="padding:0px 1px 0px 2px !important;"
                    min-width="30" height="26"  
                    @click="onLclMdl('03', 800)"
                    small>
                    <v-icon size="20" class="white--text">mdi-account-edit</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-row class="pa-0" no-gutters>
            <v-col class="nmlBfClrImp-greyE01 tableBR d-flex align-center justify-center py-1" cols="3">
                <span class="font-weight-medium">자료첨부</span>
                <span class="d-inline-block" style="margin-left: 2px; padding-top: 2px;">
                    <v-file-input
                        class="athFileInput" truncate-length="5"
                        v-model="files" @change="upFile()"
                        prepend-icon="mdi-upload"
                        multiple counter hide-input outlined dense hide-details>
                    </v-file-input>
                </span>
            </v-col>
            <v-col class="px-4 pb-1" style="padding-top: 6px" cols="9">
                <span
                    class="pb-2 pr-2"
                    v-for="(item, j) in fileList" :key="j">
                    <v-chip            
                        class="mt-1" label close outlined
                        @click="athFileDownload(j, '1')" @click:close="delAtchfiles(j)">
                        <v-icon v-if="item.ext=='pdf' || item.ext=='PDF'" color='pink' left>
                            mdi-file-pdf-box
                        </v-icon>
                        <v-icon v-else-if="item.ext=='xlsx' || item.ext=='xls'" color='green' left>
                            mdi-microsoft-excel
                        </v-icon>
                        <v-icon v-else-if="item.ext=='pptx' || item.ext=='ppt'" color='red' left>
                            mdi-file-powerpoint
                        </v-icon>
                        <v-icon v-else-if="item.ext=='doc' || item.ext=='docx'" color='blue' left>
                            mdi-file-word
                        </v-icon>
                        <v-icon v-else color='cyan' left>
                            mdi-file-document
                        </v-icon>
                        <span class="longText">{{ item.orgFileNm }}</span>
                    </v-chip> 
                </span>
                <v-divider v-if="fileList.length > 0 && imgList.length > 0" class="my-2"></v-divider>
                <div>
                    <span class="d-inline-block mx-1"
                        v-for="( item, i ) in imgList" :key="i">
                        <imageCard ref="imageCard" :imgSrc="item.src" :imgWidth="'120'" @isEvent="delImgfiles(i)" @onDown="athFileDownload(i, '2')"></imageCard>
                    </span>
                </div>
            </v-col>
        </v-row>
        <v-divider class="mr-1"></v-divider>
        <v-divider class="white my-2"></v-divider>
        <v-row class="pa-0 justify-center" no-gutters>
            <v-btn
                class="" color="grey006" min-width="110" height="36"
                :disabled="delBtn" @click="dcsnDelBtn">
                <span class="white--text fontOneDotTwoRem">삭제</span>
            </v-btn>
            <v-col cols="1"></v-col>
            <v-btn
                class="" color="blueBtnColor" min-width="110" height="36"
                :disabled="savBtn" @click="dcsnSavBtn">
                <span class="white--text fontOneDotTwoRem">저장</span>
            </v-btn>
        </v-row>
        <v-divider class="white mt-1"></v-divider>

        <!-- 팝업 영억 -->
        <v-dialog
            v-model="lclMdlInf.isOn"
            content-class="round" :max-width="lclMdlInf.maxWidth"
            persistent>
            <EmpSelector
                v-if="lclMdlInf.typ == '03'"
                @isClose="rstLclMdlInf" @outEmp="setSltEmp"
                :prtWrtDt="$moment().format('YYYY-MM-DD')">
            </EmpSelector>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { remoteURL } from '@/api/baseAxios';
import { selHltChcInf, insHltChcInf, delHltChcInf } from '../../api/bnftrcrd.js';
import { tmpFileUpload, selAtchdFileList, delAtchdFile, fileDownload } from '../../api/index.js';

import CmmDateComp from '../commons/CmmDateComp.vue';
import imageCard from '../commons/imageCard.vue';
import EmpSelector from '../commons/EmpSelector.vue';

export default {
    name: 'HlthChckWrt',

    components: {
        CmmDateComp,
        imageCard,
        EmpSelector,
    },

    props : {
        prtCd42: { type: Array, default: () => { return [] } },     // 담당직종
        prtCd70: { type: Array, default: () => { return [] } },     // 재직현황
    },

    created: function(){

    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getRootInObj != null)
                this.aplctInf(this.$store.getters.getRootInObj)
            else
                this.clrField()
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
        getSubActv: function () {
            return this.$store.getters.getSubActv
        },
    },

    watch: {
        getSubEvt: function (v) {
            if(v && this.runAble == '2'){
                if(this.bscsInf.hltChcInfPk > -1)
                    this.rmHltChcInf(this.bscsInf.hltChcInfPk)

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.runAble = '0'
            }
        },
    },

    methods: {
        // 건강검진 단건 조회
        getHltChcInf: function(pk){
            this.delBtn = true

            selHltChcInf(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.hltChcRst(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selHltChcInf : ' + error))
        },

        hltChcRst: function(res){
            if(res.result != null){
                this.bscsInf.hltChcInfPk = res.result.hltChcInfPk
                this.hltChcDt = this.$moment(res.result.hltChcDt, 'YYYYMMDD').format('YYYY-MM-DD')
                this.wrtr = res.result.wrtr
                this.delBtn = false
            }

            this.aplctSavBtn()
        },

        // 건강검진 정보 저장
        setHltChcInf: function(obj){
            this.savBtn = true

            insHltChcInf(obj)
                .then((response) => ( this.insHltChcRst(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/insHltChcInf : ' + error))
        },

        insHltChcRst: function(res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.tmr = 2500
                obj.type = 'info'
                this.bscsInf.hltChcInfPk = res.hltChcInfPk
                this.getAtchdFileList(this.bscsInf.hltChcInfPk)
            }

            this.$store.commit('setWrnnInf', obj)
            this.savBtn = false
        },
        
        // 건강검진 정보 삭제
        rmHltChcInf: function(pk){
            this.savBtn = true

            delHltChcInf(this.$store.getters.getFcltyNum, pk, this.$store.getters.getUserNm)
                .then((response) => ( this.rmHltChcRst(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/delHltChcInf : ' + error))
        },

        rmHltChcRst: function(res){
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.tmr = 2500
                obj.type = 'info'
                this.$parent.$parent.$parent.$parent.mdlOff()
            }

            this.$store.commit('setWrnnInf', obj)
            this.savBtn = false
        },

        // 첨부파일 목록 조회
        getAtchdFileList: function (pk){
            selAtchdFileList(this.$store.getters.getFcltyNum, 36, pk)
                .then((response) => ( this.getAtchdFileListAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/selAtchdFileList : ' + error))
        },

        getAtchdFileListAfter: function(res){
            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)

            let fList = res.filter(v => v.fileKndCd == '1')
            let iList = res.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgView?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })
        },

        // 첨부파일 업로드
        athFileUpload: function (formData) {
            tmpFileUpload(formData)
                .then((response) => ( this.upFileAfter(response.data) ))
                .catch((error) => console.log('upload error /opr/tmpFileUpload : ' + error))
        },

        upFileAfter: function (res) {
            let fList = res.files.filter(v => v.fileKndCd == '1')
            let iList = res.files.filter(v => v.fileKndCd == '2')

            fList.forEach( items => {
                let fileNm = items.fileNm.split(".")
                items.ext = fileNm[1]  
                this.fileList.push(items)
            })
     
            iList.forEach( items => {
                items.src = remoteURL + 'opr/imgPrview?fileNm='+items.fileNm+'&filePth='+items.filePth+'&fileKndCd='+items.fileKndCd
                this.imgList.push(items)
            })

            this.files = null
        },

        // 첨부파일 삭제
        removeAthFile: function (pk) {
            delAtchdFile(this.$store.getters.getFcltyNum, pk)
                .then((response) => ( this.delAtchdFileAfter(response.data) ))
                .catch((error) => console.log('connect error /opr/delAtchdFile : ' + error))
        },

        delAtchdFileAfter: function (res) {
            let obj = {}
            obj.actv = true                             // 경고 노출여부
            obj.tmr = 3500                              // 경고 노출시간 (-1: 무제한)
            obj.type = 'warning'                        // 경고 유형 (success, info, warning, error)
            obj.title = res.message                     // 경고 타이틀
            obj.cntnt = []                              // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.tmr = 2500
                obj.type = 'info'

                this.getAtchdFileList(this.bscsInf.hltChcInfPk)
            }

            this.$store.commit('setWrnnInf', obj)
        },

        // 첨부파일 다운로드
        athFileDownload: function (index, mode) {
            if(mode == '1'){
                fileDownload(this.fileList[index])
                    .then((response) => ( this.athFileRst(response) ))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error))
            }
            else if(mode == '2'){
                fileDownload(this.imgList[index])
                    .then((response) => ( this.athFileRst(response) ))
                    .catch((error) => console.log('connect error /cmm/fileDownload : ' + error)) 
            }
        },

        athFileRst: function (res) {
            let contentDisposition = decodeURI(res.headers['content-disposition'])

            let link = document.createElement('a')
            let url = window.URL.createObjectURL(new Blob([res.data]))
            let fileName = 'unknown'

            if (contentDisposition) {
                const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
                if (fileNameMatch)
                    [ , fileName ] = fileNameMatch.split('=')
            }

            link.href = url

            fileName = fileName.replaceAll('"', '')
            link.setAttribute('download', `${fileName}`)
            link.style.cssText = 'display:none'

            document.body.appendChild(link)

            link.click()
            link.remove()
        },

        // 기본정보 적용
        aplctInf: function (obj) {
            this.bscsInf.mmbrPk = obj.mmbrPk
            this.bscsInf.empPk = obj.empPk
            this.bscsInf.hltChcInfPk = obj.hltChcInfPk
            this.bscsInf.name = obj.name
            this.bscsInf.jobCd = obj.jobCd
            this.bscsInf.rsdntBirth = obj.rsdntBirth
            this.bscsInf.gndr = obj.gndr
            this.bscsInf.entDt = this.$moment(obj.entDt, 'YYYYMMDD').format('YYYY-MM-DD')
            this.bscsInf.age = this.getAge(obj.rsdntBirth)
            this.bscsInf.trgYear = obj.trgYear

            this.getHltChcInf(obj.hltChcInfPk)
            this.getAtchdFileList(obj.hltChcInfPk)
        },

        // 저장버튼 활성 여부 체크
        aplctSavBtn: function () {
            this.savBtn = true

            if(/^(19[0-9][0-9]|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(this.hltChcDt) == false)
                return 0
            if(this.wrtr == '')
                return 0

            this.savBtn = false
        },

        // 저장버튼 분기
        dcsnSavBtn: function () {
            let obj = {}
            obj.actv = true                                     // 경고 노출여부
            obj.tmr = 2500                                      // 경고 노출시간 (-1: 무제한)
            obj.type = 'info'                                   // 경고 유형 (success, info, warning, error)
            obj.title = '건강검진일을 입사일 이후로 작성해주세요'     // 경고 타이틀
            obj.cntnt = []                                      // 경고 내용 (arr만 출력됨!)

            if(this.$moment(this.bscsInf.entDt, 'YYYYMMDD').isAfter(this.$moment(this.hltChcDt))){
                this.$store.commit('setWrnnInf', obj)
                return 0
            }

            let inObj = {}
            inObj.mmbrPk = this.bscsInf.mmbrPk
            inObj.hltChcInfPk = this.bscsInf.hltChcInfPk
            inObj.hltChcDt = this.$moment(this.hltChcDt, 'YYYY-MM-DD').format('YYYYMMDD')
            inObj.wrtr = this.wrtr
            inObj.stdYyyy = this.bscsInf.trgYear
            inObj.files = []

            this.fileList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    inObj.files.push(items)
                }
            })
            this.imgList.forEach( items => {
                if(items.atchdFilePk == undefined){
                    inObj.files.push(items)
                }
            })
            
           this.setHltChcInf(inObj)
        },

        // 삭제버튼 분기
        dcsnDelBtn: function () {
            this.runAble = '2'

            let obj = {}
            obj.icon = 'mdi-alert'
            obj.iconClr = 'red'
            obj.title = this.bscsInf.name + ' ( ' + this.$moment(this.bscsInf.rsdntBirth, 'YYYYMMDD').format('YYYY.MM.DD') + ' )'
            obj.cntnt = ['정기검진 정보를 삭제하시겠습니까?']
            obj.cnclTxt = '아니오'
            obj.cnfrmTxt = '예, 삭제하겠습니다.'

            this.$store.commit('setSubInf', {
                actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
            });
        },

        // 첨부파일 업로드 구분
        upFile: function () {
            let formData = new FormData()

            if(this.files != null){
                this.files.forEach(element => {
                    formData.append("files", element)
                })

                if(this.files.length > 0){
                    formData.append("files", this.files)
                    this.athFileUpload(formData)
                }
            }           
        },

        // 첨부 이미지 삭제
        delImgfiles: function (idx) {
            if(this.imgList[idx].atchdFilePk != undefined){
                this.removeAthFile(this.imgList[idx].atchdFilePk)
            }
            else {
                this.imgList.splice(idx, 1)
            }
        },

        // 첨부 문서 삭제
        delAtchfiles: function (idx) {
            if(this.fileList[idx].atchdFilePk != undefined){
                this.removeAthFile(this.fileList[idx].atchdFilePk)
            }
            else {
                this.fileList.splice(idx, 1)
            }
        },

        // 나이 계산
        getAge: function (bthDay) {
            let birthDay = this.$moment(bthDay, 'YYYYMMDD')
            return this.$moment().diff(birthDay.format('YYYYMMDD'), 'years')
        },

        // 직원 선택 결과 적용
        setSltEmp: function (obj) {
            this.wrtr = obj[0].name
            this.wrtrMmbrPk = obj[0].mmbrPk

            this.aplctSavBtn()
        },

        // 초기화
        clrField: function () {
            this.files = null
            this.fileList.splice(0)
            this.imgList.splice(0)
            this.hltChcDt = ''
            this.wrtr = ''
        },

        // 통합 팝업 오픈
        onLclMdl: function (txt, wdt) {
            this.lclMdlInf.typ = txt
            this.lclMdlInf.maxWidth = wdt
            this.lclMdlInf.isOn = true
        },

        // 통합 팝업 초기화
        rstLclMdlInf: function () {
            this.lclMdlInf.typ = ''
            this.lclMdlInf.isOn = false
        },
    },

    data: () => ({
        savBtn: true,
        delBtn: true,
        runAble: '0',
        hltChcDt: '',
        wrtr: '',
        wrtrMmbrPk: -1,
        files: null,
        fileList: [],
        imgList: [],
        bscsInf: {
            mmbrPk: -1, hltChcInfPk: -1, empPk: -1, name: '', jobCd: '', gndr: '', age: '', rsdntBirth: '', entDt: '',
            trgYear: ''
        },
        lclMdlInf: { isOn: false, maxWidth: 800, typ: '' },             // 통합 팝업 제어 (03: 직원 선택(단일, 스케쥴))
    })
}
</script>