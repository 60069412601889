<template>
    <v-sheet class="rounded-lg rounded-l-0 pa-3" color="" min-width="1260" max-width="1560" height="835">
        <div style="position:relative;">
            <v-snackbar
                v-model="snackOptions[0]" :timeout="snackOptions[1]" :value="true" min-width="200" max-width="500" class="pa-0"
                :color="snackOptions[3]" rounded="pill" transition="slide-y-transition" absolute top>
                <div style="text-align: center;">
                    <span class="text-h6 white--text font-weight-bold ml-4 mr-2">{{snackOptions[2]}}</span>
                </div>
            </v-snackbar>
        </div> 
        <v-row class="ma-0 pa-0" no-gutters>
            <v-col class="text-h4 d-flex align-center" cols="5">
                <!-- <v-icon color="topTitleColor">mdi-chevron-down</v-icon> -->
                <span class="ml-2 hamletFont text-h5 topTitleColor--text font-weight-black">7-4. 교육&nbsp;및&nbsp;훈련일지</span>
            </v-col>
            <v-col class="d-flex pr-2" cols="7">
                <v-spacer></v-spacer>
                <!-- btn area -->
                <span v-for="(list, i) in filledBtn" :key="i">
                    <v-btn v-if="list.type == 'action' && list.visible" @click="onClickBtn(i)"
                        :class="list.class" samll height="30" :color="list.color" :disabled="list.disabled" rounded>
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                    <v-dialog v-else-if="list.type == 'dialogue' && list.visible"
                        v-model="dialog[i]" :max-width="list.width" content-class="round">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" :disabled="list.disabled"
                                    :class="list.class" samll height="30" :color="list.color" rounded>
                                    <v-icon small>{{list.icon}}</v-icon>
                                    <span style="font-size: 1rem;">{{list.text}}</span>
                                </v-btn>
                            </template>
                            <MsclnExpnsIns v-if="list.text == '기타비용 입력'"></MsclnExpnsIns>
                            <div v-else>개발중</div>
                    </v-dialog>
                </span>
                <span class="d-inline" v-for="(list, j) in outlinedBtn" :key="filledBtn.length+j">                   
                    <v-btn :disabled="list.disabled"
                        :class="list.class" samll height="30" :color="list.color" rounded outlined @click ="onPrtBtn()">
                        <v-icon small>{{list.icon}}</v-icon>
                        <span style="font-size: 1rem;">{{list.text}}</span>
                    </v-btn>
                </span>
            </v-col>
        </v-row>
        <v-row class="mt-5" no-gutters>
            <v-col cols="12">
                <expandYearOnlyPicker @nowYear = "getYear"></expandYearOnlyPicker>
            </v-col>
        </v-row>
        <v-row class="mt-4" no-gutters>
            <v-col cols="12">
                <v-tabs
                    v-model="tab"  class="tab-dft-Theme" active-class="black"
                    center-active hide-slider>
                    <v-tab v-for="item in tabItems" :key="item.tab" :class="item.class" :disabled="item.enter">{{item.tab}}</v-tab>
                </v-tabs>
                <v-divider class="bodyColor mb-2" style="padding:1px 0px;"></v-divider>
                <v-tabs-items v-model="tab" style="height:670px">            
                    <v-tab-item eager transition="fade-transition">
                        <edctnJrnlEmpRprt 
                            ref="edctnJrnlEmpRprt"
                            @onAtnd="onDialog"
                            @onWrtr="onDialog"
                            @edctnJrnlTrigger="edctnJrnlTrigger" @edctnJrnlMsg="edctnJrnlMsg"
                        ></edctnJrnlEmpRprt>
                    </v-tab-item>
                    <v-tab-item eager transition="fade-transition">
                        <edctnJrnlSilvCareRprt 
                            ref="edctnJrnlSilvCareRprt" 
                            @onAtnd="onDialog"
                            @onWrtr="onDialog"
                            @edctnJrnlTrigger="edctnJrnlTrigger" @edctnJrnlMsg="edctnJrnlMsg"></edctnJrnlSilvCareRprt>
                    </v-tab-item>     
                    <v-tab-item eager transition="fade-transition">
                        <edctnJrnlDssRes 
                            ref="edctnJrnlDssRes" 
                            @onAtnd="onDialog"
                            @onWrtr="onDialog"
                            @edctnJrnlTrigger="edctnJrnlTrigger" @edctnJrnlMsg="edctnJrnlMsg"></edctnJrnlDssRes>
                    </v-tab-item>       
                    <!-- <v-tab-item eager transition="fade-transition">
                        <edctnJrnlInfrm 
                            ref="edctnJrnlInfrm" @edctnJrnlTrigger="edctnJrnlTrigger" @edctnJrnlMsg="edctnJrnlMsg"></edctnJrnlInfrm>                        
                    </v-tab-item>        -->
                    <v-tab-item eager transition="fade-transition">
                        <empwrMmt 
                            ref="empwrMmt"
                            @onAtnd="onDialog"
                            @onWrtr="onDialog"
                        ></empwrMmt>                        
                    </v-tab-item> 
                    <v-tab-item eager transition="fade-transition">
                        <empStresDsltn 
                            ref="empStresDsltn"
                            @onAtnd="onDialog"
                            @onWrtr="onDialog"
                        ></empStresDsltn>                        
                    </v-tab-item> 
                </v-tabs-items>
            </v-col>
        </v-row>
        <div class="ma-0 pa-0">
            <v-dialog v-model="dialog[0]" eager content-class="round" persistent max-width="800">
                <EmpSelector @isClose="isDialog(0)" @outEmp="getEmpInfo" :prtWrtDt = "prtWrtDt"></EmpSelector>
            </v-dialog>  
        </div>
        <div class="pa-0 ma-0">
            <v-dialog v-model="dialog[1]" max-width="700" content-class="round" persistent eager>           
                <IntgrSlctn
                    :mdlTtl="'참석자 선택'" :tabInfList="['1','2']" :fixList="exclsList" mode="2"
                    :cd41="vCd41"
                    :cd42="vCd42"
                    :cd65="vCd65"
                    :prtWrtDt = "prtWrtDt"
                    :rtrInc1   = "rtrInc1"
                    :rtrInc2   = "rtrInc2"
                    @intgrResList="getAplyList" @onClose="isDialog(1)">
                </IntgrSlctn>
            </v-dialog>
        </div>
    </v-sheet>
</template>

<script>
import expandYearOnlyPicker from '../../components/commons/expandYearOnlyPicker.vue';
import edctnJrnlEmpRprt from '../../components/emp/EdctnJrnlEmpRprt.vue';
import edctnJrnlSilvCareRprt from '../../components/emp/EdctnJrnlSilvCareRprt.vue';
import edctnJrnlDssRes from '../../components/emp/EdctnJrnlDssRes.vue';
import empwrMmt from '../../components/emp/EmpwrMmt.vue';
import empStresDsltn from '../../components/emp/EmpStresDsltn.vue';
import IntgrSlctn from '../../components/commons/IntgOhrVer.vue';
import EmpSelector from '../../components/commons/EmpSelector.vue';

import { getMultiStcd } from '../../api/index.js';

export default {
    name: 'EdctnJrnl',
        
    components: {
        expandYearOnlyPicker,
        edctnJrnlEmpRprt,
        edctnJrnlSilvCareRprt,
        edctnJrnlDssRes,
        empwrMmt,
        empStresDsltn,
        IntgrSlctn,
        EmpSelector,
    },

    created: function(){
        
    },

    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            this.prtWrtDt = this.$moment().format('YYYYMMDD')
            this.getCode(['41', '42', '65', '74'])
        })
    },

    computed: {

    },

    watch:{ 
        'tab':function(value){
            this.outlinedBtn[0].disabled = true

            if(value == 4) {
                this.filledBtn[2].disabled = true
                this.filledBtn[3].disabled = true
                this.outlinedBtn[0].disabled = false
            }
        }
    },
        
    methods: {
        onClickBtn: function (key) {
            switch (key) {
                case 0:
                    this.onReset()
                    break;      
                case 1:
                    this.onSave()
                    break; 
                case 2:                    
                    this.onSend()
                    break; 
                case 3:
                    this.onDel()
                    break;                   
                default:
                    break;
            }
        },   
        onPrtBtn:function(){
            let bzPk = 0
            let prtCd = ""

            switch (this.tab) {
                case 0:
                    bzPk = this.$refs.edctnJrnlEmpRprt.sltPk
                    prtCd = "79"
                    break;
                case 1:
                    bzPk = this.$refs.edctnJrnlSilvCareRprt.sltPk
                    prtCd = "80"
                    break;
                case 2:
                    bzPk = this.$refs.edctnJrnlDssRes.sltPk
                    prtCd = "81"
                    break;
                case 3:
                    bzPk = this.$refs.empwrMmt.sltEduRecPk
                    prtCd = "82"
                    break;
                default:
                    break;
            }

            if(bzPk > 0 ){
                this.prtThrowObj.list[0].bzPk.splice(0)                
                this.prtThrowObj.list[0].bzClcd.splice(0)
                this.prtThrowObj.inqYmdStart = ""
                this.prtThrowObj.inqYmdEnd = ""
                this.prtThrowObj.fcltyNum = this.$store.getters.getFcltyNum
                this.prtThrowObj.bnMmbrPk = ''
                this.prtThrowObj.prtCd = prtCd

                this.prtThrowObj.list[0].bzPk.push(bzPk)

                this.$store.commit('setRootInf', {
                    actv: true, mxWdth: 1000, prsst: true, typ: '91-01', inObj: this.prtThrowObj, outObj: null, runEvt: true,
                });
            }
        },
        onReset:function(){
            switch (this.tab) {
                case 0:
                    this.$refs.edctnJrnlEmpRprt.frmReset()
                    break;
                case 1:
                    this.$refs.edctnJrnlSilvCareRprt.frmReset()
                    break;
                case 2:
                    this.$refs.edctnJrnlDssRes.frmReset()
                    break;
                case 3:
                    this.$refs.empwrMmt.frmReset()
                    break;
                case 4:
                    this.$refs.empStresDsltn.frmReset()
                    break;
                default:
                    break;
            }
        },
        onSave:function(){
            switch (this.tab) {
                case 0:
                    this.$refs.edctnJrnlEmpRprt.onSave()
                    break;
                case 1:
                    this.$refs.edctnJrnlSilvCareRprt.onSave()
                    break;
                case 2:
                    this.$refs.edctnJrnlDssRes.onSave()
                    break;
                case 3:
                    this.$refs.empwrMmt.onSave()
                    break;
                case 4:
                    this.$refs.empStresDsltn.onSave()
                    break;
                default:
                    break;
            }
        },
        onSend:function(){
            switch (this.tab) {
                case 0:
                    this.$refs.edctnJrnlEmpRprt.onSend()
                    break;
                case 1:
                    this.$refs.edctnJrnlSilvCareRprt.onSend()
                    break;
                case 2:
                    this.$refs.edctnJrnlDssRes.onSend()
                    break;
                case 3:
                    this.$refs.empwrMmt.onSend()
                    break;
                case 4:
                    
                    break;
                default:
                    break;
            }
        },
        onDel:function(){
            switch (this.tab) {
                case 0:
                    this.$refs.edctnJrnlEmpRprt.onDelete()
                    break;
                case 1:
                    this.$refs.edctnJrnlSilvCareRprt.onDelete()
                    break;
                case 2:
                    this.$refs.edctnJrnlDssRes.onDelete()
                    break;
                case 3:
                    this.$refs.empwrMmt.onDelete()
                    break;
                case 4:
                    this.$refs.empStresDsltn.onDelete()
                    break;
                default:
                    break;
            }
        },

        onDialog:function(ymd, key, arr){
            this.prtWrtDt = ymd

            if(key == 1){
                this.exclsList.splice(0)
               
                if(typeof arr == 'object') {
                    arr.forEach(e => {
                        let obj = {
                            name:e.name,
                            mmbrpk:e.mmbrpk,
                            disChekr:true,
                            checkr:e.checkr
                        }
        
                        this.exclsList.push(obj)
                    });
                }
            }

            this.dialog.splice(key, 1, true)
        },

        isDialog:function(key){
            this.dialog.splice(key, 1, false)
        },

        getCode: function (array) {
            getMultiStcd(array)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res) {
            this.vCd41 = res.vCd41
            this.vCd42 = res.vCd42
            this.vCd65 = res.vCd65
            this.vCd74 = res.vCd74
        },


        getYear: function (yyyy){
            this.year = yyyy
                this.$refs.edctnJrnlEmpRprt.onLoad(yyyy)
                this.$refs.edctnJrnlSilvCareRprt.onLoad(yyyy)
                this.$refs.edctnJrnlDssRes.onLoad(yyyy)
            // switch (this.tab) {
            //     case 0:
            //         break; 
            //     case 1:
            //         break;             
            //     default:
            //         break;
            // }
        },             
        snackControll: function (options) {
            this.snackOptions = options
        },
        edctnJrnlMsg:function(msg){                                                        
            this.snackControll([true, 2500, msg, 'warning'])
        },
        edctnJrnlTrigger:function(res){
            if(res.statusCode == 200){                                                
                this.snackControll([true, 2500, res.message, 'info'])
            }
            else{
                this.snackControll([true, 5000, res.message, 'error'])
            }
        },

        getEmpInfo: function (obj) {                     
            switch (this.tab) {
                case 0:
                    this.$refs.edctnJrnlEmpRprt.setEmpInfo(obj)
                    break;
                case 1:
                    this.$refs.edctnJrnlSilvCareRprt.setEmpInfo(obj)
                    break;
                case 2:
                    this.$refs.edctnJrnlDssRes.setEmpInfo(obj)
                    break;
                case 3:
                    this.$refs.empwrMmt.setEmpInfo(obj)
                    break;
                case 4:
                    this.$refs.empStresDsltn.setEmpInfo(obj)
                    break;
                default:
                    break;
            }
        },

        getAplyList: function (arr){
            switch (this.tab) {
                case 0:
                    this.$refs.edctnJrnlEmpRprt.setAplyList(arr)
                    break;
                case 1:
                    this.$refs.edctnJrnlSilvCareRprt.setAplyList(arr)
                    break;
                case 2:
                    this.$refs.edctnJrnlDssRes.setAplyList(arr)
                    break;
                case 3:
                    this.$refs.empwrMmt.setAplyList(arr)
                    break;
                case 4:
                    this.$refs.empStresDsltn.setAplyList(arr)
                    break;
                default:
                    break;
            }                              
        },
    },
    
    data: () => ({
        tab         : 0,
        year        : '',
        prtWrtDt    : '',
        rtrInc1     : false,
        rtrInc2     : true,
        vCd41       : [],
        vCd42       : [],
        vCd65       : [],
        vCd74       : [],
        dialog      : [false],
        exclsList   : [],
        filledBtn   : [
            { icon: 'mdi-pencil-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '신규', type: 'action', width:'800px', visible: true, disabled: false, },                        
            { icon: 'mdi-content-save-outline', class: 'ml-1 white--text', color:'blueBtnColor', text: '저장', type: 'action', width:'800px', visible: true, disabled: false, },
            { icon: 'mdi-arrow-expand-right', class: 'ml-1 white--text', color:'blueBtnColor', text: '서명 안내 발송', type: 'action', width:'800px', visible: true, disabled: false, },
            { icon: 'mdi-trash-can-outline', class: 'ml-1 white--text', color:'grey006', text: '삭제', type: 'action', width:'500px', visible: true, disabled: false, },
        ],
        tabItems: [
            { index: 0, tab: '직원교육', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 1, tab: '노인인권 및 학대 예방교육', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 2, tab: '재난상황대응훈련', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 3, tab: '역량강화', class: 'fontOneDotTwoRem px-15', enter:false},
            { index: 4, tab: '직무스트레스해소', class: 'fontOneDotTwoRem px-15', enter:false},
            // { index: 3, tab: '교육정보', sclass: 'fontOneDotTwoRem px-15', enter:false},
        ],   
        outlinedBtn: [
            { icon: 'mdi-printer-outline', class: 'ml-1 font-weight-bold', color:'grey006', text: '출력', width:'1000px', disabled: true, },
        ],
        snackOptions: [false, 3000, '', 'info'],
        prtThrowObj: {
            fcltyNum: 0, bnMmbrPk: 0, prtClcd: '1', inqClcd: 2, prtCd: '29', inqYmdStart: '', inqYmdEnd: '',
            rptTyp: '1', mode: '2', list: [ { bzClcd: [], bzPk: [] } ],
        },
    }),
};
</script>