<!-- 공통 다이얼로그 호출 유형 정리 -->
<!--
    actv: 활성여부(true, false)
    mxWdth: 가로 크기(최소 500)
    prsst: 다이얼로그 지속여부
    typ: 호출 컴포넌트 유형(대메뉴번호-다이얼로그순번)
    inObj: 내부로 전달할 데이터
    outObj: 외부로 전달할 데이터
    runEvt: 이벤트 발생 여부(true, false)
-->
<!-- 필요 공통코드 유형 정리 -->
<!--
    4: 프로그램 - (3, 38, 40)
-->
<template>
    <v-dialog
        content-class="round"
        :value="$store.getters.getRootActv"
        :max-width="$store.getters.getRootMxWdth"
        :persistent="$store.getters.getRootPrsst"
        :scrollable="false" :transition="false"
        @click:outside="outSideEvt">
        <v-card class="pa-7">
            <div class="overflow-hidden rounded" style="max-height: 740px;">
                <CopayChngMdl
                    v-if="$store.getters.getRootTyp == '1-01'"
                    :prtCd63="vCd63">
                </CopayChngMdl>
                <AcknwChngMdl
                    v-else-if="$store.getters.getRootTyp == '1-02'"
                    :prtCd62="vCd62">
                </AcknwChngMdl>
                <GrantsChngMdl
                    v-else-if="$store.getters.getRootTyp == '1-03'">
                </GrantsChngMdl>
                <BscInfSavMdl
                    v-else-if="$store.getters.getRootTyp == '1-04'">
                </BscInfSavMdl>
                <LvnChngMdl
                    v-else-if="$store.getters.getRootTyp == '1-05'">
                </LvnChngMdl>
                <FmlyInfSavMdl
                    v-else-if="$store.getters.getRootTyp == '1-06'"
                    :prtCd64="vCd64">
                </FmlyInfSavMdl>
                <BscInfAddMdl
                    v-else-if="$store.getters.getRootTyp == '1-07'"
                    :prtCd62="vCd62" :prtCd63="vCd63" :prtCd64="vCd64">
                </BscInfAddMdl>
                <BscEntSavMdl
                    v-else-if="$store.getters.getRootTyp == '1-08'">
                </BscEntSavMdl>
                <CntrcSavMdl
                    v-else-if="$store.getters.getRootTyp == '1-09'"
                    :prtCd62="vCd62" :prtCd63="vCd63" :prtCd114="vCd114"
                    :prtYearPeriod="yearPeriod">
                </CntrcSavMdl>
                <BscReEntSavMdl
                    v-else-if="$store.getters.getRootTyp == '1-10'">
                </BscReEntSavMdl>
                <CntrcAddMdl
                    v-else-if="$store.getters.getRootTyp == '1-11'"
                    :prtCd62="vCd62" :prtCd63="vCd63" :prtCd114="vCd114"
                    :prtYearPeriod="yearPeriod">
                </CntrcAddMdl>
                <AgrSavMdl
                    v-else-if="$store.getters.getRootTyp == '1-12'">
                </AgrSavMdl>
                <FallRiskrSavMdl
                    v-else-if="$store.getters.getRootTyp == '1-13'">
                </FallRiskrSavMdl>
                <CntrcDtSavMdl
                    v-else-if="$store.getters.getRootTyp == '1-14'">
                </CntrcDtSavMdl>
                <PrgrmRec
                    v-else-if="$store.getters.getRootTyp == '4-01'"
                    :prtCd3="vCd3" :prtCd38="vCd38" :prtCd40="vCd40">
                </PrgrmRec>
                <EmpRtrDtChngMdl
                    v-else-if="$store.getters.getRootTyp == '7-01'">
                </EmpRtrDtChngMdl>
                <EmpJobChngMdl
                    v-else-if="$store.getters.getRootTyp == '7-02'">
                </EmpJobChngMdl>
                <HlthChckWrt
                    v-else-if="$store.getters.getRootTyp == '7-03'"
                    :prtCd42="vCd42" :prtCd70="vCd70">
                </HlthChckWrt>
                <MsclsSystmChkWrk
                    v-else-if="$store.getters.getRootTyp == '7-04'">
                </MsclsSystmChkWrk>
                <BfrJnnHlthChck
                    v-else-if="$store.getters.getRootTyp == '7-05'"
                    :prtCd42="vCd42" :prtCd70="vCd70">
                </BfrJnnHlthChck>
                <UseSvcPymDtlChngMdl
                    v-else-if="$store.getters.getRootTyp == '10-01'"
                    :prtCd64="vCd64">
                </UseSvcPymDtlChngMdl>
                <ReportMgmt
                    v-else-if="$store.getters.getRootTyp == '91-01'"
                    :cd41="vCd41" :cd42="vCd42" :cd65="vCd65"
                    :bzClCd="vCd61" :inqCd="vCd81"
                    @madalState="menu.splice(0, 1, false)">
                </ReportMgmt>
                <v-row v-else class="pa-0" no-gutters>
                    <span class="text-h4 font-weight-bold">잘못된&nbsp;요청&nbsp;입니다.</span>
                    <v-spacer></v-spacer>
                    <v-icon color="black" @click="mdlOff" x-large>mdi-close</v-icon>
                </v-row>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { getMultiStcd } from '../../api/index.js';

import CopayChngMdl from '../bnfcr/infMng/CopayChngMdl.vue';        // 수급자 본인부담률 변경 팝업 (typ: '1-01')
import AcknwChngMdl from '../bnfcr/infMng/AcknwChngMdl.vue';        // 수급자 인정등급 변경 팝업 (typ: '1-02')
import GrantsChngMdl from '../bnfcr/infMng/GrantsChngMdl.vue';      // 수급자 보조금 변경 팝업 (typ: '1-03')
import BscInfSavMdl from '../bnfcr/infMng/BscInfSavMdl.vue';        // 수급자 기본정보 변경 팝업 (typ: '1-04')
import LvnChngMdl from '../bnfcr/infMng/LvnChngMdl.vue';            // 수급자 생활실 변경 팝업 (typ: '1-05')
import FmlyInfSavMdl from '../bnfcr/infMng/FmlyInfSavMdl.vue';      // 보호자 정보 변경 팝업 (typ: '1-06')
import BscInfAddMdl from '../bnfcr/infMng/BscInfAddMdl.vue';        // 수급자 신규 등록 팝업 (typ: '1-07')
import BscEntSavMdl from '../bnfcr/infMng/BscEntSavMdl.vue';        // 수급자 입소일 변경 팝업 (typ: '1-08')
import CntrcSavMdl from '../bnfcr/infMng/CntrcSavMdl.vue';          // 계약정보 변경 팝업 (typ: '1-09')
import BscReEntSavMdl from '../bnfcr/infMng/BscReEntSavMdl.vue';    // 재입소 팝업 (typ: '1-10')
import CntrcAddMdl from '../bnfcr/infMng/CntrcAddMdl.vue';          // 신규계약 등록 팝업 (typ: '1-11')
import AgrSavMdl from '../bnfcr/infMng/AgrSavMdl.vue';              // 동의 변경 팝업 (typ: '1-12')
import FallRiskrSavMdl from '../bnfcr/infMng/FallRiskrSavMdl.vue';  // 낙상위험 변경 팝업 (typ: '1-13')
import CntrcDtSavMdl from '../bnfcr/infMng/CntrcDtSavMdl.vue';      // 계약정보 기간 변경 팝업 (typ: '1-14')
import PrgrmRec from '../prgrm/PrgrmRec.vue';                       // 프로그램 운영기록 상세 (typ: '4-01')
import EmpRtrDtChngMdl from '../emp/EmpRtrDtChngMdl.vue';           // 직원 퇴사일 변경 팝업 (typ: '7-01')
import EmpJobChngMdl from '../emp/EmpJobChngMdl.vue';               // 직원 직종 변경 팝업 (typ: '7-02')
import HlthChckWrt from '../emp/HlthChckWrt.vue';                   // 직원 정기검진 팝업 (typ: '7-03')
import MsclsSystmChkWrk from '../emp/MsclsSystmChkWrk.vue';         // 직원 근골격계 팝업 (typ: '7-04')
import BfrJnnHlthChck from '../emp/BfrJnnHlthChck.vue';             // 직원 입사전 건강검진 제출 팝업 (typ: '7-05')
import UseSvcPymDtlChngMdl from '../othr/UseSvcPymDtlChngMdl.vue';  // 결제내역 상세 (typ: '10-01')
import ReportMgmt from './ReportMgmt.vue';                          // 출력관리 (typ: '91-01')

export default {
    name: 'CmmRootMdl',

    components: {
        CopayChngMdl,
        AcknwChngMdl,
        GrantsChngMdl,
        LvnChngMdl,
        BscInfSavMdl,
        FmlyInfSavMdl,
        BscInfAddMdl,
        CntrcSavMdl,
        BscReEntSavMdl,
        CntrcAddMdl,
        AgrSavMdl,
        FallRiskrSavMdl,
        CntrcDtSavMdl,
        PrgrmRec,
        EmpRtrDtChngMdl,
        EmpJobChngMdl,
        BscEntSavMdl,
        HlthChckWrt,
        MsclsSystmChkWrk,
        BfrJnnHlthChck,
        UseSvcPymDtlChngMdl,
        ReportMgmt,
    },

    created: function () {

    },

    mounted: function () {
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {
            if(this.$store.getters.getRootTyp != ''){
                let chc = this.$store.getters.getRootTyp?.split('-', 2)
                if(typeof chc != 'undefined')
                    this.ctgr = chc[0]
            }
        })
    },

    computed: {
        getRootTyp: function () {
            return this.$store.getters.getRootTyp
        },
    },

    watch: {
        getRootTyp: function (txt) {
            if(txt != ''){
                let chc = txt?.split('-', 2)
                if(typeof chc != 'undefined')
                    this.ctgr = chc[0]
            }
        },
        'ctgr': function () {
            if(this.ctgr != '')
                this.ldrItmCd()
        },
    },

    methods: {
        getCode: function (arr) {
            getMultiStcd(arr)
                .then((response) => ( this.stCdMapping(response.data) ))
                .catch((error) => console.log('connect error /cmm/selCmnItmValCdJsn : ' + error))
        },
        stCdMapping: function (res){
            switch (this.ctgr) {
                case '1':
                    this.vCd62 = res.vCd62
                    this.vCd63 = res.vCd63
                    this.vCd64 = res.vCd64
                    this.vCd114 = res.vCd114
                    break;
                case '4':
                    this.vCd3 = res.vCd3
                    this.vCd38 = res.vCd38
                    this.vCd40 = res.vCd40
                    break;
                case '7':
                    this.vCd42 = res.vCd42
                    this.vCd70 = res.vCd70
                    break;
                case '10':
                    this.vCd64 = res.vCd64
                    break;
                case '91':
                    this.vCd41 = res.vCd41
                    this.vCd42 = res.vCd42
                    this.vCd61 = res.vCd61
                    this.vCd65 = res.vCd65
                    this.vCd81 = res.vCd81
                    break;
                default:
                    break;
            }

            this.ctgr = ''
        },
        ldrItmCd: function (){
            let chc = false
            switch (this.ctgr) {
                case '1':
                    if(this.vCd62.length == 0)
                        chc = true
                    if(this.vCd63.length == 0)
                        chc = true
                    if(this.vCd64.length == 0)
                        chc = true
                    if(this.vCd114.length == 0)
                        chc = true
                    if(chc)
                        this.getCode(['62', '63', '64', '114'])
                    break;
                case '4':
                    if(this.vCd3.length == 0)
                        chc = true
                    if(this.vCd38.length == 0)
                        chc = true
                    if(this.vCd40.length == 0)
                        chc = true
                    if(chc)
                        this.getCode(['3', '38', '40'])
                    break;
                case '7':
                    if(this.vCd42.length == 0)
                        chc = true
                    if(this.vCd70.length == 0)
                        chc = true
                    if(chc)
                        this.getCode(['42', '70'])
                    break;
                case '10':
                    if(this.vCd64.length == 0)
                        chc = true
                    if(chc)
                        this.getCode(['64'])
                    break;
                case '91':
                    if(this.vCd41.length == 0)
                        chc = true
                    if(this.vCd42.length == 0)
                        chc = true
                    if(this.vCd61.length == 0)
                        chc = true
                    if(this.vCd65.length == 0)
                        chc = true
                    if(this.vCd81.length == 0)
                        chc = true
                    if(chc)
                        this.getCode(['41', '42', '61', '65', '81'])
                    break;
                default:
                    break;
            }
        },
        mdlOff: function () {
            this.$store.commit('setRootInf', {
                actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
            });
        },
        outSideEvt: function () {
            if(this.$store.getters.getRootPrsst == false)
                this.$store.commit('setRootActv', false)
        },
    },

    data: () => ({
        ctgr: '',
        vCd3: [],       // 상중하
        vCd38: [],      // 기능회복훈련코드
        vCd40: [],      // 지표구분
        vCd41: [],      // 근무구분
        vCd42: [],      // 직종
        vCd61: [],      // 업무구분
        vCd62: [],      // 인정등급
        vCd63: [],      // 본인부담률
        vCd64: [],      // 이메일
        vCd65: [],      // 입소현황
        vCd70: [],      // 재직현황
        vCd81: [],      // 출력물 조회조건
        vCd114: [],     // 상급침실구분
        yearPeriod: [   // 년도
            { valcd: '0', valcdnm: '선택' }, { valcd: '1', valcdnm: '1년' }, { valcd: '2', valcdnm: '2년' },
            { valcd: '3', valcdnm: '3년' }, { valcd: '4', valcdnm: '4년' }, { valcd: '5', valcdnm: '5년' }
        ]
    }),
};
</script>