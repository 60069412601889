<template>
    <v-sheet class="ma-0 pa-7" rounded="lg">        
        <div class="d-flex justify-left">
            <span class="text-h4 font-weight-bold">{{$moment(inqYMD, 'YYYYMMDD').format('YYYY.MM.DD')}} 이후 갱신내역</span>
            <v-spacer></v-spacer>
            <v-icon color="black" x-large @click="onClose()">mdi-close</v-icon>
        </div>
        <div class="mt-3">
            <div :class="['pa-0 mt-1 d-flex',doseList.length > 11 ? 'mr-2__5' : '']" style="border-top: 1px solid #dbdbdb;border-bottom: 1px solid #dbdbdb;font-size:1rem !important">            
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:9%;">
                    <span class="black--text font-weight-medium">투약명</span>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">총량</span>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">                        
                    <div class="black--text font-weight-medium">
                        <div>투약량</div>
                        <div>&#40;1회&#41;</div>
                    </div>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">투약방법</span>
                </div>                    
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:4%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">주사</span>
                </div>
                <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">투약기간</span>
                </div>
                <div class="greyE01 justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">아침</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                            <span class="black--text font-weight-medium">식전</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">식후</span>
                        </div>
                    </div>
                </div>
                <div class="greyE01 justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">점심</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                            <span class="black--text font-weight-medium">식전</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">식후</span>
                        </div>
                    </div>
                </div>
                <div class="greyE01 justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%;border-bottom: 1px solid #dbdbdb !important;">
                        <span class="black--text font-weight-medium">저녁</span>
                    </div>
                    <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;">
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;">
                            <span class="black--text font-weight-medium">식전</span>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:50%;border-left: 1px solid #dbdbdb !important;">
                            <span class="black--text font-weight-medium">식후</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex greyE01 justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">취침전</span>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:11%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">투약효능</span>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:11%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">비고</span>
                </div>
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">작성자</span>
                </div>   
                <div class="greyE01 d-flex justify-center align-center pa-1 ma-0" style="width:4%;border-left: 1px solid #dbdbdb !important;">
                    <span class="black--text font-weight-medium">처리</span>
                </div>             
            </div>
            <div :class="['',doseList.length > 11 ? 'mdctnField-y-scroll' : '']" style="height:450px;"> 
                <div v-if="doseList.length > 0">                        
                    <div v-for="(item,i) in doseList" :key="i" :class="['pa-0 ma-0 d-flex', item.valid==true?'nmlBfClrImp-yellow001':'']" style="border-bottom: 1px solid #dbdbdb;">            
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:9%;">                    
                            {{ item.drugNm }}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">                                
                            {{ item.totDoseAmt }}                            
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:5%;border-left: 1px solid #dbdbdb !important;">
                            {{ item.snglDoseAmt }}                            
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">
                            {{ item.drugMthd }}
                        </div>                            
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:4%;border-left: 1px solid #dbdbdb !important;">
                            <v-icon v-if="item.injctYn=='1'" color="blueBtnColor">mdi-check-circle-outline</v-icon>
                            <v-icon v-else>mdi-minus-circle-outline</v-icon>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:15%;border-left: 1px solid #dbdbdb !important;">                            
                            <span>{{ $moment(item.doseStrt,'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                            <span class="pa-1">&#126;</span>
                            <span>{{ $moment(item.doseEnd,'YYYYMMDD').format('YYYY.MM.DD') }}</span>
                        </div>
                        <div class="justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                    
                            <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;height:100%;">
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;">                                    
                                    <v-icon v-if="item.dsBrkfsBf=='1'" color="blueBtnColor">mdi-check-circle-outline</v-icon>
                                    <v-icon v-else>mdi-minus-circle-outline</v-icon>
                                </div>
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">
                                    <v-icon v-if="item.dsBrkfsAf=='1'" color="blueBtnColor">mdi-check-circle-outline</v-icon>
                                    <v-icon v-else>mdi-minus-circle-outline</v-icon>
                                </div>
                            </div>
                        </div>
                        <div class="justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                   
                            <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;height:100%;">
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;">
                                    <v-icon v-if="item.dsLunchBf=='1'" color="blueBtnColor">mdi-check-circle-outline</v-icon>
                                    <v-icon v-else>mdi-minus-circle-outline</v-icon>                                    
                                </div>
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">
                                    <v-icon v-if="item.dsLunchAf=='1'" color="blueBtnColor">mdi-check-circle-outline</v-icon>
                                    <v-icon v-else>mdi-minus-circle-outline</v-icon>                                                                            
                                </div>
                            </div>
                        </div>
                        <div class="justify-center align-center pa-0 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                   
                            <div class="d-flex justify-center align-center pa-0 ma-0" style="width:100%;height:100%;">
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;">                                        
                                    <v-icon v-if="item.dsDinerBf=='1'" color="blueBtnColor">mdi-check-circle-outline</v-icon>
                                    <v-icon v-else>mdi-minus-circle-outline</v-icon>                                      
                                </div>
                                <div class="d-flex justify-center align-center pa-0 ma-0" style="width:50%;height:100%;border-left: 1px solid #dbdbdb !important;">                                        
                                    <v-icon v-if="item.dsDinerAf=='1'" color="blueBtnColor">mdi-check-circle-outline</v-icon>
                                    <v-icon v-else>mdi-minus-circle-outline</v-icon>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                                                            
                            <v-icon v-if="item.dsBedBf=='1'" color="blueBtnColor">mdi-check-circle-outline</v-icon>
                            <v-icon v-else>mdi-minus-circle-outline</v-icon>
                        </div>
                        <div class="pa-1 ma-0 text-overflow" style="width:11%;border-left: 1px solid #dbdbdb !important;">  
                            {{ item.drugEfct }}
                        </div>
                        <div class="pa-1 ma-0 text-overflow" style="width:11%;border-left: 1px solid #dbdbdb !important;">  
                            {{ item.rmrks }}
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:6%;border-left: 1px solid #dbdbdb !important;">                                
                            {{item.wrtr}}                            
                        </div>
                        <div class="d-flex justify-center align-center pa-1 ma-0" style="width:4%;border-left: 1px solid #dbdbdb !important;">                                
                            <v-btn  v-if="item.doseInfPk > 0" icon color='grey006' @click="delList(i)"> 
                                <v-icon>mdi-trash-can-outline</v-icon>   
                            </v-btn>
                        </div>
                    </div>
                </div>
                <div v-else class="d-flex pa-0" no-gutters style="border-bottom: 1px solid #dbdbdb !important;">
                    <div class="d-flex justify-center align-center pa-1 ma-0" style="width:100%">                                
                        데이터가 없습니다.
                    </div> 
                </div>
            </div>
        </div>
        
    </v-sheet>
</template>

<script>
import { http } from '@/api/baseAxios';

export default {
    name: 'MdctnPrscrMdlRnw',

    props : {
        bnMmbrPk:{type:Number, default:0},
        inqYMD  :{type:String, default:''}
    },
            
    components: {
    },

    created: function(){
        
    },
    
    mounted: function(){
        //돔 로딩이 끝난 후 작업
        this.$nextTick(function () {           
           this.getDoseInfList();
        })
    },

    computed: {
        getSubEvt: function () {
            return this.$store.getters.getSubRunEvt
        },
    },

    watch: {
        getSubEvt: function (bol) {
            if(bol && this.rmRnwCnFm){
                
                //갱신
                this.onDelete()

                this.$store.commit('setSubInf', {
                    actv: false, mxWdth: 500, prsst: false, typ: '', inObj: null, outObj: null, runEvt: false,
                });

                this.rmRnwCnFm = false
            }
        },
    },
        
    methods: {
        
        onClose: function () {           
            this.$emit('isClose', '.')
        },


        getDoseInfList(){       
            this.doseList.splice(0)

            let obj = {                
                bnMmbrPk    : this.bnMmbrPk,
                inqYMD      : this.inqYMD
            }           

            http.post('bnftrcrd/selRnwDoseInfList', obj)
                .then((response) => ( this.rstDoseInfList(response.data) ))
                .catch((error) => console.log('connect error /bnftrcrd/selRnwDoseInfList : ' + error))
        },

        rstDoseInfList:function(res){
            if(res.statusCode == '200'){                
                if(res.result.list != null && res.result.list != undefined){
                    res.result.list.forEach(itm => {
                        this.doseList.push(itm)
                    });
                }
            }
            else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
                obj.title = res.message         // 경고 타이틀
                obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

                this.$store.commit('setWrnnInf', obj)
            }
        },
        
        delList:function(idx){
           if(this.doseList[idx].doseInfPk > 0){                
                this.sltPk = this.doseList[idx].doseInfPk                    
                let obj = {}
                    obj.icon = 'mdi-alert'
                    obj.iconClr = 'blue'                
                    obj.title = '투약명 : '+this.doseList[idx].drugNm
                    obj.cntnt = ['투약처방을 삭제하시겠습니까?']
                    obj.cnclTxt = '아니오'
                    obj.cnfrmTxt = '예, 갱신하겠습니다.'
    
                this.$store.commit('setSubInf', {
                    actv: true, mxWdth: 500, prsst: true, typ: '1001', inObj: obj, outObj: null, runEvt: false,
                });

                this.rmRnwCnFm = true
            }
        },

        //기록 DB 삭제
        onDelete:function(){
            let fcltyNum = this.$store.getters.getFcltyNum    
            let userNm = this.$store.getters.getUserNm
            let bnMmbrPk = this.$parent.$parent.$parent.$parent.bnMmbrPk

            if(this.sltPk > 0){
                let obj = {
                    fcltyNum: fcltyNum,
                    bnMmbrPk : bnMmbrPk,
                    userNm : userNm,
                    doseInfPk : this.sltPk,
                }                    
                http.post('bnftrcrd/delDoseInf', obj)
                    .then((response) => ( this.onDeleteAfter(response.data) ))
                    .catch((error) => console.log('connect error /bnftrcrd/delDoseInf : ' + error))
            }else{
                let obj = {}
                obj.actv = true                 // 경고 노출여부
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'warning'            // 경고 유형 (success, info, warning, error)
                obj.title = '삭제 대상을 찾을 수 없습니다.(ER400)'        // 경고 타이틀

                this.$store.commit('setWrnnInf', obj)
            }

        },
        onDeleteAfter:function(res){
            
            let obj = {}
           
            obj.actv = true                 // 경고 노출여부
            obj.title = res.message         // 경고 타이틀
            obj.cntnt = res.messagesList    // 경고 내용 (arr만 출력됨!)

            if(res.statusCode == 200){
                obj.tmr = 2500                  // 경고 노출시간(-1: 무제한)
                obj.type = 'success'            // 경고 유형 (success, info, warning, error)
                this.getDoseInfList()
            }else{
                obj.tmr = 5000                  // 경고 노출시간(-1: 무제한)
                obj.type = 'error'            // 경고 유형 (success, info, warning, error)
            }

            this.$store.commit('setWrnnInf', obj)
        },
    },
    
    data: () => ({   
        rmRnwCnFm:false,
        sltPk:-1,
        doseList:[],
    }),
};
</script>